var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Order',{attrs:{"act_id":"demoscene4activity3","scene_id":"demoscene4","title":"Esquemas de tratamiento del déficit de hierro","intro_text":"En este caso utilizaremos el esquema de tratamiento con hierro carboximaltosa intravenoso.","question":"Teniendo en cuenta que Juana pesa 71 kg y que su Hb es de 12,3 g/dL; escoge 3 ítems de la columna de la izquierda y ordénalos de forma adecuada en la columna de la derecha.","options":[
        {
            id: 0,
            name: '1000mg de HCM',
            cssclass: 'opcion0',
            val: 1,
        },
        {
            id: 1,
            name: '500mg de HCM',
            cssclass: 'opcion1',
            val: 2,
        },
        {
            id: 2,
            name: '1000mg de HCM',
            cssclass: 'opcion2',
            val: 1,
        },
        {
            id: 3,
            name: '500mg de HCM',
            cssclass: 'opcion3',
            val: 2,
        },
        {
            id: 4,
            name: '500mg de HCM',
            cssclass: 'opcion4',
            val: 2,
        },
        {
            id: 5,
            name: '1000mg de HCM',
            cssclass: 'opcion5',
            val: 1,
        } ],"correct_order":[1,2,2,null,null,null],"left_column":"col-6 m-0 p-0","right_column":"col-6 m-0 p-0","left_size":"col-4","right_size":"col-8 draglist","table_body":[
        {
            class: 'p-0 m-0 bg-bluelight',
            tds:[
                {
                    style: 'width: 30%;',
                    content: '<span>Dosis inicial</span>'
                },
                {
                    draggable: true,
                    class: 'w-100 h-100 p-0 m-0',
                    rowspan: 6,
                }
            ]
        },
        {
            class: 'p-0 m-0 bg-bluedark',
            tds:[
                {
                    style: 'width: 30%;',
                    content: '<span>Dosis a los 7 días</span>'
                },
                {
                    content: null
                }
            ]
        },
        {
            class: 'p-0 m-0 bg-bluelight',
            tds:[
                {
                    style: 'width: 30%;',
                    content: '<span>Dosis cada 3 meses</span>'
                },
                {
                    content: null
                }
            ]
        },
        {
            class: 'p-0 m-0',
            tds:[
                {
                    style: 'width: 30%;',
                    content: '<span class=\'invisible\'>a</span>'
                },
                {
                    content: null
                }
            ]
        },
        {
            class: 'p-0 m-0',
            tds:[
                {
                    style: 'width: 30%;',
                    content: '<span class=\'invisible\'>a</span>'
                },
                {
                    content: null
                }
            ]
        },
        {
            class: 'p-0 m-0',
            tds:[
                {
                    style: 'width: 30%;',
                    content: '<span class=\'invisible\'>a</span>'
                },
                {
                    content: null
                }
            ]
        } ],"row_style":"font-size:17px;","incorrect_msg":"La respuesta es incorrecta","clue_text":"La actividad informativa de este escenario te ayudará a ordenar los items.","feedback":_vm.feedback_content,"check_activity_children":_vm.check_activity_children}})}
var staticRenderFns = []

export { render, staticRenderFns }