var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity2checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"demoscene1activity3","scene_id":"demoscene1","title":"Componentes de los programas de atención a los pacientes con IC en AP","intro_text":"Juana acude a la consulta programada de enfermería para seguimiento de su ICC, según el programa de atención integral y multidisciplinar de la enfermedad.","question":"¿Qué actividades podrían incluirse entre las funciones de enfermería de AP en el seguimiento de paciente con ICC?","options":[
            {
                text: 'Valoración de la situación clínica mediante anamnesis y exploración',
                value: 0,
            },
            {
                text: 'Comprobar y fomentar la adherencia al tratamiento',
                value: 1,
            },
            {
                text: 'Realización de ajustes en el tratamiento farmacológico',
                value: 2,
            },
            {
                text: 'Solicitud e interpretación de analíticas de control',
                value: 3,
            },
            {
                text: 'Realización de ecocardiograma',
                value: 4,
            },
            {
                text: 'Detección precoz de descompensaciones de IC',
                value: 5,
            },
            {
                text: 'Educación sanitaria/Autocuidado: estilos de vida, ejercicio, etc.',
                value: 6,
            },
            {
                text: 'Vacunación antigripal y antineumocócica',
                value: 7,
            },
            {
                text: 'Derivación a cardiología u otro especialista hospitalario',
                value: 8,
            }
        ],"correct_answer":[
            0, 1, 5, 6, 7
        ],"clue_text":"4 de estas funciones pertenecen a otros profesionales sanitarios.","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }