<template>
    <div class="activity2checks w-100 h-100">
        <Checks 
            act_id="scene1activity3cc1"
            scene_id="scene1cc1"
            title="Componentes de los programas de atención a los pacientes con IC en AP"
            intro_text="Juana acude a la consulta programada de enfermería para seguimiento de su ICC, según el programa de atención integral y multidisciplinar de la enfermedad." 
            question="¿Qué actividades podrían incluirse entre las funciones de enfermería de AP en el seguimiento de paciente con ICC?" 
            :options="[
                {
                    text: 'Valoración de la situación clínica mediante anamnesis y exploración',
                    value: 0,
                },
                {
                    text: 'Comprobar y fomentar la adherencia al tratamiento',
                    value: 1,
                },
                {
                    text: 'Realización de ajustes en el tratamiento farmacológico',
                    value: 2,
                },
                {
                    text: 'Solicitud e interpretación de analíticas de control',
                    value: 3,
                },
                {
                    text: 'Realización de ecocardiograma',
                    value: 4,
                },
                {
                    text: 'Detección precoz de descompensaciones de IC',
                    value: 5,
                },
                {
                    text: 'Educación sanitaria/Autocuidado: estilos de vida, ejercicio, etc.',
                    value: 6,
                },
                {
                    text: 'Vacunación antigripal y antineumocócica',
                    value: 7,
                },
                {
                    text: 'Derivación a cardiología u otro especialista hospitalario',
                    value: 8,
                }
            ]" 
            :correct_answer="[
                0, 1, 5, 6, 7
            ]"
            clue_text="4 de estas funciones pertenecen a otros profesionales sanitarios."
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            ></Checks>
        </div>
</template>

<script>
import Vue from 'vue';
import Checks from '../../../../components/activitytype/Checks';
import FeedbackActivity3 from '../feedbacks/FeedbackActivity3';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Checks
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene1cc1");
        vueapp.$store.commit("setLastURL", "scene1activity3cc1");
        setActivityVisited('scene1cc1', 'scene1activity3cc1');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>
