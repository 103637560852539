var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100 demoscene4activity2"},[_c('Order',{attrs:{"act_id":"demoscene4activity2","scene_id":"demoscene4","title":"Diferencias entre las presentaciones de hierro para uso intravenoso","intro_text":"En la actualidad se dispone de diferentes presentaciones para uso intravenoso con diferentes características físicas y bioquímicas.","question":"Asocia la columna de la derecha con una de las presentaciones de hierro intravenoso de la izquierda. Cada principio activo tiene 4 características.","options":[
            /*{
                id: 0,
                name: 'Hidróxido de hierro + carbohidrato',
                cssclass: 'opcion0',
                val: 0,
            },
            {
                id: 1,
                name: 'Complejo férrico + polímeros de hidratos de  carbono',
                cssclass: 'opcion1',
                val: 1,
            },
            {
                id: 2,
                name: 'No se elimina por vía renal ni diálisis',
                cssclass: 'opcion2',
                val: 2,
            },
            {
                id: 3,
                name: 'Se distribuye rápidamente unido a apotransferrina y ferritina',
                cssclass: 'opcion3',
                val: 3,
            },
            {
                id: 4,
                name: 'Se deposita en hígado y bazo',
                cssclass: 'opcion4',
                val: 4,
            },
            {
                id: 5,
                name: 'Vida media 7-12h con eliminación renal mínima',
                cssclass: 'opcion5',
                val: 5,
            },
            {
                id: 6,
                name: 'Se han reportado reacciones anafilactoides o pseudoalérgicas por administración de dosis superiores a las recomendadas o infusión rápida.',
                cssclass: 'opcion6',
                val: 6,
            },
            {
                id: 7,
                name: 'Mejor perfil de seguridad, permite administrar dosis mayores de hierro en menor tiempo de infusión.',
                cssclass: 'opcion7',
                val: 7,
            }*/

            {
                id: 0,
                name: 'Dosis máxima semanal: 600 mg',
                cssclass: 'opcion0 bg-bluelight',
                val: 0,
            },
            {
                id: 1,
                name: 'Sólo se puede administrar en adultos',
                cssclass: 'opcion1 bg-bluelight',
                val: 1,
            },
            {
                id: 6,
                name: 'Eliminación renal insignificante y mejor perfil de seguridad',
                cssclass: 'opcion6',
                val: 6,
            },
            {
                id: 7,
                name: 'Único hierro iv recomendado por las Guías Europeas de Cardiología',
                cssclass: 'opcion7',
                val: 7,
            },
            //-----------------------------------------------------------
            {
                id: 4,
                name: 'Dosis máxima semanal: 1000 mg',
                cssclass: 'opcion4',
                val: 4,
            },
            {
                id: 3,
                name: 'Se han reportado reacciones anafilactoides o pseudoalérgicas por administración de dosis superiores a las recomendadas o infusión rápida.',
                cssclass: 'opcion3 bg-bluelight',
                val: 3,
            },
            {
                id: 5,
                name: 'Se puede administrar en ≥ 14 años',
                cssclass: 'opcion5',
                val: 5,
            },
            {
                id: 2,
                name: 'Eliminación renal de un 75% de la dosis administrada',
                cssclass: 'opcion2 bg-bluelight',
                val: 2,
            } ],"correct_order":[0,1,2,3,4,5,6,7],"left_column":"col-6 m-0 p-0","right_column":"col-6 m-0 p-0","left_size":"col-4","right_size":"col-8","table_body":[
            {
                rowspan: 4,
                class: 'p-0 m-0 bg-bluelight',
                style: 'height:50%;min-height: 50%;',
                tds:[
                    {
                        style: 'width: 25%; border-bottom: 10px solid #31718a;text-align: center;vertical-align: middle;',
                        content: '<span>Hierro sacarosa (HS)</span>'
                    },
                    {
                        draggable: true,
                        class: 'w-100 h-100 p-0 m-0 right-col-transparent',
                        rowspan: 6,
                    }
                ]
            },
            {
                rowspan: 4,
                class: 'p-0 m-0 bg-bluedark',
                style: 'height:50%;min-height: 50%;',
                tds:[
                    {
                        style: 'width: 25%;text-align: center;vertical-align: middle;',
                        content: '<span>Hierro carboximaltosa (HCM)</span>'
                    },
                    {
                        content: null
                    }
                ]
            } ],"incorrect_msg":"La respuesta es incorrecta","clue_text":"Cada uno tiene 4 items","row_style":"height: 70px;background-color: rgba(0,0,0,0);","feedback":_vm.feedback_content,"check_activity_children":_vm.check_activity_children}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }