<template>
    <div class="w-100 h-100 d-flex justify-content-center align-items-center flex-column animate__animated animate__fadeIn position-relative">
        <img id="pantalla_bloqueada" class="animate__animated animate__shakeX" src="@/assets/pantalla_bloqueada.png" alt="pantalla_bloqueada">
        <h2 class="mt-4 text-rockwell">¡Actividad bloqueada!</h2>
        <h5 class="mt-4 text-rockwell">Primero debes completar las otras actividades</h5>
        <div class="w-100 d-flex justify-content-center align-items-center">
            <button class="feeback-back-btn btn btn-action mt-3 mb-3 rounded check-btn" @click="$router.push({name:$store.state.back_url});">Volver</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Locked',
        mounted: function(){
            vueapp.$store.commit("setLastURL", "demolocked");
            updateUserSession();
        }
    }
</script>

<style scoped>
    #pantalla_bloqueada{
        position: relative;
        max-width: 40%;
        max-height: 40%;
    }
</style>
