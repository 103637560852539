<template>
    <div class="end w-100 h-100 animate__animated animate__fadeIn">
        <div v-if="$store.state.game_over == true" id="gameover-container" class="h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <img id="pantalla_perder" src="@/assets/pantalla_perder.png" class="h-50">        
            <h1 class="mt-4 text-rockwell-bold">Se ha terminado el tiempo</h1>
            <h3 class="mt-4 text-rockwell">¡No has completado el juego!</h3>
        </div>
        <div v-else class="w-100 h-100 flex-column position-relative">
            <div class="row w-100 h-100 d-flex justify-content-center align-items-center m-0">
                <div class="col-4 d-flex justify-content-center align-items-center flex-column m-0">
                    <img class="images-end animate__heartBeat" src="@/assets/pantalla_ganar.png" alt="pantalla_ganar">
                    <div class="text-oswald text-center">
                        <h1 class="text-rockwell-bold">¡Enhorabuena!</h1>
                        <h3 class="text-rockwell">¡Has completado el caso!</h3>
                        <h5 class="text-rockwell">El déficit de hierro en la insuficiencia cardiaca no es solo una comorbilidad: influye en el pronóstico, la capacidad funcional y la calidad de vida.</h5>
                    </div>
                </div>
            </div>
            <svg preserveAspectRatio="none" id="end-animation" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
                <g class="confeti"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
                <g class="confeti confi" x="-200" y="20"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
                <g class="confeti confi2" x="-200" y="20"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
                <g class="confeti confi3" x="-200" y="20"><path id="rojo3" class="strr0" d="M700.4 139.6L679 136"/><path id="rojo2" class="strr0" d="M879.9 130.4l-14.2-16.3"/><path id="rojo" class="strr0" d="M509.7 36.1l21.5-2.3"/><path id="azul2" class="strr1" d="M649.4 27.1l19.5-9.4"/><path id="verde3" class="strr2" d="M722.6 74.5L713.2 55"/><path id="amarillo" class="strr3" d="M791.9 37.9l20.4-7.3"/><path id="azul" class="strr1" d="M801.8 109.1l-17.1 13.3"/><path id="verde2" class="strr2" d="M523.2 128.1l16.3 14.2"/><path id="verde" class="strr2" d="M922.4 47.8l-18.8 10.8"/><path id="amarillo2" class="strr3" d="M612.7 80.7l-18.4 11.5"/></g>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'End',
        data: function(){
            return {
                score: null,
            };
        },
        mounted: function(){        
            vueapp.$store.commit("setLastURL", "end");
            if(vueapp.$store.state.game_over == null){
                vueapp.$store.commit("setGameOver", true);
                console.log(vueapp.$store.state.game_over);
            }
            
            // if game_over == false (win) play end sound
            if(vueapp.$store.state.game_over == false){
                if(vueapp.$store.state.end)
                    vueapp.$store.state.end.play();
            }else{ // if the game is over just play gameover sound
                if(vueapp.$store.state.gameover)
                    vueapp.$store.state.gameover.play();
            }

            // Check if the game has finished by checking finishedAt, if it's null we consider that the game isn't finished
            // so we set the finishedAt to Date now and the score
            // after save the records into vuex, we update the information to the server
            if(vueapp.$store.state.finishedAt == null){
                vueapp.$store.commit("setFinishedAt", new Date().getTime());
                vueapp.$store.commit("setFinalScore", vueapp.$store.state.finishedAt - vueapp.$store.state.startedAt);
                vueapp.$store.commit("setGameOver", false);
                updateUserSession();
            }
        },
        methods: {  
            
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .game{
        overflow: hidden !important;
    }
    #end-animation{
        position: absolute;
        top: 0px;
    }    

    .images-end{
        max-width: 100%;
        max-height: 100%;
    }

    .rowtop-feedback-esc5act4{
        height:10%;
        position: relative;
        font-size: 15px;
        padding: 10px;
    }

    .rowbottom-feedback-esc5act4{
        height: 90%;
        position: relative;
        padding: 20px;
    }
</style>
