<template>
    <SingleTextEntry 
        act_id="demoscene2activity7"
        scene_id="demoscene2"
        title="La importancia de las comorbilidades en la evolución de la IC"
        intro_text="Ante la progresión clínica de la IC (empeoramiento de síntomas, reingresos, etc.) es importante buscar posibles desencadenantes así como valorar el control de las diferentes comorbilidades del paciente."
        question="¿Qué comorbilidad se esconde detrás de esta palabra?"
        clue_text="Las 7 letras de esta palabra ya las deberías de conocer porque aparecen en mayúscula y negrita, la necesitarás más adelante para poder resolver otro escenario. Su definición es: <i>Falta o escasez de algo que se juzga necesario.</i>"
        incorrect_msg="La respuesta es incorrecta"
        :feedback="feedback_content"
        :option="[
            {
                id: 0,
                solution: 'DÉFICIT',
                value: '',
            }
        ]"
        :scenario_finisher="true"
        next_scenario="demoscene3">
    </SingleTextEntry>
</template>

<script>
    //clue_text="Las 7 letras de esta palabra ya las deberías de conocer y la necesitarás más adelante para poder resolver otro escenario."
import Vue from 'vue';
import SingleTextEntry from '../../../../components/activitytype/SingleTextEntry';
import FeedbackActivity7 from '../feedbacks/FeedbackActivity7';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        SingleTextEntry
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene2");
        vueapp.$store.commit("setLastURL", "demoscene2activity7");
        setActivityVisited('demoscene2', 'demoscene2activity7');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity7)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
        
        updateUserSession();
    }
}
</script>