<template>
    <Radio 
        act_id="demoscene1activity6"
        scene_id="demoscene1"
        title="Actuaciones en la consulta de seguimiento de enfermería"
        intro_text="Con los datos aportados hasta ahora, la enfermera puede extraer conclusiones y decidir una actuación."
        question="¿Cuál sería la actuación más correcta por parte de la enfermera?"
        :options="[
            {
                id: 0,
                name: 'respuesta1',
                text: 'Explicarle a la paciente que está bien controlada y citarle para un próximo control en 3 meses.',
                value: 0,
            },
            {
                id: 1,
                name: 'respuesta2',
                text: 'Reforzar la educación para la salud: insistir en la dieta y el ejercicio para que la paciente pueda volver a su peso habitual.',
                value: 1,
            },
            {
                id: 2,
                name: 'respuesta3',
                text: 'Citar a la paciente con su médico de familia lo antes posible ante el empeoramiento de su situación funcional.',
                value: 2,
            },
            {
                id: 3,
                name: 'respuesta4',
                text: 'Se detecta una falta de adherencia al tratamiento: empoderar a la paciente, insistir en la importancia del cumplimiento farmacológico y no farmacológico.',
                value: 3,
            }
        ]" 
        :correct_answer=2
        incorrect_msg="La respuesta es incorrecta"
        clue_text="La respuesta es fácil si lees la conversación con la enfermera, los resultados de las pruebas complementarias y el cuestionario de adherencia."
        :feedback="feedback_content"
        :scenario_finisher="true"
        next_scenario="demoscene2">
    </Radio>
</template>

<script>
import Vue from 'vue';
import Radio from '../../../../components/activitytype/Radio';
import FeedbackActivity6 from '../feedbacks/FeedbackActivity6';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Radio
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene1");
        vueapp.$store.commit("setLastURL", "demoscene1activity6");
        setActivityVisited('demoscene1', 'demoscene1activity6');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity6)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>