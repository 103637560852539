<template>
    <div class="row h-100 w-100 m-0">
        <div class="col">
            <nav v-if="$route.name == 'panel'" aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Panel</li>
                </ol>
            </nav>
            <div v-if="$route.name == 'panel'" class="w-100 d-flex justify-content-center align-items-center">
                <div class="btn btn-secondary p-5 m-5 panel-buttons d-flex justify-content-center align-items-center flex-column text-oswald btn-nav-blue" @click="$router.push({name:'panel-sessions'})"><h4>SESIONES</h4><font-awesome-icon class="fa-7x" :icon="['fa', 'list']" /></div>
                <div class="btn btn-secondary p-5 m-5 panel-buttons d-flex justify-content-center align-items-center flex-column text-oswald btn-nav-blue" @click="$router.push({name:'panel-users'})"><h4>USUARIOS</h4><font-awesome-icon class="fa-7x" :icon="['fa', 'users']" /></div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'Panel',
    }
</script>