<template>
    <div class="row no-gutters h-100 animate__animated animate__fadeIn p-2">
        <div class="col-md-4 title-scene-col" id="col_title_scene3" ref="col_title_scene3">
            <h3 class="title-scene">Consulta telefónica con el médico de familia para comprobar evolución clínica e informar de resultados de pruebas diagnósticas</h3>
            <p class="description-scene">Se recibe el resultado de la analítica y se llama por teléfono a Juana para informar del resultado y comprobar su evolución clínica.</p>
        </div>
        <div class="col-md-8 d-flex justify-content-center align-items-center scene-container">
            <scene3svg class="sceneelement" id="demoscene3" ref="demoscene3"></scene3svg>
        </div>
    </div>
</template>

<script>
    //Navigation bar
    import scene3svg from '@/assets/demo/scenes/scene3.svg';

    export default {
        name: 'Scene3',
        components: {
            scene3svg,
        },
        data: function(){
            return {
                scene_name: 'demoscene3',
                activities: [],
                update_ripples_timeout: null
            };
        },
        created() {
            window.addEventListener("resize", this.updateRipples);
        },
        destroyed() {
            window.removeEventListener("resize", this.updateRipples);
            //Clean up click events for each activity.
            for(var i = 0; i < this.activities.length; i++){
                //Delete path click event.
                $(this.activities[i]).off("click", "**");
                //Delete ripple click event.
                $('#ripple_'+(i+1)).off("click", "**");
            }
        },
        mounted() {
            vueapp.$store.commit("setBackURL", "demomap");
            vueapp.$store.commit("setLastURL", "demoscene3");
            this.activities = $('.activity').toArray();
            var current_game = (vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.scenes : vueapp.$store.state.scenes;
            //Check if there are more than 0 scenes in the vuex.
            if(Object.keys(current_game).length > 0){
                //Find the current scene that we are in.
                var found_scene = null;
                found_scene = current_game[this.scene_name];
                //Push each activity information for the first time.
                if(found_scene){
                    //Check if in the found scene there are activities
                    if(Object.keys(found_scene.activities).length === 0){  
                        //Add the activities on the found scene if there are no activities in there.                  
                        for(var i = 0; i < this.activities.length; i++){
                            console.log(this.activities[i])
                            found_scene.activities[this.activities[i].id] = {
                                activity: this.activities[i],
                                visited: false,
                                locked: (this.activities.length == (i+1)) ? true : false,
                                completed: false,
                                data: null,
                            };
                        }
                    }else{
                        //If there are activities then update the path.
                        for(var i = 0; i < this.activities.length; i++){
                            found_scene.activities[this.activities[i].id].activity = this.activities[i];
                        }
                    }
                }
                //Loop each scene activity
                if(found_scene != null){
                    var i = 0;
                    var unlock_last_activity = true;
                    for (var prop in found_scene.activities) {
                        //Check for the last activity, if the rest of activities are completed = true, then the last activity should be unlocked here.
                        //setActivityLocked(scene_id, act_id, false)
                        //If any of the activities are not completed and are not the last one set the status of the last activity to locked
                        if(found_scene.activities[prop].completed == false && ((i+1) < this.activities.length) && unlock_last_activity == true){
                            unlock_last_activity = false;
                        }
                        //if the rest of activities are completed and is the last one activity then we unlock it...
                        if(unlock_last_activity == true && (this.activities.length == (i+1))){
                            setActivityLocked(this.scene_name, prop, false);
                        }
                        //if is not locked then we allow the click event and hide the scene background and the locker.
                        if(found_scene.activities[prop].locked == false){                              
                            $(found_scene.activities[prop].activity).click(function(activity){
                                router.push({ name: activity.id});
                            }.bind(this, found_scene.activities[prop].activity));
                        }else{
                            $(found_scene.activities[prop].activity).click(function(scene_name){
                                vueapp.$store.commit("setBackURL", scene_name);
                                router.push({ name: 'demolocked', params: { scene_id: scene_name }});
                            }.bind(this, this.scene_name));
                        }
                        i++;
                    }
                    this.activities = current_game[this.scene_name].activities;
                }
            }
            this.appendRipples();
            this.updateRipples();

            updateUserSession();
        },
        methods: {
            appendRipples: function(){
                //Add the ripples to the document.
                var i = 0;
                for (var prop in this.activities) {
                    if(this.activities[prop].completed == false){
                        if(i == 0){
                            $('.scene-container').append("<div class='ripple-dark d-none' id='ripple_"+(i+1)+"'>"+ ((process.env.NODE_ENV === 'production') ? '' : (i+1)) +"</div>");
                        }else{
                            $('.scene-container').append("<div class='ripple d-none' id='ripple_"+(i+1)+"'>"+ ((process.env.NODE_ENV === 'production') ? '' : (i+1)) +"</div>");
                        }
                    }
                        
                    $('#ripple_'+(i+1)).click(function(activity){
                        if(activity.locked == false){
                            router.push({ name: activity.activity.id});
                        }else{
                            vueapp.$store.commit("setBackURL", this.scene_name);
                            router.push({ name: 'demolocked', params: { scene_id: this.scene_name }});
                        }
                    }.bind(this, this.activities[prop]));
                    i++;
                }
            },
            updateRipples: function(){
                //Update ripples using lazzy update.
                if(this.update_ripples_timeout != null) clearTimeout(this.update_ripples_timeout);
                this.update_ripples_timeout = setTimeout(function(){
                    var i = 0;
                    for (var prop in this.activities) {
                        //if($('#ripple_'+(i+1))[0])
                            this.setRipple($('#ripple_'+(i+1)), $(this.activities[prop].activity));
                        i++;
                    }
                }.bind(this), 10);
            },
            setRipple(ripple, path){
                //Set the ripple position according with the path position.
                var group_position = $('#'+this.scene_name).offset();
                ripple.css('top', ((path[0].getBoundingClientRect().top + window.scrollY)- group_position.top) + path[0].getBoundingClientRect().height/2)
                ripple.css('left', ((path[0].getBoundingClientRect().left + window.scrollX)- group_position.left) + path[0].getBoundingClientRect().width/2) 
                ripple.removeClass("d-none");
            },
        }
    }
</script>

<style scoped>

</style>
