var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Radio',{attrs:{"act_id":"demoscene3activity3","scene_id":"demoscene3","title":"Criterios para el inicio de tratamiento","intro_text":"Ya conoces el diagnóstico de la paciente, ahora debes de valorar si es necesario iniciar algún tipo de tratamiento.","question":"¿A partir de qué valores consideraría necesario tratar a la paciente?","options":[
        {
            id: 0,
            name: 'respuesta0',
            text: 'No es necesario iniciar tratamiento porque no existe anemia',
            value: 0,
        },
        {
            id: 1,
            name: 'respuesta1',
            text: 'No es necesario iniciar tratamiento porque no hay descompensación de IC',
            value: 1,
        },
        {
            id: 2,
            name: 'respuesta2',
            text: 'Nivel de Hb <12 g/dl (♀) y Hb<13g/dl(♂)',
            value: 2,
        },
        {
            id: 3,
            name: 'respuesta3',
            text: 'Ferritina <30 ng/ml',
            value: 3,
        },
        {
            id: 4,
            name: 'respuesta4',
            text: 'Ferritina < 100ng/ml o ferritina 100-300 ng/ml + IST<20%',
            value: 4,
        },
        {
            id: 5,
            name: 'respuesta5',
            text: 'Ferritina < 100ng/ml o ferritina 100-300 ng/ml + IST<20% + Hb <12 g/dl',
            value: 5,
        }
    ],"correct_answer":4,"incorrect_msg":"La respuesta es incorrecta","clue_text":"Piensa en los criterios diagnósticos...","feedback":_vm.feedback_content}})}
var staticRenderFns = []

export { render, staticRenderFns }