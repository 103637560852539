module.exports = {
    adjustScreenSize : function(nav, game, window){
        var window_height = window.innerHeight;
        var nav_height = nav[0].offsetHeight;
        game[0].style.height = window_height - nav_height + "px";
    },

    /* Get into full screen */
    GoInFullscreen(element) {
        if(element.requestFullscreen)
            element.requestFullscreen();
        else if(element.mozRequestFullScreen)
            element.mozRequestFullScreen();
        else if(element.webkitRequestFullscreen)
            element.webkitRequestFullscreen();
        else if(element.msRequestFullscreen)
            element.msRequestFullscreen();
    },

    /* Get out of full screen */
    GoOutFullscreen() {
        if(document.exitFullscreen)
            document.exitFullscreen();
        else if(document.mozCancelFullScreen)
            document.mozCancelFullScreen();
        else if(document.webkitExitFullscreen)
            document.webkitExitFullscreen();
        else if(document.msExitFullscreen)
            document.msExitFullscreen();
    },

    /* Is currently in full screen or not */
    IsFullScreenCurrently() {
        var full_screen_element = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null;
        
        // If no element is in full-screen
        if(full_screen_element === null)
            return false;
        else
            return true;
    },
    toggleFullScreen : function() {
        if(this.IsFullScreenCurrently()){
            this.GoOutFullscreen();
            return false;
        }else{
            this.GoInFullscreen($("body").get(0));
            return true;
        }
    },
    isValidParameter:function(param){
        if(param==null || param== '' || typeof param === 'undefined'){
            return false;
        }
        return true;
    },
    isValidPasswordLength:function(param){
        if(param.length>=6){
            return true;
        }
        return false;
    },
    isUserNameValid:function(username) {
        /* 
          Usernames can only have: 
          - Lowercase Letters (a-z) 
          - Numbers (0-9)
          - Dots (.)
          - Underscores (_)
        */
        const res = /^[a-z0-9_@\.]+$/.exec(username);
        const valid = !!res;
        return valid;
    },
    makeID : function(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ )
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }
};