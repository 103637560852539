<template>
    <div class="activity4 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="preview-img-list position-relative w-100 h-100 p-5 d-flex justify-content-center align-items-center">
                <img class="preview-img-item" v-for="(item, index) in items" :src="item.src" @click="$photoswipe.open(index, items)">
            </div>
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn rounded mb-4 ml-3 text-oswald btn-nav-white mr-5" onclick="router.push({name:vueapp.$store.state.back_url});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_07",
    data () {
        return {
            items: [{
                src: require('@/assets/demo/activities/E4_A4_tabla.jpg'),
                w: 946*2,
                h: 697*2
            }]
        }
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene4");
        vueapp.$store.commit("setLastURL", "demoscene4activity4");
        setActivityVisited('demoscene4', 'demoscene4activity4');
        var act = getActivityStatus("demoscene4", 'demoscene4activity4');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.demo.score + 1);
        setActivityCompleted('demoscene4', 'demoscene4activity4', true);

        updateUserSession();
    }
}
</script>

<style scoped>
    #esc4_act4{
        width: auto;
        height: auto;
    }
</style>