<template>
    <div class="activity1 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row w-100 h-100 d-flex justify-content-center align-items-center">
            <img id="esc_1_act_1" src="@/assets/demo/activities/esc1_act1.png" alt="esc_1_act_1" rel="preload">
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:vueapp.$store.state.back_url});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_01",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene1");
        vueapp.$store.commit("setLastURL", "demoscene1activity1");
        setActivityVisited('demoscene1', 'demoscene1activity1');
        var act = getActivityStatus("demoscene1", 'demoscene1activity1');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('demoscene1', 'demoscene1activity1', true);

        updateUserSession();
    }
}
</script>

<style scoped>
    #esc_1_act_1{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
</style>