<template>
    <b-modal v-model="show" id="modal-center-users" size="lg" centered title="Registra nuevo usuario" header-text-variant="light">
        <div class="form-group mb-2">
            <label class="font-weight-bold" for="inputUserName">Nombre del usuario:</label>
            <div class="alert alert-info">
                <span>Los nombres de usuario solo pueden tener:</span>
                <ul class="mb-0">
                    <li>Letras en minúscula: (a-z)</li>
                    <li>Letras en mayúscula: (A-Z)</li>
                    <li>Números: (0-9)</li>
                    <li>Puntos: (.)</li>
                    <li>Caracter: (@)</li>
                    <li>Guiones bajos: (_)</li>
                </ul>
            </div>
            <input type="text" v-model="new_user.username" class="form-control" id="inputUserName" name="username" required placeholder="Introduzca el nombre de usuario o email..">
        </div>
        <div class="form-group mb-2">
            <label class="font-weight-bold" for="inputUserPassword">Contraseña:</label>
            <input type="password" v-model="new_user.password" class="form-control" id="inputUserPassword" name="password" required placeholder="Introduzca la contraseña del usuario..">
            <small>El tamaño minimo de la contraseña ha de tener 6 caracteres.</small>
        </div>
        <div class="form-group mb-2">
            <label class="font-weight-bold" for="inputRole">Rol:</label>
            <select v-model="new_user.role" class="form-control" id="inputRole" name="role">
                <option :value="null">Selecciona un rol</option>
                <option value="0">Jugador</option>
                <option value="1">Admin</option>
                <option v-if="$store.state.user && $store.state.user.role == 2" value="2">Super Admin</option>
            </select>
        </div>
        
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    class="float-right"
                    @click="register_user"
                >
                    Registrar usuario
                </b-button>
                <b-button
                    variant="secondary"
                    class="float-right mr-2"
                    @click="show=false"
                >
                    Cancelar
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import utils from '../../utils'
    export default {
        name: 'RegisterUserModal',
        data(){
            return{
                // Show/hide modal.
                show: false,
                // User data.
                new_user: {
                    id: null,
                    password: "",
                    username: "",
                    role: "0",
                }
            };
        },
        methods: {
            show_modal : function(){
                this.show = true;
            },
            register_user : function(){
                //if(utils.isValidParameter(this.new_user.username) && utils.isValidParameter(this.new_user.password) && utils.isValidParameter(this.new_user.role)){
                    
                    if(!utils.isValidParameter(this.new_user.username)){
                        vueapp.$toast.error("¡Comprueba el campo nombre!");
                        return;
                    }
                    if(!utils.isUserNameValid(this.new_user.username)){
                        vueapp.$toast.error("¡Comprueba el formato del nombre de usuario!");
                        return;
                    }
                    if(this.findUserByUsername(this.new_user.username) == true){
                        vueapp.$toast.error("¡Ya existe un usuario con ese nombre de usuario!");
                        return;
                    }
                    if(!utils.isValidParameter(this.new_user.password)){
                        vueapp.$toast.error("¡Comprueba el campo contraseña!");
                        return;
                    }
                    if(!utils.isValidPasswordLength(this.new_user.password)){
                        vueapp.$toast.error("¡Contraseña invalida, comprueba el tamaño!");
                        return;
                    }
                    if(!utils.isValidParameter(this.new_user.role)){
                        vueapp.$toast.error("¡Comprueba el campo rol!");
                        return;
                    }

                    // Hide modal
                    this.show = false;
                    // Send packet to the server for register new session
                    ws.send(JSON.stringify({action: RPC.REGISTER_USER, params:this.new_user}));
                    // Reset form values
                    this.new_user.id = null;
                    this.new_user.role = "0";
                    this.new_user.username = "";
                    this.new_user.password = "";
                    
                //}else{
                //    vueapp.$toast.error("¡Comprueba si los campos nombre del usuario, contraseña y el rol estan completados!");
                //}
            },
            findUserByUsername : function(username){
                var found = false;
                for(var i = 0; i < vueapp.$store.state.panel_users.length && found == false; i++){
                    if(vueapp.$store.state.panel_users[i].username == username){
                        found = true;
                        return true;
                    }
                }
                return false;
            }
        }
    }
</script>

<style scoped>

</style>
