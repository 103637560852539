<template>
    <div class="row w-100 m-0" id="ranking-bg">
        <div class="col-12 mt-2">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-6">
                    <div class="row d-flex justify-content-center align-items-center text-white">
                        <img width="60px" height="60px" style="margin-right: 20px;margin-left: 20px;" src="@/assets/icono.png">
                        <h1>CLASIFICACIÓN</h1>
                        <img width="60px" height="60px" style="margin-right: 20px;margin-left: 20px;" src="@/assets/icono.png">
                    </div>
                    <table id="table_ranking" class="table mt-4" style="width:100%">
                        <thead class="text-white">
                            <tr>
                                <td class="text-center" style="width:5%;">#</td>
                                <td class="text-center" style="width:20%; border:none;">NOMBRE</td>
                                <td class="text-center" style="width:10%;">TIEMPO</td>
                            </tr>
                        </thead>
                        <tbody class="text-white text-center" >
                            <tr>
                                <!--div class="d-none">{{ (user.session_id == $route.params.id) ? playable=true:playable=false }}</div-->
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RPC from '../../../shared/RPC'
    import dotenv from 'dotenv'
    dotenv.config()
    export default {
        name: 'Ranking',
        data(){
            return {
                session: null,
                users:[],
                datatable_ranking: null,
                is_multiplayer: false,
            };
        },
        created : function(){
            $('#game_component').css({
                'background-image': 'url('+require("@/assets/puntuacion_fondo.png")+')',
                'background-repeat': 'no-repeat',
                'background-size': 'contain',
                'background-position-y': '37px',
            });
        },
        destroyed : function(){
            $('#game_component').css({
                'background-image': 'none',
                'background-repeat': 'no-repeat',
                'background-size': 'contain',
                'background-position-y': '0px',
            });
        },
        mounted: function(){
            ws.send(JSON.stringify({action: RPC.GET_RANKING, params:this.$route.params.id}));
            
            this.datatable_ranking = $('#table_ranking').DataTable({
                responsive: true,
                "dom": '<"toolbar">rti',
                "bInfo" : false,
                paging: false,
                "columnDefs": [
                    { "orderable": false, "targets": "_all" } // Applies the option to all columns
                ],
                "createdRow": function( row, data, dataIndex){
                    if( data[1] ==  vueapp.$store.state.user.username){
                        $(row).addClass('white-bg');
                    }
                }
            });
        },
        methods:{
            findUserByID: function(user_id){
                if(vueapp.$store.state.panel_users.length>0){
                    var found = false;
                    for(var i = 0; i < vueapp.$store.state.panel_users.length && found == false; i++){
                        if(vueapp.$store.state.panel_users[i].id == user_id){
                            found = true;
                            return vueapp.$store.state.panel_users[i].username;
                        }
                    }
                }
                return "Desconocido";
            },
            format_game_time : function(dt2, dt1){
                var diff =(new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
                diff /= 60;
                return Math.abs(Math.round(diff));
            }
        },
        watch:{
            '$store.state.ranking' : function(){
                console.log("new changes in ranking data")
                
                this.session = vueapp.$store.state.ranking;

                // Clear table content
                this.datatable_ranking.clear();
                // Fill table with ranking contents
                for(var i = 0; i < vueapp.$store.state.ranking.users.length; i++){
                    var user = vueapp.$store.state.ranking.users[i];
                    if(user != null){
                        var username = user.username;
                        
                        var startedAt = null, finishedAt = null, game_finished = false, spent_time = 99999;
                        if(typeof user.users_sessions.startedAt !== 'undefined' && user.users_sessions.startedAt != null){
                            if(vueapp.moment(user.users_sessions.startedAt).isValid()){
                                startedAt = vueapp.moment(user.users_sessions.startedAt);
                            }
                        }
                        if(typeof user.users_sessions.finishedAt !== 'undefined' && user.users_sessions.finishedAt != null){
                            if(vueapp.moment(user.users_sessions.finishedAt).isValid()){
                                finishedAt = vueapp.moment(user.users_sessions.finishedAt);
                            }
                        }
                        
                        if(user.users_sessions.data != null && typeof user.users_sessions.data !== 'undefined')
                            game_finished = (user.users_sessions.data.game_over != null && typeof user.users_sessions.data.game_over !== 'undefined') ? ((user.users_sessions.data.game_over == true) ? false : true) : false;
                    
                        if(startedAt != null && finishedAt != null && game_finished == true){
                            spent_time = vueapp.moment.duration(finishedAt.diff(startedAt)).asSeconds();
                        }
                        // Add new row
                        this.datatable_ranking.row.add([0, username, spent_time]);
                    }
                }
                // Order and draw the new content
                this.datatable_ranking
                    .order([ 2, 'asc' ])
                    .draw();
                
                // Update each row from column 0 (Player position)
                var position = 1;
                this.datatable_ranking
                    .rows()
                    .every( function ( rowIdx, tableLoop, rowLoop ) {
                        var data = this.data();

                        // Adjust played time for each row of the table.
                        var formated_time = null, minutes = null, seconds = null;
                        if(data[2] == 99999)
                            formated_time = "-"
                        else{
                            minutes = Math.floor(data[2] / 60);
                            seconds = Math.round(data[2] - minutes * 60);
                            formated_time = minutes + " m " + seconds+ " s";
                        }
                        // Adjust player position.
                        data[0] = (minutes>=process.env.VUE_APP_GAME_DURATION || minutes == null) ? '-' : position+'º'; //col #0 (Player position)

                        // Adjust time spent in the game.
                        data[2] = formated_time; //col #5 (Time played)
                        this.data(data)
                        position++;
                    })
            }
        }
    }
</script>

<style>
    .white-bg{
        background: rgb(255 255 255 / 17%) !important;
    }
    .sorting:before, .sorting_asc:before, .sorting_desc:before, .sorting:after, .sorting_asc:after, .sorting_desc:after{
        content: "" !important;
    }
</style>
