
//console.log = function() {}

import 'babel-polyfill'

const GAME_TIME = 45;
const MAX_GIVEN_SCORE = 200;
const USERS = {
    PLAYER:         0,
    ADMIN:          1,
    SUPER_ADMIN:    2
};
window['USERS'] = USERS;

const STATUS = {
    IDLE: 0,
    STARTING: 1,
    STARTED: 2,
    STOPPED: 3,
    FINISHED: 4
};
window['STATUS'] = STATUS;

const ACCESS_MODE = {
    OPENED: 0,
    LOGIN: 1,
    DEMO: 2
};
window['ACCESS_MODE'] = ACCESS_MODE;

const GAME_MODES = {
    SINGLEPLAYER: 0,
    MULTIPLAYER: 1
};
window['GAME_MODES'] = GAME_MODES;

window['GAME_TIME'] = GAME_TIME;
window['MAX_GIVEN_SCORE'] = MAX_GIVEN_SCORE;

import cssVars from 'css-vars-ponyfill';

cssVars({
    // Only styles from CodePen's CSS panel
    include: 'style:not([data-ignore])',
    // Treat all browsers as legacy
    onlyLegacy: false,
    
    // DEMO: Toggles to see results
    // ----------------------------
    // preserveStatic: false,
    // preserveVars: true,
    // updateURLs: false,
    // variables: { '--color': 'purple' },
    // ----------------------------
});

import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import BootstrapVue from "bootstrap-vue"
import App from './App.vue'
import dispatcher from './clientdispatcher.js'
import VueSession from 'vue-session'
import store from './store/store.js'
import router from './router'
import RPC from '../../shared/RPC'

import moment from 'moment'

import dotenv from 'dotenv'
dotenv.config()

Vue.use(VueToast);

Vue.prototype.moment = moment

Vue.prototype.$USERS = USERS;
Vue.prototype.$GAME_MODES = GAME_MODES;
Vue.prototype.$STATUS = STATUS;
Vue.prototype.$ACCESS_MODE = ACCESS_MODE;

//https://github.com/ymyang/vue-photoswipe-demo
import VuePhotoSwipe from 'vue-photoswipe'

Vue.use(VuePhotoSwipe)

//Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExpand, faKey, faTimes, faCheck, faUndoAlt, faInfo, faTrash, faPencilAlt, faLink, faList, faUsers, faArrowLeft, faPlus, faCompress, faQuestion, faBroom } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faExpand, faKey, faTimes, faCheck, faUndoAlt, faInfo, faTrash, faPencilAlt, faLink, faList, faUsers, faArrowLeft, faPlus, faCompress, faQuestion, faBroom)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import "animate.css"

//jQuery
window.$ = window.jQuery = require('jquery')
window.isEmptyArray = function(emptyArray) {
        return (Array.isArray(emptyArray) && emptyArray.length === 0)
    }
    //Loading plugin
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);

// Init plugin
Vue.use(Loading);

//Vuex
Vue.use(store);

//moment.js
Vue.use(require('vue-moment'));

//Date time picker
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

Vue.use(datePicker);

Vue.use(BootstrapVue)
Vue.use(VueSession, { persist: true });

Vue.config.productionTip = false

Vue.prototype.$dispatcher = dispatcher;



import dt from 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons';
import { param } from 'jquery';

//import 'datatables.net-buttons/js/dataTables.buttons.min.js';
//import 'datatables.net-buttons/js/buttons.print.min.js';
//import 'datatables.net-buttons/js/buttons.colVis.js'
//import 'datatables.net-buttons/js/buttons.html5.min.js'

window.$.DataTable = dt;

window['countdown_initial'] = process.env.VUE_APP_COUNTDOWN_INITIAL;
window['game_id'] = process.env.VUE_APP_GAME_ID;
window['vueapp'] = new Vue({
    router,
    store,
    render: h => h(App),
    components: {
        Loading
    },
    data: function() {
        return {
            visible: false,
            loader: null
        };
    },
    methods: {
        open() {
            this.loader = this.$loading.show({
                loader: 'dots'
            });
        },
        close() {
            setTimeout(() => {
                    this.loader.hide();
                    game.adjustGame();
                }
                , 1000)
        },
    },
	beforeCreate() {
		this.$store.commit('initialiseStore');
    },
    created() {
        if (performance.navigation.type == 1) {
            if(this.$route.path != '') {
                this.$router.push({path: '/'}).catch((e)=>{console.log(e)});
            }       
        }
    }
}).$mount('#app')

vueapp.$store.commit("setLoading", true);

//router global variable.
window['router'] = router;
window['game_time'] = new Date().getTime() + (60000*process.env.VUE_APP_COUNTDOWN_MINUTES);
window['handleUser'] = function(){    
    console.log("handleUser")
    // Check if we have a information about the user in the vuex
    if(vueapp.$store.state.user == null){
        // If the user is null, we understand that the client should be redirected to home by default.
        // If the url contains login or login with id, we are handling the redirection too.
        if(window.location.pathname.startsWith("/login")){
            var splitted_pathname = window.location.pathname.split("/");
            if(splitted_pathname.length==2){
                router.push({name:'login'}).catch((e)=>{console.log(e)});
            }else if(splitted_pathname.length==3){            
                router.push({name:'login', params: {session_id: splitted_pathname[2]}}).catch((e)=>{console.log(e)});
            }
        }else{
            router.push({name:"home"}).catch((e)=>{console.log(e)});
        }
        vueapp.$store.commit("setLoading", false);
    }else{
        vueapp.$store.commit("setLoading", false);
        // Update user session token.
        if(vueapp.$store.state.user.session_token != null && typeof vueapp.$store.state.user.session_token !== 'undefined')
            vueapp.$session.set("session_token", vueapp.$store.state.user.session_token);
        // Update user session id.
        if(vueapp.$store.state.user.session_id != null && typeof vueapp.$store.state.user.session_id !== 'undefined')
            window.localStorage.setItem("session_id", vueapp.$store.state.user.session_id);
        // If there is an existing user information saved in vuex.
        // Check if the user role is a player or admin/superadmin
        if(vueapp.$store.state.user.role == USERS.PLAYER){
            // At this point we are supposed to have user and session information.

            // update last session id.
            window.localStorage.setItem("session_id", vueapp.$store.state.user.session_id);

            // Handle session type
            if(vueapp.$store.state.session.game_mode == GAME_MODES.SINGLEPLAYER){
                // If the session is singleplayer, check user session data.
                console.log("GAME SINGLEPLAYER")

                // If user has user_session data.
                
                console.log(vueapp.$store.state.user.session.users_sessions)
                // Update user_session information
                var user_session_data = vueapp.$store.state.user.session.users_sessions;
                console.log(user_session_data)
                if(user_session_data != null && typeof user_session_data !== 'undefined'){
                    console.log("existe user_session")
                    if(user_session_data.data != null){
                        console.log("leemos .data")
                        vueapp.$store.state.last_url = user_session_data.data.last_url;
                        vueapp.$store.state.back_url = user_session_data.data.back_url;
                        vueapp.$store.state.scenes = user_session_data.data.scenes;
                        vueapp.$store.state.timer = user_session_data.data.timer;
                        vueapp.$store.state.fs_message = user_session_data.data.fs_message;
                        vueapp.$store.state.game_state = user_session_data.data.game_state;
                        vueapp.$store.state.game_over = user_session_data.data.game_over;
                        vueapp.$store.state.timer = user_session_data.data.timer;
                        //vueapp.$store.state.startedAt = vueapp.moment(vueapp.$store.state.session.startedAt).valueOf();
                        vueapp.$store.state.startedAt = vueapp.moment(user_session_data.data.startedAt).valueOf();
                        vueapp.$store.state.finishedAt = user_session_data.data.finishedAt;
                    }
                }
                

                // Check the game startedAt and finishAt.
                // By this way we check if the game has ended by the finishAt time.
                if(vueapp.$store.state.timer != null){
                    if(moment(vueapp.$store.state.timer).valueOf() < new Date().getTime()){
                        // update session status.
                        //vueapp.$store.state.session.status = STATUS.FINISHED;
                        
                        vueapp.$emit('gameFinished', '');
                    }else{
                        vueapp.$store.commit("setTimer", moment(vueapp.$store.state.timer).valueOf());
                    }
                }

                if(vueapp.$store.state.last_url != null && typeof vueapp.$store.state.last_url !== 'undefined'){
                    console.log("router push to: "+vueapp.$store.state.last_url)
                    router.push({name: vueapp.$store.state.last_url}).catch((e)=>{console.log(e)});
                }else{
                    console.log("router push to: scene1cc1start")
                    router.push({name: "scene1cc1start"}).catch((e)=>{console.log(e)});
                }
                return;
            }
            if(vueapp.$store.state.session.game_mode == GAME_MODES.MULTIPLAYER){
                // If the session is multiplayer, check user session data.
                console.log("GAME MULTIPLAYER");
                console.log(vueapp.$store.state.session.startedAt)
                console.log(vueapp.$store.state.startedAt)
                // Update user_session information
                var user_session_data = vueapp.$store.state.user.session.users_sessions;
                if(user_session_data != null && typeof user_session_data !== 'undefined'){
                    if(user_session_data.data != null){
                        vueapp.$store.state.last_url = user_session_data.data.last_url;
                        vueapp.$store.state.back_url = user_session_data.data.back_url;
                        vueapp.$store.state.scenes = user_session_data.data.scenes;
                        vueapp.$store.state.fs_message = user_session_data.data.fs_message;
                        //vueapp.$store.state.game_state = user_session_data.data.game_state;
                        vueapp.$store.state.game_over = user_session_data.data.game_over;
                        vueapp.$store.state.finishAt = vueapp.$store.state.session.finishAt;
                        vueapp.$store.state.startedAt = vueapp.moment(vueapp.$store.state.session.startedAt).valueOf();
                        vueapp.$store.state.finishedAt = user_session_data.data.finishedAt;
                    }
                }else{
                    vueapp.$store.state.startedAt = vueapp.moment(vueapp.$store.state.session.startedAt).valueOf();
                }

                console.log(vueapp.$store.state.session.startedAt)
                console.log(vueapp.$store.state.startedAt)
                // Check the game startedAt and finishAt.
                // By this way we check if the game has ended by the finishAt time.
                // Calculate the timer by startedAt time.
                var finishAt = vueapp.moment(vueapp.$store.state.session.startedAt).valueOf() + (60000*process.env.VUE_APP_COUNTDOWN_MINUTES);
                console.log(finishAt)
                if(moment(finishAt).valueOf() < new Date().getTime()){
                    // update session status.
                    vueapp.$store.state.session.status = STATUS.FINISHED;
                }else{
                    vueapp.$store.commit("setTimer", moment(finishAt).valueOf());
                }
                vueapp.$store.state.timer = finishAt;

                // Handle user redirection acordingly with session status.
                handle_multiplayer_session_status();
                return;
            }
        }else if(vueapp.$store.state.user.role == USERS.ADMIN || vueapp.$store.state.user.role == USERS.SUPER_ADMIN){   
            // At this point we are supposed to have user information only because the admin do not need specific information about a session.
            if(vueapp.$store.state.last_url){
                if(vueapp.$store.state.last_url.includes(':')){
                    var last_url = vueapp.$store.state.last_url.split(':');
                    var route_name = last_url[0];
                    var session_id = last_url[1];
                    router.push({name:route_name, params:{id:session_id}}).catch((e)=>{console.log(e)});
                }else{
                    router.push({name:vueapp.$store.state.last_url}).catch((e)=>{console.log(e)});
                }
                return;
            }else{
                router.push({name:"panel"}).catch((e)=>{console.log(e)});
                return;
            }
        }
    }
}

window['handle_multiplayer_session_status'] = function(){
    var session = null;
    session = vueapp.$store.state.session;
    if(session != null){
        switch(session.status){
            case STATUS.IDLE:
                router.push({name:"lobby"}).catch((e)=>{console.log(e)});
            break;
            case STATUS.STARTING:
                router.push({name:"democountdown"}).catch((e)=>{console.log(e)});
            break;
            case STATUS.STARTED:
                if(vueapp.$store.state.last_url != null && typeof vueapp.$store.state.last_url !== 'undefined')
                    router.push({name:vueapp.$store.state.last_url}).catch((e)=>{console.log(e)});
                else
                    router.push({name:"demomap"}).catch((e)=>{console.log(e)});
            break;
            case STATUS.STOPPED:
                router.push({name:"lobby"}).catch((e)=>{console.log(e)});
            break;
            case STATUS.FINISHED:
                router.push({name:"end"}).catch((e)=>{console.log(e)});
            break;
        }
    }
}

window['destroy_user'] = function(){
    console.log("destroy_user")
    vueapp.$store.state.user = null;
    vueapp.$session.set("session_token", null);
    vueapp.$session.remove("session_token");
    vueapp.$session.destroy();
    vueapp.$store.commit("setUser", null);
    vueapp.$store.commit("setDemoState",{demo: false});
    vueapp.$store.commit("setTimer", null);
    reset_user_session_info();
    localStorage.clear();
}

window['reset_user_session_info'] = function(){
    vueapp.$store.state.startedAt = null;
    vueapp.$store.state.finishedAt = null;
    vueapp.$store.state.last_url = null;
    vueapp.$store.state.back_url = null;
    if(vueapp.$store.state.session != null)
        vueapp.$store.state.session.status = STATUS.IDLE;
    vueapp.$store.state.scenes = {
        demoscene1:{
            activities: {},
            locked: false,
            completed: false,
            scene: null
        },
        demoscene2:{
            activities: {},
            locked: true,
            completed: false,
            scene: null
        },
        demoscene3:{
            activities: {},
            locked: true,
            completed: false,
            scene: null
        },
        demoscene4:{
            activities: {},
            locked: true,
            completed: false,
            scene: null
        },
        demoscene5:{
            activities: {},
            locked: true,
            completed: false,
            scene: null
        },
    };
    vueapp.$store.state.timer = null;
}

window['startCountDown'] = function(){
    vueapp.$store.commit("setFullScreenMessage", false);
    $("#countdown").fadeIn(); 
    vueapp.$store.commit('setCountDown', countdown_initial);   
    if(vueapp.$store.state.beep){    
        if(typeof vueapp.$store.state.beep.pause !== 'undefined')     
            vueapp.$store.state.beep.pause();   
        if(typeof vueapp.$store.state.beep.play !== 'undefined')  
            vueapp.$store.state.beep.play(); 
    }
    var countdown_timer = setInterval(function() {    
        if(vueapp.$store.state.beep){    
            if(typeof vueapp.$store.state.beep.pause !== 'undefined')     
                vueapp.$store.state.beep.pause();   
            if(typeof vueapp.$store.state.beep.play !== 'undefined')  
                vueapp.$store.state.beep.play(); 
        }
        vueapp.$store.commit('setCountDown', vueapp.$store.state.countdown - 1);
        if(vueapp.$store.state.countdown == 0 || vueapp.$store.state.countdown < 0) {
            clearInterval(countdown_timer);                   
            // Set startedAt date now
            vueapp.$store.state.startedAt = new Date().getTime();
            // Set the game time finish at...
            vueapp.$store.state.timer = new Date().getTime() + (60000*process.env.VUE_APP_COUNTDOWN_MINUTES);
            updateUserSession();
            console.log("GOING TO MAP FROM COUNTDOWN FN")
            router.push({name:"demomap"}).catch((e)=>{console.log(e)});
        }
    }.bind(this), 1000);
    vueapp.$store.commit("setCountDownInterval", countdown_timer);
}

window['resetDemo'] = function(){
    localStorage.clear();
    vueapp.$store.commit('resetGlobalState');
    router.push({name:"start"}).catch((e)=>{console.log(e)});
    clearInterval(window.countdown_interval);
    clearInterval(window.timer_interval);
}

window['getActivityStatus'] = function(scene_id, act_id){
    var found_activity = (vueapp.$store.state.demo.demo) ? 
        vueapp.$store.state.demo.scenes[scene_id].activities[act_id] : 
        vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity)
        return found_activity;
    
    return null;    
}

window['setActivityStatus'] = function(scene_id, act_id, data){
    var found_activity = (vueapp.$store.state.demo.demo) ? 
        vueapp.$store.state.demo.scenes[scene_id].activities[act_id] : 
        vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity){
        found_activity.data = data;
        vueapp.$store.commit("setScenes", (vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.scenes : vueapp.$store.state.scenes);
    }
}

window['setActivityVisited'] = function(scene_id, act_id){
    console.log("setActivityVisited",scene_id, act_id)
    var found_activity = (vueapp.$store.state.demo.demo) ? 
        vueapp.$store.state.demo.scenes[scene_id].activities[act_id] : 
        vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity){
        found_activity.visited = true;        
        vueapp.$store.commit("setScenes", (vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.scenes : vueapp.$store.state.scenes);
    }
}

window['setSceneLocked'] = function(scene_id, locked){
    var found_scene = (vueapp.$store.state.demo.demo) ? 
        vueapp.$store.state.demo.scenes[scene_id] : 
        vueapp.$store.state.scenes[scene_id];
    if(found_scene){
        found_scene.locked = locked;        
        vueapp.$store.commit("setScenes", (vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.scenes : vueapp.$store.state.scenes);
    }
}

window['setActivityLocked'] = function(scene_id, act_id, locked){
    var found_activity = (vueapp.$store.state.demo.demo) ? 
        vueapp.$store.state.demo.scenes[scene_id].activities[act_id] : 
        vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity){
        found_activity.locked = locked;        
        vueapp.$store.commit("setScenes", (vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.scenes : vueapp.$store.state.scenes);
    }
}

window['setActivityCompleted'] = function(scene_id, act_id, completed){
    var found_activity = (vueapp.$store.state.demo.demo) ? 
        vueapp.$store.state.demo.scenes[scene_id].activities[act_id] : 
        vueapp.$store.state.scenes[scene_id].activities[act_id];
    if(found_activity){
        found_activity.completed = completed;      
        vueapp.$store.commit("setScenes", (vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.scenes : vueapp.$store.state.scenes);
    }
}

window['setSceneCompleted'] = function(scene_id, completed){
    var found_scene = (vueapp.$store.state.demo.demo) ? 
        vueapp.$store.state.demo.scenes[scene_id] : 
        vueapp.$store.state.scenes[scene_id];
    if(found_scene){
        found_scene.completed = completed;      
        vueapp.$store.commit("setScenes", (vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.scenes : vueapp.$store.state.scenes);
    }
}

/*window['updateUserSession'] = function(){
    var user_scenes = {};
    for (var prop in vueapp.$store.state.scenes) {
        user_scenes[prop] = {
            activities: {},
            completed: vueapp.$store.state.scenes[prop].completed,
            locked: vueapp.$store.state.scenes[prop].locked
        };
        for (var prop1 in vueapp.$store.state.scenes[prop].activities) {
            user_scenes[prop].activities[prop1] = {
                completed: vueapp.$store.state.scenes[prop].activities[prop1].completed,
                data: vueapp.$store.state.scenes[prop].activities[prop1].data,
                locked: vueapp.$store.state.scenes[prop].activities[prop1].locked,
                visited: vueapp.$store.state.scenes[prop].activities[prop1].visited
            };
        }
    }
    console.log(user_scenes)
    ws.send(JSON.stringify({action:RPC.UPDATE_USER_SESSION, params:{
        last_url: vueapp.$store.state.last_url,
        back_url: vueapp.$store.state.back_url,
        score: vueapp.$store.state.score,
        scenes: user_scenes,
    }}));
}*/

window['updateUserSession'] = function(){
    console.log('updateUserSession')
    if(vueapp.$store.state.user != null && typeof vueapp.$store.state.user !== 'undefined'){
        if(vueapp.$store.state.user.role == USERS.PLAYER){
            var user_scenes = {};
            for (var prop in vueapp.$store.state.scenes) {
                user_scenes[prop] = {
                    activities: {},
                    completed: vueapp.$store.state.scenes[prop].completed,
                    locked: vueapp.$store.state.scenes[prop].locked
                };
                for (var prop1 in vueapp.$store.state.scenes[prop].activities) {
                    user_scenes[prop].activities[prop1] = {
                        completed: vueapp.$store.state.scenes[prop].activities[prop1].completed,
                        data: vueapp.$store.state.scenes[prop].activities[prop1].data,
                        locked: vueapp.$store.state.scenes[prop].activities[prop1].locked,
                        visited: vueapp.$store.state.scenes[prop].activities[prop1].visited
                    };
                }
            }
            
            ws.send(JSON.stringify(
                {
                    action:RPC.UPDATE_USER_SESSION, 
                    params:{
                        last_url: vueapp.$store.state.last_url,
                        back_url: vueapp.$store.state.back_url,
                        startedAt: vueapp.$store.state.startedAt,
                        finishedAt: vueapp.$store.state.finishedAt,
                        final_score: vueapp.$store.state.final_score,
                        score: vueapp.$store.state.score,
                        game_over: vueapp.$store.state.game_over,
                        scenes: user_scenes,
                        game_state: vueapp.$store.state.game_state,
                        timer: vueapp.$store.state.timer,
                        fs_message: vueapp.$store.state.fs_message
                    }
                }
            ));
        }else{
            
            ws.send(JSON.stringify(
                {
                    action:RPC.UPDATE_USER_SESSION, 
                    params:{
                        user_id: vueapp.$store.state.user.id,
                        session_id: null,
                        last_url: vueapp.$store.state.last_url,
                        back_url: vueapp.$store.state.back_url,
                        finishedAt: "", 
                        startedAt: "", 
                        final_score: ""
                    }
                }
            ));
        }
    }
}

//Function for compare array items
window['compareArrays'] = function(arr, target) { 
    return (JSON.stringify(target) === JSON.stringify(arr));
};

//Function for compare array items for order activity
window['compareOrderArrays'] = function(arr, target) {
    var new_array = [];
    for(var i = 0; i < arr.length; i++)
        new_array.push(arr[i].val);
        
    return (JSON.stringify(target) === JSON.stringify(new_array));
};

window['isGameFinished'] = function() {
    return (new Date().getTime() > game_time);
};

window['finishGame'] = function(){
    //alert("finishhhhh")
    //game_time
    var time_now = new Date().getTime();
    vueapp.$store.commit("setTimer", null)
    router.push({name:((vueapp.$store.state.demo.demo === true) ? 'demoend':'end')})
}

window['RPC'] = RPC;

window['client_join'] = function(){
    console.log("client_join")
    var session_token = vueapp.$session.get("session_token");
    if(session_token){
        //vueapp.$store.commit("setLoading", true);
        ws.send(JSON.stringify({action: RPC.CLIENT_SESSION_CHECK, params: {session_token:session_token, session_id: window.localStorage.getItem("session_id")}}))
    }
    handleUser();
}

window['client_login'] = function(params){
    console.log("client_login", params);
    vueapp.$store.commit("setUser", params);

    // Update user last url.
    if(params.last_url != null && typeof params.last_url !== 'undefined'){
        vueapp.$store.state.last_url = params.last_url;
    }

    // Update user session.
    if(params.session_token){
        vueapp.$session.set("session_token",params.session_token);
    }

    // update last session id.
    if(params.session_id){
        window.localStorage.setItem("session_id", params.session_id);
    }

    // Update session information.
    if(params.session != null && typeof params.session !== 'undefined'){
        vueapp.$store.commit("setSession", params.session);
        //vueapp.$store.commit("setGameState", params.session.status);
    }

    handleUser();
}

window['client_register'] = function(params){
    console.log("client_register", params);
    
}

window['client_ping'] = function(){
    ws.send(JSON.stringify({action: RPC.CLIENT_PING, params: null}))
}

window['client_toast'] = function(params){
    if(params.message)
    vueapp.$toast[params.type](params.message, {})
}

window['client_session_check'] = function(params){
    console.log("client_session_check",params);
    vueapp.$store.commit("setUser", params);

    // Update user last url.
    if(params.last_url != null && typeof params.last_url !== 'undefined'){
        vueapp.$store.state.last_url = params.last_url;
    }

    // Update user session.
    if(params.session_token){
        vueapp.$session.set("session_token",params.session_token);
    }

    // update last session id.
    if(params.session_id){
        window.localStorage.setItem("session_id", params.session_id);
    }

    // Update session information.
    if(params.session != null && typeof params.session !== 'undefined'){
        vueapp.$store.commit("setSession", params.session);
        //vueapp.$store.commit("setGameState", params.session.status);
    }

    handleUser();
}

window['client_session_expire'] = function(){
    destroy_user();
    handleUser();
}

window['get_sessions'] = function(params){
    console.log("get_sessions", params);
    vueapp.$store.commit("setPanelSessions", params);
}

window['get_session'] = function(params){
    console.log("get_session", params);
    params['link'] = window.location.origin+'/login/'+params.id;
    var session = params;
    if(session.users.length>0){
        var users = [];
        users = session.users;
        session.users = [];
        for(var i = 0; i < users.length; i++){
            session.users.push(users[i].user);
        }
    }
    vueapp.$store.commit("setSession", session);
    if(params){
        if(vueapp.$store.state.user == null) 
            return;
        if(vueapp.$store.state.user.role == USERS.PLAYER){
            if(params.finishAt){
                vueapp.$store.commit("setTimer", moment(params.finishAt).valueOf())
            }
            if(params.status){
                vueapp.$store.state.game_state = params.status;
            }
            if(params.status == STATUS.STOPPED || params.status == STATUS.IDLE){
                vueapp.$store.commit("setTimer", null);
            }
        }
    }
    
}

window['get_users'] = function(params){
    console.log("get_users", params);
    var users = [];
    for(var i = 0; i < params.users.length; i++){
        users.push(params.users[i]);
    }
    vueapp.$store.commit("setPanelUsers", users);
    vueapp.$store.commit("setPanelUsersPagination", {
        page: params.page, 
        limit: params.limit, 
        offset: params.offset, 
        total_pages: params.total_pages
    });
}

window['get_user'] = function(params){
    console.log("get_user", params);

    if(params.session_id){
        ws.send(JSON.stringify({action: RPC.GET_SESSION, params: params.session_id}));
    }
    // TODO
}

window['client_logout'] = function(params){
    console.log("client_logout", params);
    // Local user
    if(vueapp.$store.state.user != null){
        if(vueapp.$store.state.session != null && vueapp.$store.state.user.role == USERS.PLAYER){
            router.push('/login/'+vueapp.$store.state.session.id).catch((e)=>{console.log(e)});
        }else
            router.push({name:'login'}).catch((e)=>{console.log(e)});

    }
    destroy_user();
}

window['register_session'] = function(params){
    console.log("register_session", params);
    vueapp.$store.state.panel_sessions.push(params);
}

window['remove_session'] = function(params){
    console.log("remove_session", params);
    if(vueapp.$store.state.user.role == USERS.ADMIN || vueapp.$store.state.user.role == USERS.SUPER_ADMIN){
        var final_sessions = [];
        var found = false;
        for(var i = 0; i < Object.keys(vueapp.$store.state.panel_sessions).length && found == false; i++){
            console.log("Comparing "+vueapp.$store.state.panel_sessions[i].id+" - with - "+params+ "condition:"+(vueapp.$store.state.panel_sessions[i].id != params))
            if(vueapp.$store.state.panel_sessions[i].id != params){
                final_sessions.push(vueapp.$store.state.panel_sessions[i]);
            }
        }
        if(final_sessions.length == 0)
            vueapp.$emit('emptySessionsTable', '');
        vueapp.$store.commit("setPanelSessions", final_sessions);
    }
    if(vueapp.$store.state.user.role == USERS.PLAYER && vueapp.$store.state.session.id == params){
        vueapp.$store.commit("setSession", null);
        vueapp.$store.commit("setTimer", null);
        vueapp.$toast.warning('El administrador ha borrado la sesión de juego!', {duration: 9000})

        
        destroy_user();
        //reset_user_session_info();

        handleUser();
    }
}

window['remove_user'] = function(params){
    console.log("remove_user", params);
    if(vueapp.$store.state.panel_users.length>0 && (vueapp.$store.state.user.role == USERS.ADMIN || vueapp.$store.state.user.role == USERS.SUPER_ADMIN)){
        var found = false;
        for(var i = 0; i < vueapp.$store.state.panel_users.length && found == false; i++){
            if(vueapp.$store.state.panel_users[i].id == params){
                found = true;
                vueapp.$store.state.panel_users.splice(i, 1);
            }
        }
    }
    if(vueapp.$store.state.user.role == USERS.PLAYER && params == vueapp.$store.state.user.id){
        destroy_user();
        handleUser()
    }
}

window['update_user'] = function(params){
    console.log("update_user", params);
    if(params.id == vueapp.$store.state.user.id){
        vueapp.$store.state.user.username = params.username;
    }
}

window['register_user'] = function(params){
    console.log("register_user", params);
    vueapp.$store.state.panel_users.push(params);
}

window['set_user_state'] = function(params){
    console.log("set_user_state", params);
    
}

window['client_connect'] = function(params){
    console.log("client_connect", params);
    //Update panel users
    if(vueapp.$store.state.panel_users.length>0){
        var found = false;
        var user = null;
        for(var i = 0; i < vueapp.$store.state.panel_users.length && found == false; i++){
            if(vueapp.$store.state.panel_users[i].id == params.id){
                found = true;
                vueapp.$store.state.panel_users[i].is_connected = true;
                user = vueapp.$store.state.panel_users[i];
                vueapp.$store.state.panel_users.splice(i , 1);
                vueapp.$store.state.panel_users.push(user);
            }
        }
    }
    //Update session users
    if(vueapp.$store.state.session==null) return;
    if(vueapp.$store.state.session.users==null) return;
    if(vueapp.$store.state.session.users.length>0){
        var found = false;
        var user = null;
        for(var i = 0; i < vueapp.$store.state.session.users.length && found == false; i++){
            if(vueapp.$store.state.session.users[i].id == params.id){
                found = true;
                vueapp.$store.state.session.users[i].is_connected = true;
                user = vueapp.$store.state.session.users[i];
                vueapp.$store.state.session.users.splice(i , 1);
                vueapp.$store.state.session.users.push(user);
            }
        }
        if(!found){
            vueapp.$store.state.session.users.push({user:params});
        }
    }else{
        vueapp.$store.state.session.users.push({user:params});
    }
}

window['client_disconnect'] = function(params){
    console.log("client_disconnect", params);
    //Update panel users
    if(vueapp.$store.state.panel_users.length>0){
        var found = false;
        var user = null;
        for(var i = 0; i < vueapp.$store.state.panel_users.length && found == false; i++){
            if(vueapp.$store.state.panel_users[i].id == params){
                found = true;
                vueapp.$store.state.panel_users[i].is_connected = false;
                user = vueapp.$store.state.panel_users[i];
                vueapp.$store.state.panel_users.splice(i , 1);
                vueapp.$store.state.panel_users.push(user);
            }
        }
    }
    //Update session users
    if(vueapp.$store.state.session.users==null) return;
    if(vueapp.$store.state.session.users.length>0){
        var found = false;
        var user = null;
        for(var i = 0; i < vueapp.$store.state.session.users.length && found == false; i++){
            if(vueapp.$store.state.session.users[i].id == params){
                found = true;
                vueapp.$store.state.session.users[i].is_connected = false;
                user = vueapp.$store.state.session.users[i];
                vueapp.$store.state.session.users.splice(i , 1);
                vueapp.$store.state.session.users.push(user);
            }
        }
    }
}

window['session_update_status'] = function(params){
    console.log("session_update_status", params);

    if(vueapp.$store.state.user){
        // If the user is a player and we have the same params.id as the session.id or user.session_id
        if(vueapp.$store.state.user.role == USERS.PLAYER && vueapp.$store.state.session.id == params.id){

            //Update game state too
            vueapp.$store.state.game_state = params.status;
            vueapp.$store.commit("setGameState", params.status);
            //Update session status
            vueapp.$store.state.session.status = params.status;

            if(params.status == STATUS.STOPPED || params.status == STATUS.FINISHED){
                vueapp.$store.commit("setTimer", null);
            }
        }else if(vueapp.$store.state.user.role == USERS.ADMIN || vueapp.$store.state.user.role == USERS.SUPER_ADMIN){

            //Update game state too
            vueapp.$store.state.game_state = params.status;
            vueapp.$store.commit("setGameState", params.status);
            //Update session status
            vueapp.$store.state.session.status = params.status;
            vueapp.$store.state.session.startedAt = (params.startedAt != null && typeof params.startedAt !== 'undefined') ? params.startedAt : null;
            vueapp.$store.state.session.finishAt = (params.finishAt != null && typeof params.finishAt !== 'undefined') ? params.finishAt : null;
            //alert("ADMIN: El juego ha comenzado");
            var sessions = JSON.parse(JSON.stringify(vueapp.$store.state.panel_sessions));
            console.log(sessions);
            for(var i = 0; i < sessions.length; i++){
                if(sessions[i].id == params.id){
                    sessions[i].status = params.status;
                    if(typeof params.startedAt !== 'undefined'){
                        sessions[i].startedAt = moment(params.startedAt).valueOf();
                    }
                    if(typeof params.finishAt !== 'undefined'){
                        sessions[i].finishAt = moment(params.finishAt).valueOf();
                    }
                }
            }

            if(vueapp.$store.state.ranking.id == params.id)
            {
                vueapp.$store.state.ranking.status = params.status;
            }            
            vueapp.$store.commit("setSession",  vueapp.$store.state.session);
            vueapp.$store.commit("setPanelSessions", sessions);
        }
    }else{
        // If user is not authenticated
        vueapp.$store.state.game_state = params.status;
        //Update session status
        vueapp.$store.state.session.status = params.status;
    }
}

window['get_user_session'] = function(params){
    console.log("get_user_session", params);
    if(params == null){
        router.push({name: "lobby"}).catch((e)=>{console.log(e)});
        return;
    }
    //vueapp.$store.commit("setSession", params);
    /*if(params.finishAt){
        console.log("esta en juego ");
        vueapp.$store.commit("setTimer", moment(params.finishAt).valueOf())
    }*/
    // Cleanup current scenes data
    vueapp.$store.commit("setScenes", null);
    if(params.data != null){
        // Update stored information from the server into vuex
        if(params.data.scenes!=null)
            vueapp.$store.commit("setScenes", params.data.scenes);
        if(params.data.game_over!=null)
            vueapp.$store.commit("setGameOver", params.data.game_over);
        if(params.data.fs_message!=null)
            vueapp.$store.commit("setFullScreenMessage", params.data.fs_message);
        if(params.data.back_url!=null)
            vueapp.$store.commit("setBackURL", params.data.back_url);
        if(params.data.last_url!=null){
            vueapp.$store.commit("setLastURL", params.data.last_url);
        }
        /*if(vueapp.$store.state.session.status == STATUS.IDLE){
            vueapp.$store.commit("setScenes", null);
            vueapp.$store.commit("setGameOver", false);
            vueapp.$store.commit("setGameState", STATUS.IDLE);
            vueapp.$store.commit("setTimer", null);
            vueapp.$store.commit("setBackURL", null);
            vueapp.$store.commit("setLastURL", null);
            vueapp.$store.commit("setFullScreenMessage", false);
        }*/
    }else{
        console.log("El usuario no tiene info de su juego");
    }

    console.log("Modo de juego: "+vueapp.$store.state.session.game_mode)

    // Update game state on the client.
    if(vueapp.$store.state.session.game_mode == GAME_MODES.MULTIPLAYER){
        console.log("Modo multiplayer, restauramos el de la tabla de sessions.");
        console.log(vueapp.$store.state.session.status);

        if(params.session != null && typeof params.session !== 'undefined'){
            vueapp.$store.commit("setSession", params.session);
        }

        vueapp.$store.commit("setTimer", moment(vueapp.$store.state.session.finishAt).valueOf());
        vueapp.$store.commit("setGameState", vueapp.$store.state.session.status);
        vueapp.$store.state.startedAt = vueapp.moment(vueapp.$store.state.session.startedAt).valueOf();

        if(vueapp.$store.state.session.status == STATUS.IDLE ){
            vueapp.$store.commit("setTimer", null);
            router.push({name:'lobby'}).catch((e)=>{console.log(e)});
        }
        if(vueapp.$store.state.session.status == STATUS.STOPPED ){
            vueapp.$store.commit("setTimer", null);
            router.push({name:'lobby'}).catch((e)=>{console.log(e)});
        }
        if(vueapp.$store.state.session.status == STATUS.STARTED && params.data == null){
            router.push({name:"demomap"}).catch((e)=>{console.log(e)});
            return;
        }

        if(params.data !== null){
            router.push({name:params.data.last_url}).catch((e)=>{console.log(e)});
            return;
        }

        if(moment(vueapp.$store.state.session.finishAt).valueOf() < new Date().getTime()){
            router.push({name:'end'}).catch((e)=>{console.log(e)});
            return;
        }
        return;
    }else{
        console.log("Modo singleplayer, restauramos el de la tabla de user_sessions.");
        
        // Check if user has saves user_session data for get the game state.
        if(params.data != null){

            vueapp.$store.commit("setStartedAt", params.data.startedAt);
            vueapp.$store.commit("setTimer", params.data.startedAt + (60000*process.env.VUE_APP_GAME_DURATION));
            vueapp.$store.state.session.status = params.data.game_state;
            vueapp.$store.state.game_state = params.data.game_state;
            
            //if((params.data.startedAt + (60000*process.env.VUE_APP_GAME_DURATION)) < new Date().getTime() && params.data.last_url !== "scene1cc1start"){
            //    router.push({name:'end'}).catch((e)=>{console.log(e)});
            //    return;
            //}else 
            if(params.data.last_url == "scene1cc1start"){
                router.push({name:'scene1cc1start'}).catch((e)=>{console.log(e)});
                return;
            }else{
                router.push({name:params.data.last_url}).catch((e)=>{console.log(e)});
            }
            // If user_session information contains game_state then apply the game state.
            if(params.data.game_state){
                vueapp.$store.commit("setGameState", params.data.game_state);
                return;
            }
        }else{
            router.push({name:'scene1cc1start'}).catch((e)=>{console.log(e)});
            return;
        }
    }

    //if(moment(vueapp.$store.state.session.finishAt).valueOf() < new Date().getTime())
    //    router.push({name:'end'});

    // Default game state is STATUS.IDLE
    vueapp.$store.commit("setGameState", STATUS.IDLE);
    return;
    //console.log("modo de juego: "+vueapp.$store.state.session.game_mode)
    // Check if the session game_mode is multiplayer then get back the game state from the session data (table: 'sessions') instead of user_session data (table: 'users_sessions').
    //if(vueapp.$store.state.session.game_mode == GAME_MODES.MULTIPLAYER){ //Multiplayer
    //    console.log("multijugador, restauramos el estado d la sesion de la tabla de sesiones k es: "+vueapp.$store.state.session.status)
    //    vueapp.$store.commit("setGameState", vueapp.$store.state.session.status)
    //}else{ //Singleplayer
    //    vueapp.$store.commit("setGameState", params.data.game_state);
    //}
}

window['get_ranking'] = function(params){
    console.log("get_ranking", params);
    params['link'] = window.location.origin+'/login/'+params.id;
    vueapp.$store.commit("setRanking", params);
}

window['update_ranking'] = function(params){
    console.log("update_ranking", params);
    params['link'] = window.location.origin+'/login/'+params.id;

    // If the loged user is an admin or superadmin and we recv update_ranking packet
    // we will need to update session detail users who have already accessed to the game session
    if(vueapp.$store.state.user.role == USERS.ADMIN || vueapp.$store.state.user.role == USERS.SUPER_ADMIN && vueapp.$store.state.session != null){
        if(vueapp.$store.state.session.id == params.id){
            
            vueapp.$store.commit("setRanking", params);
            console.log(vueapp.$store.state.session.users)
            var session = vueapp.$store.state.session;
            session.users = [];
            console.log(vueapp.$store.state.session.users)
            session.users = params.users;
            console.log(vueapp.$store.state.session.users)
        }
    }
}

window['clean_user_session'] = function(params){
    console.log("clean_user_session", params);
    vueapp.$emit("hide-clean-user-session-btn", params);
    if(vueapp.$store.state.user.id == params.user_id && vueapp.$store.state.session.id == params.session_id){
        vueapp.$toast.warning('Un administrador ha borrado tus datos de juego!', {
            // optional options Object
            duration: 9000
        })

        reset_user_session_info();
        vueapp.$store.state.session.status = STATUS.IDLE;
        //handleUser();
        
        router.push({name: "scene1cc1start"}).catch((e)=>{console.log(e)});
    }
}

window['get_users_sessions'] = function(params){
    console.log("get_users_sessions", params);
    if(vueapp.$store.state.user.role == USERS.ADMIN || vueapp.$store.state.user.role == USERS.SUPER_ADMIN){
        
        vueapp.$store.commit('setUsersSessions', params.users_sessions)
        vueapp.$store.commit('setUsersSessions', params.users_sessions)
    }
}

window['log'] = function(params){
    if(typeof vueapp.$store.state.user !== 'undefined'){
        if(vueapp.$store.state.user.role == USERS.SUPER_ADMIN){
            console.log("log", params);
            var logs = vueapp.$store.state.log;
            logs.push(params);
            vueapp.$store.commit("setLog", logs);
        }
    }
}

window['log_apache'] = function(params){
    if(typeof vueapp.$store.state.user !== 'undefined'){
        if(vueapp.$store.state.user.role == USERS.SUPER_ADMIN){
            console.log("log_apache", params);
            var logs_apache = vueapp.$store.state.log_apache;
            logs_apache.push(params);
            vueapp.$store.commit("setLogApache", logs_apache);
        }
    }
}

window['ws'] = null;
window['network'] = null;
var network = {
    ws: null,
    reconnecting: false,
    registered_rpcs: {},
    protocol: '',
    host: '',
    port: '',
    keep_alive_interval: null,
    reconnect_interval: 1000,
    connect: function(callback) {
        this.host = process.env.VUE_APP_HOST;
        this.port = process.env.VUE_APP_PORT;
        this.protocol = (process.env.NODE_ENV === 'development') ? 'ws://' : 'wss://';
        this.ws = new WebSocket(this.protocol + this.host + ":" + this.port);
        window['ws'] = this.ws;
        if(network.reconnecting == true) {
            console.log(`[CLIENT] Reconnecting to '${this.protocol+this.host+":"+this.port}'`);
        } else {
            console.log(`[CLIENT] Connecting to '${this.protocol+this.host+":"+this.port}'`);
        }

        this.ws.onopen = function(callback) {
            // Register all RPC's
            network.register_rpc(RPC.CLIENT_JOIN, window.client_join);
            network.register_rpc(RPC.CLIENT_LOGIN, window.client_login);
            network.register_rpc(RPC.CLIENT_REGISTER, window.client_register);
            network.register_rpc(RPC.CLIENT_PING, window.client_ping);
            network.register_rpc(RPC.CLIENT_TOAST, window.client_toast);
            network.register_rpc(RPC.CLIENT_SESSION_CHECK, window.client_session_check)
            network.register_rpc(RPC.CLIENT_SESSION_EXPIRE, window.client_session_expire);
            network.register_rpc(RPC.GET_SESSIONS, window.get_sessions);
            network.register_rpc(RPC.GET_SESSION, window.get_session);
            network.register_rpc(RPC.GET_USERS, window.get_users);
            network.register_rpc(RPC.GET_USER, window.get_user);
            network.register_rpc(RPC.CLIENT_LOGOUT, window.client_logout);
            network.register_rpc(RPC.REGISTER_SESSION, window.register_session);
            network.register_rpc(RPC.REMOVE_SESSION, window.remove_session);
            network.register_rpc(RPC.REMOVE_USER, window.remove_user);
            network.register_rpc(RPC.UPDATE_USER, window.update_user);
            network.register_rpc(RPC.REGISTER_USER, window.register_user);
            network.register_rpc(RPC.SET_USER_STATE, window.set_user_state);
            network.register_rpc(RPC.CLIENT_CONNECT, window.client_connect);
            network.register_rpc(RPC.CLIENT_DISCONNECT, window.client_disconnect);
            network.register_rpc(RPC.SESSION_UPDATE_STATUS, window.session_update_status);
            network.register_rpc(RPC.GET_USER_SESSION, window.get_user_session);
            network.register_rpc(RPC.GET_RANKING, window.get_ranking);
            network.register_rpc(RPC.UPDATE_RANKING, window.update_ranking);
            network.register_rpc(RPC.CLEAN_USER_SESSION, window.clean_user_session);
            network.register_rpc(RPC.GET_USERS_SESSIONS, window.get_users_sessions);
            network.register_rpc(RPC.LOG, window.log);
            network.register_rpc(RPC.LOG_APACHE, window.log_apache);

            vueapp.$toast.success('Conectado con el servidor correctamente.', {
                // optional options Object
            })
            // First sent packet after establish the connection with the server
            ws.send(JSON.stringify({action: RPC.CLIENT_JOIN, params: null}))
            callback();
        }.bind(this, callback);
        this.ws.onmessage = function(evt) {
            network.handle_message(evt)
        };
        this.ws.onclose = function(evt) {
            window['ws'] = null;
            network.reconnecting = true;
            vueapp.$store.commit("setLoading", true);
            vueapp.$toast.warning('No se ha podido conectar con el servidor, intentando de nuevo.', {
                // optional options Object
            })
            setTimeout(network.connect, network.reconnect_interval);
        };
        this.ws.onerror = function(evt) {
        };
    },
    register_rpc: function(rpcid, fn) {
        network.registered_rpcs[rpcid] = fn;
    },
    handle_action : function(rpc, params) {
        if(this.registered_rpcs[rpc])
            this.registered_rpcs[rpc](params);
    },
    handle_message: function(message) {
        try {
            //ws, rpc, params
            var msg = JSON.parse(message.data);
            network.handle_action(msg.action, msg.params);
        } catch(error) {
            console.error(error)
        }
    }
};

window['network'] = network;
network.connect(function(){
    console.log("[INFO]: Client connected!")
});

console.log("- ------------------------ -")
console.log("- Innovalearning semg 2021 -")
console.log("- ------------------------ -")