var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity5checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"demoscene1activity4","scene_id":"demoscene1","title":"Exploración física y complementaria en la consulta de seguimiento de enfermería de AP del paciente con ICC","intro_text":"Tras una breve entrevista, se procede a la exploración física.","question":"¿Qué tres acciones priorizaría la enfermera en esta consulta de seguimiento y tras la anamnesis realizada?","options":[
            {
                text: 'Realización de ECG',
                value: 0,
            },
            {
                text: 'Realización de test rápido de Antígenos para Sars-Cov2',
                value: 1,
            },
            {
                text: 'Control de peso',
                value: 2,
            },
            {
                text: 'Toma de temperatura',
                value: 3,
            },
            {
                text: 'Control de tensión arterial, frecuencia cardiaca y saturación de oxígeno',
                value: 4,
            },
            {
                text: 'Exploración de los pies',
                value: 5,
            },
            {
                text: 'Test de adherencia al tratamiento',
                value: 6,
            },
            {
                text: 'Vacunación de la gripe',
                value: 7,
            }
        ],"correct_answer":[
            2, 4, 6
        ],"clue_text":"Se trata de una visita de seguimiento de una paciente pluripatológica y polimedicada, con HTA, ICC. No refiere síntomas respiratorios salvo aumento de la disnea.","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }