<template>
    <div class="row h-100 w-100 m-0">

        <register-user-modal ref="register_user_modal"></register-user-modal>

        <!-- Edit user modal -->
        <b-modal v-model="show_edit_modal" id="modal-edit-users" size="lg" centered title="Editar contraseña" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputUserName">Nombre del usuario:</label>
                <input type="text" disabled v-model="edit_user.username" class="form-control" id="inputUserName" name="username" required placeholder="Introduzca el nombre de usuario..">
            </div>
            <div class="form-group mb-2">
                <label for="inputUserPassword">Contraseña:</label>
                <input type="password" v-model="edit_user.password" class="form-control" id="inputUserPassword" name="password" required placeholder="Introduzca la contraseña del usuario..">
                <small>El tamaño minimo de la contraseña ha de tener 8 caracteres.</small>
            </div>
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="primary"
                        class="float-right"
                        @click="update_user"
                    >
                        Actualizar contraseña
                    </b-button>
                    <b-button
                        variant="secondary"
                        class="float-right mr-2"
                        @click="show_edit_modal=false"
                    >
                        Cancelar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- Remove user modal -->
        <b-modal v-model="show_delete_user" id="modal-warning-link" centered title="¡Cuidado!" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputSessionName">¡Esta a punto de eliminar el usuario: <b v-if="current_delete_user">{{ current_delete_user.username }}</b>!</label> 
                <p>Si continua con la operación, los datos del usuario se perderán.</p>
                <p class="font-weight-bold">¿Desea continuar con la operación?</p>
            </div>            
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="nav-blue"
                        class="float-right"
                        @click="remove_user(current_delete_user.id);show_delete_user = false;"
                    >
                        Continuar
                    </b-button>
                    <b-button
                        variant="secondary"
                        class="float-right mr-2"
                        @click="show_delete_user=false"
                    >
                        Cancelar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- Remove user game session modal -->
        <b-modal v-model="show_delete_data_user" id="modal-warning-link" centered title="¡Cuidado!" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputSessionName">¡Esta a punto de eliminar los datos de la sesión de juego del usuario: <b v-if="current_delete_user">{{ current_delete_user.username }}</b>!</label> 
                <p>Si continua con la operación, los datos del usuario se perderán.</p>
                <p class="font-weight-bold">¿Desea continuar con la operación?</p>
            </div>            
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="nav-blue"
                        class="float-right"
                        @click="remove_user_session(current_delete_user.id);show_delete_data_user = false;"
                    >
                        Continuar
                    </b-button>
                    <b-button
                        variant="secondary"
                        class="float-right mr-2"
                        @click="show_delete_data_user=false"
                    >
                        Cancelar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <div class="col-12">
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel'})">Panel</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Usuarios</li>
                </ol>
                <div class="d-none">        
                    <font-awesome-icon id="trash-icon" :icon="['fa', 'trash']" />
                    <font-awesome-icon id="pencil-alt-icon" :icon="['fas', 'pencil-alt']" />
                    <font-awesome-icon id="link-icon" :icon="['fa', 'link']" />
                    <font-awesome-icon id="key-icon" :icon="['fas', 'key']" />
                </div>
            </nav>
            <div class="col-12 p-0">
                <div class="row bg-dark text-white pt-3 pb-3 rounded m-0">
                    <div class="col d-flex justify-content-start align-items-center">
                        <h5 class="mb-0"><b>Usuarios</b></h5>
                    </div>
                    <div class="col">
                        <b-button @click="register_user_modal" variant="primary" class="btn btn btn-sm float-right btn-nav-blue">Crear usuario</b-button>
                    </div>
                </div>
                <hr class="mt-2 mb-2">
                <table id="table_users" class="table table-hover table-striped border-bottom mb-3" style="width:100%">
                    <thead class="bg-dark text-white">
                        <tr>
                            <td>id</td>
                            <td>Nombre de usuario</td>
                            <td>Rol</td>
                            <td>Sesión</td>
                            <td>Conectado</td>
                            <td>Fecha de creación</td>
                            <td>Fecha de actualización</td>
                            <td>Acciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        <!--tr v-for="user in $store.state.panel_users" :key="user.id">
                            <td>{{ user.id }}</td>
                            <td>{{ user.username }}</td>
                            <td>
                                <div v-if="user.role === $USERS.PLAYER">
                                    Jugador
                                </div>
                                <div v-else-if="user.role === $USERS.ADMIN">
                                    Admin
                                </div>
                                <div v-else-if="user.role === $USERS.SUPER_ADMIN">
                                    Super admin
                                </div>
                                <div v-else>
                                    Desconocido
                                </div>
                            </td>
                            <td>{{ (user.session_id == false || user.session_id == null) ? 'Global' : 'ID de sesión:'+user.session_id }}</td>
                            <td>
                                <h5 v-if="user.is_connected == true" class="text-success rounded-circle text-center mb-0">⬤<span class="d-none">true</span></h5> 
                                <h5 v-if="user.is_connected == false" class="text-secondary rounded-circle text-center mb-0">⬤<span class="d-none">false</span></h5>
                            </td>
                            <td>
                                <a v-if="user.route" href="#">{{ user.route }}</a>
                                <span v-else>Desconocida</span>
                            </td>
                            <td>{{ moment(user.createdAt).format("DD/MM/YYYY  HH:mm:ss") }}</td>
                            <td>
                                <div class="row d-flex">
                                    <div class="p-0 mr-2 ml-2 d-flex justify-content-center align-items-center">
                                        <button class="btn btn-sm btn-warning text-white edit-session" @click="current_delete_user = user; show_delete_data_user=true;">Borrar estado</button>
                                    </div>
                                    <div class="p-0 mr-2 ml-2 d-flex justify-content-center align-items-center">
                                        <button class="btn btn-sm btn-danger text-white edit-session" @click="current_delete_user = user; show_delete_user=true;"><font-awesome-icon :icon="['fa', 'trash']" /></button>
                                    </div>
                                    <div class="p-0 mr-2 ml-2 d-flex justify-content-center align-items-center">
                                        <button class="btn btn-sm btn-success text-white edit-session" @click="edit_user = user; show_edit_modal=true;"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button>
                                    </div>
                                </div>
                            </td>
                        </tr-->
                    </tbody>
                </table>
                <div class="pagination mb-3">
                    <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
                    <span>Page {{ currentPage }} of {{ totalPages }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>                  
                    <input type="number" v-model="gotoPage">
                    <button @click="gotoPageClick">Ir a página</button>                   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Vue from 'vue';
    import utils from '../../utils'
    import RegisterUserModal from '../../components/panel/RegisterUserModal.vue';
    export default {
        name: 'Users',
        components: {
            'register-user-modal': RegisterUserModal
        },
        data : function(){
            return {
                show: false,
                // Show/hide delete user modal.
                show_delete_user: false,
                // Show/hide delete user game session modal.
                show_delete_data_user: false,
                // Show/hide edit user modal.
                show_edit_modal: false,
                current_pagination: null,
                actions_content: "",
                new_user: {
                    username: null,
                    session_id: null,
                    role: 0,
                    password: null,
                },
                edit_user: {
                    id: null,
                    username: "",
                    password: "",
                },
                datatable_users: null,
                current_delete_user: null,
                html_trash: '',
                html_pencil_alt:'',
                html_pencil_alt:'',
                html_key:'',
                currentPage: 1,
                itemsPerPage: 30,
                totalItems: 100,
                gotoPage: 0,
            };
        },
        computed: {
            currentPage() {
                return this.$store.state.panel_pagination.page;
            },
            totalPages() {
                return this.$store.state.panel_pagination.total_pages;
            },
        },
        mounted: function() {
            //ws.send(JSON.stringify({action: RPC.GET_USERS, params: null}));
            // Example of requesting page 2 with 10 users per page
            ws.send(JSON.stringify({action: RPC.GET_USERS, params: { page: 1, limit: 30 }}));

            this.html_trash = document.getElementById("trash-icon");
            this.html_pencil_alt = document.getElementById("pencil-alt-icon");
            this.html_link = document.getElementById("link-icon");
            this.html_key = document.getElementById("key-icon");

            window['remove_user_modal'] = this.remove_user_modal;
            window['edit_user_modal'] = this.edit_user_modal;

            // Create the datatable instance with the configuration for panel sessions.
            this.datatable_users = $('#table_users').DataTable({
                responsive: true,
                "dom": '<"toolbar">frtip',
                //"iDisplayLength": 20,
                "bLengthChange" : false,
                "bInfo" : false,
                "oLanguage": {
                    "sSearch": "Buscar:"
                },
                paging: false,
                "language": {
                    "paginate": {
                        "previous": "Anterior",
                        "next": "Siguiente"
                    }
                },
                "createdRow": function( row, data, dataIndex){
                    //if( data[2] == "Super admin" && vueapp.$store.state.user.role == window.USERS.ADMIN){ //Rol
                    //    $(row).addClass('locked-row');
                    //    this.datatable_users.row(dataIndex).edit(6, "-");
                    //}
                }
            });
            // Save in vuex the datatable instance for panel sessions
            vueapp.$store.commit("setPanelUsersDataTable", this.datatable_users);
            window['datatable'] =  this.datatable_users;
        },
        watch: {
            '$store.state.panel_users': function() {
                // Clear table content
                this.datatable_users.clear();

                // Fill table content
                for(var i = 0; i < vueapp.$store.state.panel_users.length; i++){
                    var user = vueapp.$store.state.panel_users[i];
                    var role = '';
                    if(user.role == window.USERS.PLAYER){
                        role = "Jugador";
                    }else if(user.role == window.USERS.ADMIN){
                        role = "Admin";
                    }else if(user.role == window.USERS.SUPER_ADMIN){
                        role = "Super admin";
                    }else{
                        role = "Desconocido";
                    }
                    var session_id = (user.session_id == false || user.session_id == null) ? 'Global' : 'ID de sesión:'+user.session_id;
                    var is_connected = (user.is_connected==0) ? 
                    '<h5 class="text-secondary rounded-circle text-center mb-0">⬤<span class="d-none">false</span></h5>':
                    '<h5 class="text-success rounded-circle text-center mb-0">⬤<span class="d-none">true</span></h5>';
                    var createdAt = (user.createdAt) ? moment(user.createdAt).format("DD/MM/YYYY  HH:mm:ss"): '-';
                    var updatedAt = (user.updatedAt) ? moment(user.updatedAt).format("DD/MM/YYYY  HH:mm:ss"): '-';
                    var hide_superadmin = (user.role == window.USERS.SUPER_ADMIN && vueapp.$store.state.user.role == window.USERS.ADMIN);
                    var actions = (hide_superadmin) ? ``:` 
                        <div class="row">        
                            <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                <button class="btn btn-sm btn-danger text-white delete-session" onclick="remove_user_modal(`+user.id+`);">`+this.html_trash.outerHTML+`</button>
                            </div>
                            <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                <button class="btn btn-sm btn-primary text-white share-session" onclick="edit_user_modal(`+user.id+`)">`+this.html_key.outerHTML+`</button>
                            </div>
                        </div>
                    `;

                    // Add new row
                    var current_row = this.datatable_users.row.add([user.id, user.username, role, session_id, is_connected, createdAt, updatedAt, actions]).draw();
              
                    if(user.role == window.USERS.SUPER_ADMIN && vueapp.$store.state.user.role == window.USERS.ADMIN)
                        current_row.node().classList.add('locked-row');
                }
                //// Save the current page for latter navigate to the same page.
                //this.current_pagination = this.datatable_users.page.info();
                // Navigate to the page we where in..
                if(this.current_pagination != null){                    
                    console.log("Navigating to the page: "+this.current_pagination)
                    this.datatable_users.page(this.current_pagination).draw(false);
                }
                this.datatable_users.page.len(this.$store.state.panel_pagination.total_pages).draw();
            }
        },
        methods: {
            nextPage() {
                console.log("nextPage")
                if (this.currentPage < this.totalPages) {
                    this.currentPage = this.currentPage+1;
                    ws.send(JSON.stringify({action: RPC.GET_USERS, params: { page: this.currentPage, limit: 30 }}));
                }
            },
            prevPage() {
                console.log("prevPage")
                if (this.currentPage > 1) {
                    this.currentPage = this.currentPage-1;
                    ws.send(JSON.stringify({action: RPC.GET_USERS, params: { page: this.currentPage, limit: 30 }}));
                }
            },
            gotoPageClick() {
                this.currentPage = this.gotoPage;
                ws.send(JSON.stringify({action: RPC.GET_USERS, params: { page: this.gotoPage, limit: 30 }}));
            },
            register_user_modal : function(){
                this.$refs.register_user_modal.show_modal();
            },
            remove_user_modal : function(user_id){
                this.current_delete_user = this.find_user_by_id(user_id); 
                this.show_delete_user=true;
            },
            edit_user_modal : function(user_id){
                this.edit_user = JSON.parse(JSON.stringify(this.find_user_by_id(user_id))); 
                this.show_edit_modal=true;
            },
            find_user_by_id : function(user_id){
                var found = false;
                for(var i = 0; i < vueapp.$store.state.panel_users.length && found == false; i++){
                    if(vueapp.$store.state.panel_users[i].id == user_id){
                        found = true;
                        return vueapp.$store.state.panel_users[i];
                    }
                }
                return null;
            },
            remove_user : function(user_id){
                ws.send(JSON.stringify({action:RPC.REMOVE_USER, params: user_id}));
            },
            update_user : function(){
                if(utils.isValidPasswordLength(this.edit_user.password)){
                    // Hide modal
                    this.show_edit_modal=false;
                    // Send packet to the server for update user password
                     ws.send(JSON.stringify({action:RPC.UPDATE_USER, params: this.edit_user}));
                    // Reset form values
                    this.edit_user.id = null;
                    this.edit_user.username = "";
                    this.edit_user.password = "";
                }else{
                    vueapp.$toast.error("¡Comprueba el tamaño de las contraseña!");
                }
            },
            remove_user_session : function(user_id){
                ws.send(JSON.stringify({action:RPC.REMOVE_USER_SESSION, params: user_id}));
            },
            view_user : function(session_id){
                //router.push({name: 'panel-session-id', params: {id:session_id}});
            },
            show_modal : function(){
                this.show = true;
            }
        }
    }
</script>

<style scoped>
    #table_users_filter{
        width: 50%;
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    button {
      padding: 5px 10px;
      margin: 0 5px;
      cursor: pointer;
    }
</style>
