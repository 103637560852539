<template>
    <div class="activity5 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="col-lg-6 text-rockwell fb-color fb-text-size d-flex justify-content-center align-items-center text-justify">
                <p class="ml-5">Los pacientes con ICC suelen presentar numerosas comorbilidades asociadas, que van a influir en gran medida en la evolución de la enfermedad: aumento de hospitalizaciones y mortalidad, empeoramiento de la calidad de vida y los síntomas de IC, interacción y efectos secundarios de fármacos, diagnóstico diferencial de IC más complejo, etc. Para conseguir un abordaje integral de los pacientes con IC es importante conocer estas patologías y manejarlas correctamente.</p>
            </div>
            <div class="col-lg-6 d-flex justify-content-center align-items-center">
                <!--img id="esc2_act5" src="@/assets/demo/activities/esc2_act5.png" alt="esc2_act5" rel="preload"-->
                <table class="activity-table activity-table-striped m-5">
                    <thead>
                        <tr class="text-rockwell-bold">
                            <td colspan="2">COMORBILIDADES EN LA INSUFICIENCIA CARDÍACA</td>
                        </tr>
                    </thead>
                    <tbody class="text-rockwell-bold">
                        <tr>
                            <td class="w-50 align-top">
                                <ul>
                                    <li>Angina y enfermedad arterial coronaria</li>
                                    <li>Caquexia y sarcopenia</li>
                                    <li>Cáncer</li>
                                    <li>Depresión</li>
                                    <li>Ictus</li>
                                    <li>Disfunción autonómica</li>
                                    <li>Diabetes mellitus</li>
                                    <li>Disfunción eréctil</li>
                                    <li>Gota y artritis</li>
                                    <li>Hipopotasemia e Hiperpotasemia</li>
                                    <li>Hiperlipemia</li>
                                    <li>Hipertensión</li>
                                </ul>
                            </td>
                            <td class="w-50 align-top">
                                <ul>
                                    <li>Déficit de hierro y anemia</li>
                                    <li>Patología renal: enfermedad renal crónica, daño renal agudo, síndrome cardiorrenal y obstrucción prostática</li>
                                    <li>Enfermedad pulmonar: asma y enfermedad pulmonar obstructiva crónica</li>
                                    <li>Obesidad</li>
                                    <li>Trastornos del sueño y trastornos respiratorios durante el sueño</li>
                                    <li>Valvulopatías</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:vueapp.$store.state.back_url});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_09",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene2");
        vueapp.$store.commit("setLastURL", "demoscene2activity5");
        setActivityVisited('demoscene2', 'demoscene2activity5');
        var act = getActivityStatus("demoscene2", 'demoscene2activity5');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('demoscene2', 'demoscene2activity5', true);
        
        updateUserSession();
    }
}
</script>

<style scoped>
    #esc2_act5{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
</style>