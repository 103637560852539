<template>
    <div class="activity1 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-center align-items-center">
            <!--img id="esc3_act1" src="@/assets/demo/activities/esc3_act1.png" alt="esc3_act1" rel="preload"-->
            <div class="preview-img-list position-relative w-100 h-100 p-5 d-flex justify-content-center align-items-center">
                <img class="preview-img-item" v-for="(item, index) in items"
                    :src="item.src" @click="$photoswipe.open(index, items)">
            </div>
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn rounded mb-4 ml-3 text-oswald btn-nav-white mr-5" onclick="router.push({name:vueapp.$store.state.back_url});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_07",
    data () {
        return {
            items: [{
                src: require('@/assets/demo/activities/esc3_act1.png'),
                w: 1183*2,
                h: 819*2
            }]
        }
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene3");
        vueapp.$store.commit("setLastURL", "demoscene3activity1");
        setActivityVisited('demoscene3', 'demoscene3activity1');
        var act = getActivityStatus("demoscene3", 'demoscene3activity1');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.demo.score + 1);
        setActivityCompleted('demoscene3', 'demoscene3activity1', true);

        updateUserSession();
    }
}
</script>

<style scoped>

    .preview-img-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .preview-img-item {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    #esc3_act1{
        width: auto;
        height: auto;
    }
</style>