<template>
    <div class="w-100 h-100 animate__animated animate__fadeIn">
        <div class="row w-100 h-100 m-0" v-if="(activity_response==null || activity_response ==false)">
            <div :class="left_size">
                <div class="activity_title question-title text-rockwell">{{ title }}</div>
                <div class="intro_text ml-5 mt-4 text-oswald" v-html="intro_text"></div>

                <div v-if="!clue && clue_text" class="d-flex justify-content-center align-items-center flex-column mt-5 ml-5 text-rockwell">
                    <button
                        :disabled="(current_date.diff(last_clue_date, 'seconds') < clue_time)" 
                        class="rounded pt-2 pb-2 pr-5 pl-5 clues clue-button" 
                        v-on:click="getClue">
                        ¡PISTA!
                    </button>
                </div><div class="row text-rockwell" v-if="clue && clue_text">
                    <div class="rounded p-2 w-100 clues clue-show justify-content-center align-items-center mt-5 ml-5 text-rockwell">
                        ¡PISTA!
                        <div class="text-oswald" v-html="clue_text"></div>
                    </div>
                </div>
                <p class="ml-5 intro_text_order order-warning text-center text-information-order clue-msg clue-msg-info"  v-if="!clue && (current_date.diff(last_clue_date, 'seconds') < clue_time) && clue_text != null">Hace muy poco que pediste una pista, inténtalo más tarde</p>
            </div>
            <div :class="right_size">
                <h3 class="text-rockwell question-title">{{ question }}</h3>
                <div class="crossword" id="crosswordactivity" ref="crosswordactivity"></div>
                <div class="row m-0 d-flex justify-content-center align-items-center">
                    <!--button v-if="activity_response == null" class="btn btn-action mt-3 mb-3 rounded check-btn" v-on:click="check_activity">Comprobar</button-->
                    <div v-if="activity_response==false" class="incorrectmsg p-2 mt-3 result text-center rounded">
                        {{ incorrect_msg }}
                    </div>
                    <div v-if="is_correct==true" class="correctmsg p-2 mt-3 result text-center rounded">La actividad es correcta</div>
                </div>
            </div>
        </div>
        <div v-if="activity_response==true" class="w-100 h-100 d-flex justify-content-center flex-column">
            <div class="w-100 h-100" v-html="feedback"></div>
            <div class="w-100 d-flex justify-content-center align-items-center scene-finisher-btn">
                <button v-if="scenario_finisher == true" @click="$router.push({name:'demomap'})" class="btn btn-action mt-3 mb-3 rounded check-btn">Mapa</button>
            </div>
        </div>
    </div>
</template>

<script>
import ActivityType from './ActivityType'

import dotenv from 'dotenv'
dotenv.config()
export default {
    extends: ActivityType,
    props: {
        act_id: {
            type: String,
            required: true,
            default: ''
        },
        scene_id: {
            type: String,
            required: true,
            default: ''
        },
        intro_text: '',
        words: {
            type: Array,
            default: []
        },
        cols: {
            type: Number,
            required: true,
            default: 0
        },
        rows: {
            type: Number,
            required: true,
            default: 0
        },
        incorrect_msg: '',
        left_size: {
            type: String,
            default: 'col-6'
        },
        right_size: {
            type: String,
            default: 'col-6'
        },
        scenario_finisher: {
            type: Boolean,
            default: false,
        },
        next_scenario: {
            type: String,
            default: ''
        },
        final_activity: {
            type:Boolean,
            default: false,
            required: false,
        },
    },
    data: function() {
        return {
            activity_response: null,
            grid_content: [],
        }
    },
    beforeDestroy : function(){
        /*console.log("crossword grid", this.grid_content);
        this.grid_content = $(".crossword-grid").html();
        console.log( this.grid_content)*/
        setActivityStatus(this.scene_id, this.act_id, this.$data);
        updateUserSession();
    },
    created() {
        document.addEventListener('beforeunload', this.handler)
    },
    //updated : function(){
    //    updateUserSession();
    //},
    mounted: function() {        
        var act = null;
        act = getActivityStatus(this.scene_id, this.act_id);
        console.log(act);
        if(act != null){
            if(act.data != null){
                this.activity_response = act.data.activity_response;
                this.can_clue = act.data.can_clue;
                this.clue = act.data.clue;
                this.current_date = act.data.current_date;
                this.is_correct = act.data.is_correct;
                this.last_clue_date = act.data.last_clue_date;
                this.render = act.data.render;
                this.grid_content = act.data.grid_content;
            }
        }

        var context = this;

        // Set global variables
        var gridSize = [30,6];      // number of squares wide, number of squares tall
        var direction = 'across';   // set initial direction to 'across'
        var markCorrect = true;     // indicates ability for answers to be marked correct. will be set to false if "show answers" is clicked
        var successShown = false;   // indicates whether the success modal has been shown
        var $clueTooltip = $('<div class="clue-tooltip invisible"><div class="clue-tooltip-arrow"></div><div class="clue-tooltip-text"></div></div>').appendTo('.crossword');

        // set up the base grid
        var $crosswordPuzzle = $('<div class="crossword-puzzle"></div>');
        

        var $table = $('<table class="crossword-grid"></table>');
        
       
            for ( var i=0; i<gridSize[1]; i++) {
                var $row = $('<tr class="grid-row"></tr>');
                for (var j=0;j<gridSize[0];j++) {
                    $square = $('<td class="grid-square"></td>');
                    $square.appendTo($row);
                }
                $row.appendTo($table);
            }
        
        
        $table.appendTo($crosswordPuzzle);
        $crosswordPuzzle.appendTo('.crossword');
        // Add the fields to the grid
        for (var i=0;i<this.words.length;i++) {
            var row = this.words[i].row;
            var column = this.words[i].column;
            var counter = 0;
            for (var j=0;j<this.words[i].answer.length;j++) {
                var $square = $('.grid-row').eq(row-1).find('.grid-square').eq(column-1);
                var title = this.words[i].clue+', letter '+(j+1)+' of '+this.words[i].answer.length;
                var id = (this.words[i].direction == 'across' ? 'a' : 'd') + '-' + this.words[i].number + '-' + (j+1);
                if (j==0 && $square.find('.word-label').length == 0) {
                    $('<span class="word-label">'+this.words[i].number+'</span>').appendTo($square);
                }
                if ($square.find('input').length == 0) {
                    var $input = $('<input type="text" class="letter" title="'+title+'" value="" id="'+id+'" maxlength="1" data-square="'+'square_'+id+'"/>');
                        if (this.words[i].direction == 'across') {
                            $input.attr('data-across',this.words[i].number);
                            $input.attr('data-across-clue',this.words[i].clue);
                        } else {
                            $input.attr('data-down',this.words[i].number);
                            $input.attr('data-down-clue',this.words[i].clue);
                        }
                        $input.data('letter',this.words[i].answer[j]);
                        $input.appendTo($square);
                    $square.addClass('active');
                } else {
                    var $input = $square.find('input');
                        $input.attr('title',$input.attr('title')+'; '+title);
                        //$input.attr('id',$input.attr('id')+'+'+id);
                        $input.attr('id',"square_"+counter+'_'+this.words[i].row);
                        if (this.words[i].direction == 'across') {
                            $input.attr('data-across',this.words[i].number);
                            $input.attr('data-across-clue',this.words[i].clue);
                        } else {
                            $input.attr('data-down',this.words[i].number);
                            $input.attr('data-down-clue',this.words[i].clue);
                        }
                }
                if (this.words[i].direction == 'down') {
                    row++;
                } else {
                    column++;
                }
                counter++;
            }
        }

        // Add the success modal
        var $modal = $('<div class="modal fade" id="success-modal" tabindex="-1" role="dialog"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button><h4 class="modal-title">Congratulations!</h4></div><div class="modal-body"><p>You have finished the puzzle.</p></div></div></div></div>');
        $modal.appendTo('#crosswordactivity');

        // When a square is focused, highlight the other squares in that word and the clue, and show the tooltip
        $('input.letter').on('focus',function(){
            var $current = $(this);
            $current.select();
            $current[0].setSelectionRange(0, 10);
            getDirection($current);
            $('[data-'+direction+'='+$current.data(direction)+']').parent('.grid-square').addClass('current-word');
            $('.crossword-clues li').removeClass('active');
            $('.crossword-clues li[data-direction='+direction+'][data-clue='+$(this).data(direction)+']').addClass('active');
        })

        // When a square is blurred, remove highlight from squares and clue
        $('input.letter').on('blur',function(){
            $('.grid-square').removeClass('current-word');
            $('.crossword-clues li').removeClass('active');
            $clueTooltip.addClass('invisible');
        })

        // handle directional and letter keys in letter inputs
        $('input.letter').on('keyup',function(e){
            var $current = $(this);
            //e.target.value=""
            if (e.which == 38) {      // up arrow moves to square above if it exists
                direction = 'down';
                var itm = getPrevLetter($current);
                if (itm) {
                    getPrevLetter($current).focus();
                }
            } else if (e.which == 40) {      // down arrow moves to square below if it exists
                direction = 'down';
                var itm = getNextLetter($current);
                if (itm) {
                    getNextLetter($current).focus();
                }
            } else if (e.which == 37) {      // left arrow moves to square to the left if it exists
                direction = 'across';
                var itm = getPrevLetter($current);
                if (itm) {
                    getPrevLetter($current).focus();
                }
            } else if (e.which == 39) {      // right arrow moves to square to the right if it exists
                direction = 'across';
                var itm = getNextLetter($current);
                if (itm) {
                    getNextLetter($current).focus();
                }
            } else {
                e.preventDefault();
            }
            if (markCorrect) {
                checkWord($current, context);
            };
            //context.grid_content = $('.crossword-grid').html();
        })

        // Tab and Shift/Tab move to next and previous words
        $('input.letter').on('keydown',function(e){
            var $current = $(this);
            var found = false;
            for(var i = 0; i < context.grid_content.length && found == false; i++){
                if(context.grid_content[i].id == e.target.id){
                    if(e.which != 8 && e.keyCode >= 65 && e.keyCode <= 90){
                        context.grid_content[i].value = e.key.toUpperCase();
                    }else{
                        context.grid_content.splice(i, 1);
                    }
                    found = true;
                }
            }
            if(!found && e.which != 8 && e.keyCode >= 65 && e.keyCode <= 90)
                context.grid_content.push({id:e.target.id, value: e.key.toUpperCase()})
            //console.log(e.key)
            if (e.which == 9) {       // tab
                e.preventDefault();
                if (e.shiftKey) {       // shift/tab
                    getPrevWord($current).focus();
                }
            } else if (e.which == 8) {        // backspace
                e.preventDefault();
                /*var itmid = $current[0].id;
                console.log("removing "+itmid)
                updateGridContent(context, itmid, null, true);*/
                if ($(this).val().length > 0) {
                    //var itmval = e.target.value;
                    //updateGridContent(context, itmid, itmval, true);
                    $(this).val('');
                } else {
                    if (getPrevLetter($current)) {
                        getPrevLetter($current).focus().val('');
                         var found = false;
                        for(var i = 0; i < context.grid_content.length && found == false; i++){
                            if(context.grid_content[i].id == getPrevLetter($current)[0].id){
                                if(e.which != 8 && e.keyCode >= 65 && e.keyCode <= 90){
                                    context.grid_content[i].value = e.key.toUpperCase();
                                }else{
                                    context.grid_content.splice(i, 1);
                                }
                                found = true;
                            }
                        }
                    }
                }
            } else if ((e.which>=48 && e.which<=90) || (e.which>=96 && e.which<=111) || (e.which>=186 && e.which<=192) || (e.which>=219 && e.which<=222)) {    // typeable characters move to the next square in the word if it exists
                e.preventDefault();
                $current.val(String.fromCharCode(e.which));
                if (getNextLetter($current)) {
                    getNextLetter($current).focus();
                }
            }
            if (markCorrect) {
                checkWord($current, context);
            };
        })

        // Check if all letters in selected word are correct
        function checkWord($current, context) {
            var correct;
            var currentWord;
            if ( $current.is('[data-across]') ) {
                correct = 0;
                currentWord = $current.data('across');
                $('[data-across='+currentWord+']').each(function(){
                    if ($(this).val().toLowerCase() == $(this).data('letter').toLowerCase()) {
                        correct += 1;
                    }
                })
                if (correct == $('[data-across='+currentWord+']').length ) {
                    $('[data-across='+currentWord+']').parent('.grid-square').addClass('correct-across');
                    $('.crossword-clues li[data-direction=across][data-clue='+currentWord+']').addClass('correct');
                } else {
                    $('[data-across='+currentWord+']').parent('.grid-square').removeClass('correct-across');
                    $('.crossword-clues li[data-direction=across][data-clue='+currentWord+']').removeClass('correct');
                }
            }
            if ( $current.is('[data-down]') ) {
                correct = 0;
                currentWord = $current.data('down');
                $('[data-down='+currentWord+']').each(function(){
                    if ($(this).val().toLowerCase() == $(this).data('letter').toLowerCase()) {
                        correct += 1;
                    }
                })
                if (correct == $('[data-down='+currentWord+']').length ) {
                    $('[data-down='+currentWord+']').parent('.grid-square').addClass('correct-down');
                    $('.crossword-clues li[data-direction=down][data-clue='+currentWord+']').addClass('correct');
                } else {
                    $('[data-down='+currentWord+']').parent('.grid-square').removeClass('correct-down');
                    $('.crossword-clues li[data-direction=down][data-clue='+currentWord+']').removeClass('correct');
                }
            }
            if ($('.grid-square.active:not([class*=correct])').length == 0 && !successShown) {
                //$('#success-modal').show();
                //alert("Correct")
                context.is_correct = true;
                vueapp.$store.state.correct.play();
                setTimeout(context.activity_correct, 3000);
                successShown = true;
            }
        }

        // Return the input of the first letter of the previous word in the clues list
        function getPrevWord($current) {
            var length = $('.crossword-clues li').length;
            var index = $('.crossword-clues li').index($('.crossword-clues li.active'));
            var prevWord;
            if (index > 0) {
                $prevWord = $('.crossword-clues li').eq(index-1);
            } else {
                $prevWord = $('.crossword-clues li').eq(length-1);
            }
            direction = $prevWord.data('direction');
            return $('[data-'+$prevWord.data('direction')+'='+$prevWord.data('clue')+']').eq(0);
        }

        // If there is a letter square before or after the current letter in the current direction, keep global direction the same, otherwise switch global direction
        function getDirection($current) {
            if (getPrevLetter($current) || getNextLetter($current)) {
                direction = direction;
            } else {
                direction = (direction == 'across') ? 'down' : 'across';
            }
        }

        // Return the input of the previous letter in the current word if it exists, otherwise return false
        function getPrevLetter($current) {
            var index = $('[data-'+direction+'='+$current.data(direction)+']').index($current);
            if (index > 0) {
            return $('[data-'+direction+'='+$current.data(direction)+']').eq(index-1);
            } else {
            return false;
            }
        }

        // Return the input of the next letter in the current word if it exists, otherwise return false
        function getNextLetter($current) {
            var length = $('[data-'+direction+'='+$current.data(direction)+']').length;
            var index = $('[data-'+direction+'='+$current.data(direction)+']').index($current);
            if (index < length-1) {
            return $('[data-'+direction+'='+$current.data(direction)+']').eq(index+1);
            } else {
            return false;
            }
        }

        $('#square_0_1')[0].offsetParent.classList.add("solved-word");
        $('#square_1_1')[0].offsetParent.classList.add("solved-word");
        $('#square_2_1')[0].offsetParent.classList.add("solved-word");
        $('#square_3_1')[0].offsetParent.classList.add("solved-word");
        $('#square_4_1')[0].offsetParent.classList.add("solved-word");
        $('#square_5_1')[0].offsetParent.classList.add("solved-word");

        
        if(context.grid_content.length>0){
            for(var i = 0; i < context.grid_content.length;i++){
                $('#'+context.grid_content[i].id).val(context.grid_content[i].value);
                var e = document.createEvent('HTMLEvents');
                e.initEvent("keyup",false,true);
                $('#'+context.grid_content[i].id)[0].dispatchEvent(e);
            }
        }
    },
    methods: {
        handler: function handler(event) {
            //this.grid_content = $(".crossword-grid").html();
            setActivityStatus(this.scene_id, this.act_id, this.$data);
            updateUserSession();
        },
        activity_correct: function() {
            this.activity_response = true;
            this.is_correct = true;
            setActivityStatus(this.scene_id, this.act_id, this.$data);
            var act = getActivityStatus(this.scene_id, this.act_id);
            if(act.completed == false)
                vueapp.$store.commit("setScore", ((vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.score : vueapp.$store.state.score) + 1);
            setActivityCompleted(this.scene_id, this.act_id, true);
            if(this.scenario_finisher == true){
                setSceneLocked(this.next_scenario, false);
                setSceneCompleted((this.next_scenario-1), true);
            }
            updateUserSession();
        }
    }
}
</script>


<style>
    .crossword {
        margin: 1em auto;
        position: relative;
        font-family: sans-serif;
    }
    .crossword-clues {
        font-size: 14px;
    }
    .crossword-buttons {
        clear: both;
        text-align: center;
        padding-top: 1em;
    }
    .crossword-buttons button {
        margin: 0 0.5em;
        min-width: 200px;
    }
    table.crossword-grid {
        width: 100%;
        max-width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
    }
    .grid-row {
        clear: left;
    }
    .grid-square {
        position: relative;
        background-clip: padding-box;
    }
    .grid-square:after {
        content: '';
        display: block;
        margin-top: 100%;
    }
    .grid-square.active {
        border: 2px solid black;
    }
    .grid-square.current-word {
        background-color:#e6eef1;
    }
    .grid-square.solved-word {
        background-color: #fbe6d9 !important;
    }
    .grid-square.correct-across,
    .grid-square.correct-down {
        background-color: #dff0d8;
    }
    .grid-square.correct-across.current-word,
    .grid-square.correct-down.current-word {
        background-color:#e6eef1;
    }
    .grid-square .word-label {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        line-height: 1;
        font-size: calc(10px + .25vw);
        color: rgba(0, 0, 0, 0.5);
        pointer-events: none;
    }
    .grid-square input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 1.2em;
        font-size: calc(10px + .5vw);
        font-weight: 600;
        border: 0;
        padding: 0;
        text-transform: uppercase;
        border-radius: 0;
        background: transparent;
    }
    .crossword-clues li {
        position: relative;
    }
    .crossword-clues li label {
        display: block;
        font-weight: normal;
        cursor: pointer;
    }
    .crossword-clues li.active {
        background-color:#e6eef1;
    }
    .crossword-clues li.correct {
        background: transparent;
        color: green;
        text-decoration: line-through;
    }
    .crossword-clues li.correct.active {
        background-color:#e6eef1;
    }
    .crossword-clues li.correct label:before {
        content: "\f00c";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        top: 0.1em;
    }
    .crossword-clues a.hint {
        display: none;
    }
    .final{
        background-color: blue;
    }
</style>