var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity1qa w-100 h-100"},[_c('QandA',{attrs:{"act_id":"demoscene5activity1","scene_id":"demoscene5","title":"Consulta de seguimiento tras tratamiento con hierro intravenoso","intro_text":"En primer lugar se procede a la valoración clínica de la paciente con la anamnesis.","imgsrc":_vm.imgsrc,"left_size":"col-md-5","right_size":"col-md-7","questions":[
            {
                id: 0,
                question: 'Buenos días Juana, ¿qué tal se encuentra?',
                answer: 'Hola doctor, desde que estuve en el lugar donde se proporciona a la población una asistencia médico sanitaria completa, tanto curativa como preventiva (Fila 6), me encuentro muy bien, mucho más animada.'
            },
            {
                id: 1,
                question: '¿Se sigue fatigando cuando camina?',
                answer: 'No, ya no me fatigo cuando me camino, sólo si voy muy rápido o subo cuestas.'
            },
            {
                id: 2,
                question: '¿Se le siguen inflamando los tobillos?',
                answer: 'Tampoco, desde hace unos días ya no se me hinchan.'
            },
            {
                id: 3,
                question: '¿Está tomando toda su medicación?',
                answer: 'Si doctor, utilizo el pastillero como usted me recomendó y no se me olvida nunca.'
            }
        ],"feedback":_vm.feedback_content}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }