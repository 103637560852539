<template>
    <div class="w-100 h-100">
        <div id="top-bar" class="w-100 text-center">
            <h5 class="position-absolute text-center w-100 bg-warning pt-2 pb-2 top-messagebar d-flex justify-content-center align-items-center" v-if="$store.state.session && $store.state.game_state == $STATUS.IDLE">¡Espere a que comience el juego!
            <div class="ml-3 lds-dual-ring" id="loading-lobby"></div></h5>
            <h5 class="position-absolute text-center w-100 bg-warning pt-2 pb-2 top-messagebar d-flex justify-content-center align-items-center" v-if="$store.state.game_state == null || $store.state.game_state == $STATUS.STOPPED">El juego se ha detenido, espere mientras el juego se inicia!
            <div class="ml-3 lds-dual-ring" id="loading-lobby"></div></h5>
        </div>
        <!--div class="row w-100 h-100 d-flex justify-content-center align-items-center flex-column m-0 p-0">
            <div class="lds-dual-ring"></div>
            <div v-if="$store.state.session">
                <h3 class="mt-3" v-if="$store.state.game_state == $STATUS.IDLE">¡Espere a que comience el juego!</h3>
            </div>
            <h3 class="mt-3" v-if="$store.state.game_state == null">El juego se ha detenido, espere mientras el juego se inicia!</h3>
            <div v-if="$store.state.session.id">
                <intro :is="intro"></intro>
            </div>
            <div v-else class="text-center">
                <h3 class="mt-3 mb-3">No se ha podido encontrar la sesión de juego. </h3>
                <button class="btn btn-nav-blue text-decoration-none" @click="logout">SALIR</button>
            </div>
        </div-->

        <div class="row h-100 w-100 m-0 p-3">
            <div class="col-sm-8 col-md-6 col-lg-5 col-xl-3 d-flex justify-content-center align-items-start flex-column">
                <p v-if="$store.state.session.id" id="intro-text" class="p-3">
                    <intro :is="intro"></intro>
                </p>
                <div v-else class="text-center">  
                    <h3 class="mt-3 mb-3">No se ha podido encontrar la sesión de juego. </h3>
                    <button class="btn btn-nav-blue text-decoration-none" @click="logout">SALIR</button>
                </div>
            </div>
            <div id="start" class="col-sm-4 col-md-6 col-lg-7 col-xl-9 p-0 m-0" :style="cssProps"></div>
            <img id="personaje_1" src="@/assets/personaje_1.png" alt="personaje_1">
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Lobby',  
        data(){
            return {
                intro: null, 
                cssProps: {
                    backgroundImage: `url(${require('@/assets/imagen_fondo.png')})`
                }
            };
        },   
        mounted: function(){
            if(this.loader){
                this.loader()
                    .then(() => {
                        this.intro = () => this.loader()
                    })
                    .catch(() => {
                        //this.intro = () => import('../Intro')
                    })
            }
        },
        computed: {
            loader() {
                if(vueapp.$store.state.session){
                    console.log('./caso'+vueapp.$store.state.session.case_id+'/Intro')
                    return () => import('./caso'+vueapp.$store.state.session.case_id+'/Intro');
                }
                return null;
            }
        },
        methods : {
            logout : function(){
                destroy_user();
                router.push({name:'home'});
            }
        }
    }
</script>

<style scoped>

    #start{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    #personaje_1{
        position: absolute;
        right: 30%;
        bottom: -60px;
        width: 30%;
    }

    .lds-dual-ring{
        width: 20px !important;
        height: 20px !important;
    }

    .lds-dual-ring:after{
        width: 20px !important;
        height: 20px !important;
        margin: 0px  !important;

        border-top-color: rgb(0, 0, 0);
        border-top-style: solid;
        border-top-width: 3px;
        border-right-color: transparent;
        border-right-style: solid;
        border-right-width: 3px;
        border-bottom-color: rgb(0, 0, 0);
        border-bottom-style: solid;
        border-bottom-width: 3px;
        border-left-color: transparent;
        border-left-style: solid;
        border-left-width: 3px;
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
    }

    #top-bar{
        position: relative;
        top: 0px;
        z-index: 999;
    }

    .top-messagebar{
        top: 0px;
    }
</style>