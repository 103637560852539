<template>
    <div class="row h-100 w-100 m-0">
        {{  $store.state.panel_user}}
    </div>
</template>

<script>
    export default {
        name: 'User',
        data : function(){
            return {
            };
        },
        mounted: function() {
            ws.send(JSON.stringify({action: RPC.GET_USER, params: this.$route.params.id}));
        },
        methods: {

        }
    }
</script>

<style scoped>
    
</style>
