<template>
    <div class="activity5 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-center align-items-center">
            <table class="activity-table activity-table-striped m-5">
                <thead>
                    <tr class="text-rockwell-bold">
                        <td colspan="2">CUESTIONARIO ADHERENCIA MORISKY-GREEN</td>
                    </tr>
                </thead>
                <tbody class="text-rockwell-bold">
                    <tr>
                        <td>
                            1.¿Olvida alguna vez tomar los medicamentos para tratar su enfermedad?
                        </td>
                        <td class="text-center">
                            <div class="d-flex">
                                <span class="mr-3 ml-3">
                                    Sí
                                </span>
                                <span class="ml-3 mr-3 text-table-orange">
                                    No
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2.¿Toma los medicamentos a las horas indicadas?
                        </td>
                        <td class="text-center">
                            <div class="d-flex">
                                <span class="mr-3 ml-3 text-table-orange">
                                    Sí
                                </span>
                                <span class="ml-3 mr-3">
                                    No
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            3.Cuando se encuentra bien, ¿deja de tomar la medicación?
                        </td>
                        <td class="text-center">
                            <div class="d-flex">
                                <span class="mr-3 ml-3">
                                    Sí
                                </span>
                                <span class="ml-3 mr-3 text-table-orange">
                                    No
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            4.Si alguna vez le sienta mal, ¿deja usted de tomarla?
                        </td>
                        <td class="text-center">
                            <div class="d-flex">
                                <span class="mr-3 ml-3">
                                    Sí
                                </span>
                                <span class="ml-3 mr-3 text-table-orange">
                                    No
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--img id="esc_1_act_5" src="@/assets/demo/activities/esc1_act5.png" alt="esc_1_act_5" rel="preload"-->
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn rounded mb-4 ml-3 text-oswald btn-nav-white mr-5" onclick="router.push({name:vueapp.$store.state.back_url});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_02",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene1");
        vueapp.$store.commit("setLastURL", "demoscene1activity5");
        setActivityVisited('demoscene1', 'demoscene1activity5');
        var act = getActivityStatus("demoscene1", 'demoscene1activity5');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('demoscene1', 'demoscene1activity5', true);
        
        updateUserSession();
    }
}
</script>

<style scoped>
    #esc_1_act_5{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
</style>