<template>
    <SingleTextEntry 
        act_id="demoscene5activity4" 
        scene_id="demoscene5"
        intro_text='El abordaje de los pacientes con IC debe de ser multidisciplinar e integral, con una buena coordinación entre los profesionales que intervienen en su seguimiento.'
        title='Mensaje clave'
        question="En ocasiones los pacientes con IC pueden presentar deterioro de su estado funcional. ¿Qué debe sospechar el médico de familia para detectar el origen?"
        :feedback="feedback_content"
        incorrect_msg="La respuesta es incorrecta"
        :option="[
            {
                id:0,
                solution: 'DÉFICIT DE HIERRO',
                value: '',
            }
        ]"
        clue_text="La frase consta de 3 palabras que ya debes de conocer."
        :final_activity="true">
    </SingleTextEntry>
</template>

<script>
import SingleTextEntry from '../../../../components/activitytype/SingleTextEntry';
import Vue from 'vue';
import FeedbackActivity4 from '../feedbacks/FeedbackActivity4';

export default {
    components: {
        SingleTextEntry
    },
    data:function(){
        return {
            feedback_content: "",
        };
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene5");
        vueapp.$store.commit("setLastURL", "demoscene5activity4");
        //setActivityVisited('scene2', 'act_18');

        setActivityVisited('demoscene5', 'demoscene5activity4');
        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity4)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;

        updateUserSession();
    }
}
</script>