<template>
    <Radio 
        act_id="demoscene3activity4"
        scene_id="demoscene3"
        title="Elección del tratamiento"
        intro_text="Conocemos el diagnóstico y los criterios para iniciar tratamiento para la patología que presenta la paciente; ahora es importante decidir el tratamiento indicado para su problema..."
        question="¿Cuál sería el tratamiento más adecuado para la paciente?"
        :options="[
            {
                id: 0,
                name: 'respuesta0',
                text: 'No es necesario iniciar tratamiento',
                value: 0,
            },
            {
                id: 1,
                name: 'respuesta1',
                text: 'Aumentar el consumo de alimentos ricos en hierro en la dieta',
                value: 1,
            },
            {
                id: 2,
                name: 'respuesta2',
                text: 'Suplemento con hierro oral durante 3 meses',
                value: 2,
            },
            {
                id: 3,
                name: 'respuesta3',
                text: 'Suplemento con hierro oral durante 6 meses',
                value: 3,
            },
            {
                id: 4,
                name: 'respuesta4',
                text: 'Hierro intravenoso administrado en Atención Primaria',
                value: 4,
            },
            {
                id: 5,
                name: 'respuesta5',
                text: 'Hierro intravenoso administrado en Atención Hospitalaria',
                value: 5,
            },
            {
                id: 6,
                name: 'respuesta6',
                text: 'Transfusión de 2 concentrados de hematíes',
                value: 6,
            },
            {
                id: 7,
                name: 'respuesta7',
                text: 'Ninguna de las anteriores es correcta',
                value: 7,
            }
        ]" 
        :correct_answer=5
        incorrect_msg="La respuesta es incorrecta"
        clue_text="Para saber el diagnóstico y los criterios de inicio de tratamiento tienes que ver la analítica y resolver las otras dos preguntas del escenario."
        :feedback="feedback_content"
        :scenario_finisher="true"
        next_scenario="demoscene4">
    </Radio>
</template>

<script>
import Vue from 'vue';
import Radio from '../../../../components/activitytype/Radio';
import FeedbackActivity4 from '../feedbacks/FeedbackActivity4';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Radio
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene3");
        vueapp.$store.commit("setLastURL", "demoscene3activity4");
        setActivityVisited('demoscene3', 'demoscene3activity4');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity4)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;

        updateUserSession();
    }
}
</script>