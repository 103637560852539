<template>
    <CrossWord
        act_id="demoscene5activity3"
        scene_id="demoscene5"
        title="Las definiciones de cada una de las palabras del crucigrama ya las deberías de saber. La palabra que obtengas al resolver el crucigrama es una de las palabras del enunciado final."
        intro_text=""
        question=""
        left_size="col-md-5"
        right_size="col-md-7"
        :feedback="feedback_content"
        :cols="11"
        :rows="6"
        :words="[
            {
                number : 1, 
                direction : 'across',
                row: 1,
                column: 1+10,
                clue: '',
                answer : 'adherencia',
                class: ''
            },
            {
                number : 2, 
                direction : 'across',
                row: 2,
                column: 2+10,
                clue: '',
                answer : 'disnea',
                class: ''
            },
            {
                number : 3, 
                direction : 'across',
                row: 3,
                column: 3+10,
                clue: '',
                answer : 'ecg',
                class: ''
            },
            {
                number : 4, 
                direction : 'across',
                row: 4,
                column: 1+10,
                clue: '',
                answer : 'ferritina',
                class: ''
            },
            {
                number : 5, 
                direction : 'across',
                row: 5,
                column: 10,
                clue: '',
                answer : 'intravenoso',
                class: ''
            },
            {
                number : 6, 
                direction : 'across',
                row: 6,
                column: 2+10,
                clue: '',
                answer : 'hospital',
                class: ''
            },
            {
                number : 7, 
                direction : 'down',
                row: 1,
                column: 3+10,
                clue: '',
                answer : 'hierro',
                class: 'final'
            },
        ]">
    </CrossWord>
</template>

<script>
import Vue from 'vue';
import CrossWord from '../../../../components/activitytype/CrossWord.vue';
import FeedbackActivity3 from '../feedbacks/FeedbackActivity3';
export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components:{
        CrossWord
    },    
    mounted: function(){
        vueapp.$store.commit("setBackURL", "demoscene5");
        vueapp.$store.commit("setLastURL", "demoscene5activity3");

        setActivityVisited('demoscene5', 'demoscene5activity3');
        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;

        updateUserSession();
    }
}
</script>
