<template>
    <div class="row h-100 w-100 m-0">
        <div class="col-12">
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel'})">Panel</a></li>
                    <li class="breadcrumb-item" aria-current="page">Logs</li>
                </ol>
            </nav>
            <div class="row mt-3">
                <div class="col-6 h-100">
                    <h5 class="p-2 bg-dark text-white w-100 d-flex justify-content-between align-items-center font-weight-bold">
                        Logs server:
                        <button class="btn btn-sm btn-nav-blue"><font-awesome-icon :icon="['fa', 'info']" /></button>
                    </h5>
                    <ul class="h-100 overflow-auto list-unstyled" style="max-height: 600px;">
                        <li v-for="log in logs">{{log}}</li>
                    </ul>
                </div>
                <div class="col-6 h-100">
                    <h5 class="p-2 bg-dark text-white w-100 d-flex justify-content-between align-items-center font-weight-bold">
                        Logs Apache:
                        <button class="btn btn-sm btn-nav-blue"><font-awesome-icon :icon="['fa', 'info']" /></button>
                    </h5>
                    <ul class="h-100 overflow-auto list-unstyled" style="max-height: 600px;">
                        <li v-for="log_apache in logs_apache">{{log_apache}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    import dotenv from 'dotenv'
    dotenv.config()
    
    export default {
        name: 'Logs',
        data(){
            return {
                logs: [],
                logs_apache: []
            };
        },
        mounted: function(){
            
        },
        watch:{
            '$store.state.log' : function(){
                this.logs = vueapp.$store.state.log;
            },
            '$store.state.log_apache' : function(){
                this.logs_apache = vueapp.$store.state.log_apache;
            }
        }
    }
</script>

<style scoped>
</style>
