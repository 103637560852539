<template>
    <div id="start-background" class="h-100 w-100">
        <h5 v-if="$route.params.session_id == null" class="position-absolute text-center w-100 bg-warning pt-2 pb-2">Para ingresar a una sesión de juego, por favor, acceda desde el enlace/url correspondiente.</h5>
        <div id="login" class="row h-100 w-100 m-0" :style="(session) ? ((loading) ? cssPropsLoading : cssProps) : ''">
            <div v-if="session" class="w-100 h-100">
                <div v-if="loading" class="w-100 h-100 position-absolute bg-light d-flex justify-content-center align-items-center flex-column">
                    <div class="lds-dual-ring"></div>
                    <span v-if="$route.params.session_id">Cargando la información de la sesión..</span>
                    <span v-else>Sesión invalida.</span>
                </div>
                <div v-else class="col-sm-8 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-center align-items-center flex-column h-100">
                    <!--div v-if="$route.params.session_id">
                        <intro :is="intro" :data="data"></intro>
                    </div-->
                    <div v-if="session.access_mode == 0" class="">
                        <h2 id="intro-case" class="mb-3">LOGIN</h2>
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <input class="mt-3 rounded pr-3 pl-3 pt-2 pb-2" type="text" placeholder="Nombre de usuario" v-model="username">
                            <button type="button" id="intro-button" class="btn btn-light text-dark font-weight-bold p-2 pr-5 pl-5 mt-4" @click="Register()">ENTRAR</button>
                        </div>
                    </div>
                    <div v-else-if="session.access_mode == 1" class="d-flex justify-content-center align-items-center flex-column mb-5">
                        <h2 id="intro-case" class="mb-3">LOGIN</h2>
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <input class="mt-3 rounded pr-3 pl-3 pt-2 pb-2" type="text" placeholder="Nombre de usuario" v-model="username">
                            <input class="mt-3 rounded pr-3 pl-3 pt-2 pb-2" type="password" placeholder="Contraseña" v-on:keyup.enter="Login" v-model="password">
                            <button type="button" id="intro-button" class="btn btn-light text-dark font-weight-bold p-2 pr-5 pl-5 mt-4" @click="Login()">ENTRAR</button>
                        </div>
                    </div>
                    <div v-else-if="session.access_mode == 2" class="d-flex justify-content-center align-items-center flex-column mb-5">
                        <h2 id="intro-case" class="mb-3">DEMO</h2>
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <button type="button" id="intro-button" class="btn btn-light text-dark font-weight-bold p-2 pr-5 pl-5 mt-4" @click="Demo()">ENTRAR</button>
                        </div>
                    </div>
                    <div v-else class="d-flex justify-content-center align-items-center flex-column mb-5">
                        <h2 id="intro-case" class="mb-3">LOGIN</h2>
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <input class="mt-3 rounded pr-3 pl-3 pt-2 pb-2" type="text" placeholder="Nombre de usuario" v-model="username">
                            <input class="mt-3 rounded pr-3 pl-3 pt-2 pb-2" type="password" placeholder="Contraseña" v-on:keyup.enter="Login" v-model="password">
                            <button type="button" id="intro-button" class="btn btn-light text-dark font-weight-bold p-2 pr-5 pl-5 mt-4" @click="Login()">ENTRAR</button>
                        </div>
                    </div>
                    
                </div>
                <div class="col-sm-4 col-md-6 col-lg-7 col-xl-8"></div>
            </div>
            <div v-else aria-describedby="" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center flex-column">
                    <img id="pantalla_perder" src="@/assets/pantalla_perder.png" height="300px">   
                    <h3>¡No se ha podido encontrar la sesión, revisa el enlace de invitación!</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import utils from '../../utils'
    import dotenv from 'dotenv'
    dotenv.config()
    export default {
        name: 'Login',
        props: ['data'],
        data(){
            return {
                loading: true,
                session: null,
                intro: null,
                username: null,
                password: null,
                cssPropsLoading: {
                    backgroundImage: `url(${require('@/assets/cc1/imagen_fondo.png')})`,
                    backgroundPosition: 'center'
                },
                cssProps: {
                    backgroundImage: `url(${require('@/assets/cc1/imagen_fondo.png')})`
                },
            };
        },
        computed: {
            loader() {
                if(this.$route.params.session_id)
                    return () => import('../caso'+vueapp.$store.state.session.case_id+'/Intro');
                
                return null;
            }
        },
        mounted() {
            if(this.$route.params.session_id)
                ws.send(JSON.stringify({action: window.RPC.GET_SESSION, params:this.$route.params.session_id}));
            else{
                this.loading = false;
                this.session = "default";
            }
        },
        watch: {
            '$store.state.session' : function() {
                console.log("Session updated", vueapp.$store.state.session)
                if(vueapp.$store.state.session != null){
                    this.loading = false;
                    this.session = vueapp.$store.state.session;
                    if(this.loader){
                        this.loader()
                            .then(() => {
                                this.intro = () => this.loader()
                            })
                            .catch(() => {
                                this.intro = () => import('../Intro')
                            })
                    }
                }
            },
        },
        methods: {
            Login: function(){
                if(utils.isValidParameter(this.username) && utils.isValidParameter(this.password))
                    ws.send(JSON.stringify({action: window.RPC.CLIENT_LOGIN, params:{username: this.username, password: this.password, session_id: this.$route.params.session_id}}));
                else{
                    vueapp.$toast.warning('Comprueba si los datos del formulario son correctos!', {
                        // optional options Object
                    })
                }
            },
            Register: function(){
                ws.send(JSON.stringify({action: window.RPC.CLIENT_REGISTER, params:{username: this.username, session_id: this.$route.params.session_id}}));
            },
            Demo: function(){
                ws.send(JSON.stringify({action: window.RPC.CLIENT_REGISTER, params:{username: utils.makeID(10), session_id: this.$route.params.session_id}}));
            }
        }
    }
</script>

<style scoped>

    #login{
        background-position: right;
        background-repeat: no-repeat;
        background-size: 70%;
    }

   /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
    */

    @media (min-width: 1281px) {

        #login{
            background-size: 80%;
        }
    }

    /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
    */

    @media (min-width: 1025px) and (max-width: 1280px) {

        #login{
            background-size: 80%;
        }
    }

    /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

        #login{
            background-size: 80%;
        }
    }

    /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 868px to 1024px
    */

    @media (min-width: 868px) and (max-width: 1024px) {

        #login{
            background-size: 80%;
        }
    }

    /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 867px) {

        #login{
            background-size: 100%;
        }
    }

    /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 667px) {

        #login{
            background-size: 100%;
        }
    }

    /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {

        #login{
            background-size: 100%;
        }
    }

    #personaje_1{
        position: absolute;
        right: 150px;
        bottom: 0px;
        width: 20%;
    }
    
</style>
