<template>
    <div class="row h-100 w-100 m-0 d-flex justify-content-center align-items-center position-relative">
        <img id="pantalla_inicio" class="animate__animated animate__zoomIn animate__faster" src="@/assets/pantalla_inicio.png" alt="pantalla_inicio">
        <div id="countdown" class="w-100 h-100 d-flex justify-content-center align-items-center">
            <span>{{ countdown }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'CountDown',
        data: function(){
            return {
                countdown: null,
                countdown_timer: null,
                beep: null
            };
        },
        destroy: function(){
            if(this.countdown_timer != null){
                clearInterval(this.countdown_timer);
                vueapp.$store.commit("setCountDownTimer", null);
            }
        },
        mounted : function(){
            vueapp.$store.state.beep.pause();
            vueapp.$store.state.beep.play();
            this.countdown = vueapp.$store.state.countdown;
            console.log(this.countdown)
            this.countdown_timer = setInterval(function(){
                this.countdown = this.countdown - 1;
                if(this.countdown<=0){
                    clearInterval(this.countdown_timer);
                    router.push({name: 'demomap'});
                }
            }.bind(this), 1000);
            vueapp.$store.commit("setCountDownTimer", this.countdown_timer);
        },
    }
</script>

<style scoped>
    #pantalla_inicio{
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        margin: 30px;
        opacity: 0.2;
    }
    #countdown{
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 200px;
        z-index: 9999;
    }
</style>
