<template>
    <div class="row h-100 w-100 m-0">

        <!-- New user modal -->
        <b-modal v-model="show_new_session_user" id="modal-center-users" size="lg" centered title="Registra nuevo usuario en la sesión" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputUserName">Nombre del usuario:</label>
                <div class="alert alert-info">
                    <span>Los nombres de usuario solo pueden tener:</span>
                    <ul class="mb-0">
                        <li>Letras en minúscula: (a-z)</li>
                        <li>Números: (0-9)</li>
                        <li>Puntos: (.)</li>
                        <li>Guiones bajos: (_)</li>
                    </ul>
                </div>
                <input type="text" v-model="new_user.username" class="form-control" id="inputUserName" name="username" required placeholder="Introduzca el nombre de usuario..">
            </div>
            <div class="form-group mb-2">
                <label for="inputUserPassword">Contraseña:</label>
                <input type="password" v-model="new_user.password" class="form-control" id="inputUserPassword" name="password" required placeholder="Introduzca la contraseña del usuario..">
                <small>El tamaño minimo de la contraseña ha de tener 8 caracteres.</small>
            </div>
            <div class="form-group mb-2">
                <label for="inputUserRePassword">Repetir contraseña:</label>
                <input type="password" v-model="new_user.repassword" class="form-control" id="inputUserRePassword" name="repassword" required placeholder="Repita la contraseña..">
                <small>El tamaño minimo de la contraseña ha de tener 8 caracteres.</small>
            </div>
            <div class="form-group mb-2">
                <label for="inputSession">Sesión de juego:</label>
                <select v-if="session" class="form-control" id="inputSession" name="session_id" :value="session.id" disabled>
                    <option :value="session.id" :key="session.id" selected="selected">{{ session.name }}</option>
                </select>
            </div>
            <div class="form-group mb-2">
                <label for="inputRole">Rol:</label>
                <select v-model="new_user.role" class="form-control" id="inputRole" name="role" value="null">
                    <option value="null">Selecciona un rol</option>
                    <option value="0">Jugador</option>
                    <option value="1">Admin</option>
                    <option v-if="$store.state.user.role == 2" value="2">Super Admin</option>
                </select>
            </div>
            <!--div class="form-group mb-2">
                <label for="inputSessionId">Sesión:</label>
                <select v-model="new_user.session_id" class="form-control" id="inputSessionId" name="session_id" value="null">
                    <option value="null">Selecciona la sesión de juego</option>
                </select>
            </div-->
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="primary"
                        class="float-right"
                        @click="register_user"
                    >
                        Registrar usuario
                    </b-button>
                    <b-button
                        variant="secondary"
                        class="float-right mr-2"
                        @click="show_new_session_user=false"
                    >
                        Cancelar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- Share link modal -->
        <b-modal v-if="session" v-model="show_modal_share" id="modal-share-link" centered title="Comparte la sesión de juego" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputSessionName">Nombre de la sesión:</label>
                <input type="text" v-model="session.name" class="form-control" id="inputSessionName" name="name" readonly>
            </div>
            
            <div class="form-group mb-2">
                <label for="inputSessionLink">Enlace:</label>
                <div class="d-flex">
                    <input type="text" v-model="session.link" class="form-control" id="inputSessionLink" name="link" readonly>
                    <button class="btn btn-warning ml-3" @click="copy_session">Copiar</button>
                </div>
            </div>
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="nav-blue"
                        class="float-right"
                        @click="show_modal_share=false"
                    >
                        Listo
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- Warning modal for finishing the game session -->
        <b-modal v-model="show_modal_warning" id="modal-warning-link" centered title="¡Cuidado!" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputSessionName">¡Esta a punto de finalizar una sesión!</label> 
                <p>Si continua con la operación, los datos de los usuarios de esta sesión se perderán.</p>
                <p class="font-weight-bold">¿Desea continuar con la operación?</p>
            </div>            
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="nav-blue"
                        class="float-right"
                        @click="stop_session"
                    >
                        Continuar
                    </b-button>
                    <b-button
                        variant="secondary"
                        class="float-right mr-2"
                        @click="show_modal_warning=false"
                    >
                        Cancelar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <div class="col-12" v-if="session != null">
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel'})">Panel</a></li>
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel-sessions'})">Sesiones</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ session.name }}</li>
                </ol>
            </nav>
            <div class="row mt-3">
                <div class="col-lg-6">
                    <h5 class="p-2 bg-dark text-white w-100 d-flex justify-content-between align-items-center font-weight-bold">
                        Usuarios que han accedido a la sesión de juego
                        <!--button class="btn btn-sm btn-nav-blue" @click="show_new_session_user=true;"><font-awesome-icon :icon="['fas', 'plus']" /></button-->
                        <button class="btn btn-sm btn-nav-blue"><font-awesome-icon :icon="['fa', 'users']" /></button>
                    </h5>
                    <table class="table table-striped">
                        <thead class="escaperoom-main-bg text-white">
                            <tr>
                                <td class="font-weight-bold">ID</td>
                                <td class="font-weight-bold">ESTADO</td>
                                <td class="font-weight-bold">NOMBRE DE USUARIO</td>
                                <td class="font-weight-bold" v-if="$store.state.session.game_mode == $GAME_MODES.SINGLEPLAYER">REINICIAR</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in session.users" :key="user.id" :id="'user_'+user.id">
                                <!--div class="d-none">{{ (user.session_id == $route.params.id) ? playable=true:playable=false }}</div-->
                                <td>{{ user.id }}</td>
                                <td>
                                    <h5 v-if="user.is_connected == true" class="text-success rounded-circle text-center mb-0">⬤<span class="d-none">true</span></h5> 
                                    <h5 v-if="user.is_connected == false || user.is_connected == null" class="text-secondary rounded-circle text-center mb-0">⬤<span class="d-none">false</span></h5>
                                </td>
                                <td>{{ user.username }}</td>
                                <td :id="'clean_user_session_'+user.id" v-if="$store.state.session.game_mode == $GAME_MODES.SINGLEPLAYER">
                                    <button class="btn btn-warning btn-sm" :id="'user_'+user.id+'_session_id_'+session.id" @click="clean_user_session(user.id, session.id)"><font-awesome-icon :icon="['fa', 'undo-alt']" /></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-6">
                    <h5 class="p-2 bg-dark text-white w-100 d-flex justify-content-between align-items-center font-weight-bold">
                        Detalle de la sesión
                        <div class="d-flex align-items-center">
                            <small v-if="counter != null && (session.status === $STATUS.STARTING || session.status === $STATUS.STARTED)" class="mr-3">Tiempo restante:{{ counter }}</small>
                            <button v-if="$store.state.user.role == $USERS.SUPER_ADMIN" class="btn btn-sm btn-primary btn-sm mr-2" @click="view_activity(session.id)">Ver actividad</button>
                            <div v-if="session.game_mode == 1">
                                <div v-if="session.status === $STATUS.IDLE">
                                    <button class="btn btn-success btn-sm mr-2" @click="start_session">Iniciar</button>
                                </div>
                                <div v-else-if="session.status === $STATUS.STARTING || session.status === $STATUS.STARTED">
                                    <button class="btn btn-danger btn-sm mr-2" @click="show_modal_warning=true">Finalizar</button>
                                </div>
                                <div v-else-if="session.status === $STATUS.STOPPED || session.status === $STATUS.FINISHED">
                                    <button class="btn btn-warning btn-sm mr-2" @click="restart_session">Resetear</button>
                                </div>
                                <div v-else>
                                    Desconocido
                                </div>
                            </div>
                            <!--button class="btn btn-sm btn-nav-blue btn-sm"><font-awesome-icon :icon="['fas', 'pencil-alt']" /></button-->
                            <button class="btn btn-sm btn-nav-blue btn-sm mr-2" @click="view_ranking(session.id)">Ranking</button>
                            <button class="btn btn-sm btn-primary text-white share-session" @click="share_session(session.id, this)"><font-awesome-icon :icon="['fa', 'link']" /></button>
                       </div> 
                    </h5>
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td class="font-weight-bold">ID</td>
                                <td>{{ session.id }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">NOMBRE</td>
                                <td>{{ session.name }}</td>
                            </tr>
                            <!--tr>
                                <td class="font-weight-bold">VISIBLE</td>
                                <td>{{ session.visible }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">ESTADO</td>
                                <td>
                                    <div class="badge badge-primary" v-if="session.status === $STATUS.IDLE">
                                        En espera
                                    </div>
                                    <div class="badge badge-info" v-else-if="session.status === $STATUS.STARTING">
                                        Empezando
                                    </div>
                                    <div class="badge badge-success" v-else-if="session.status === $STATUS.STARTED">
                                        En juego
                                    </div>
                                    <div class="badge badge-danger" v-else-if="session.status === $STATUS.STOPPED">
                                        Detenido
                                    </div>
                                    <div class="badge badge-dark" v-else-if="session.status === $STATUS.FINISHED">
                                        Finalizado
                                    </div>
                                    <div class="badge badge-secondary" v-else>
                                        Desconocido
                                    </div>
                                </td>
                            </tr-->
                            <tr>
                                <td class="font-weight-bold">CASO</td>
                                <td>{{ session.case_id }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">MODO DE ACCESO</td>
                                <td>{{ (session.access_mode==0) ? 'Abierto' : 'A través de login' }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">MODO DE JUEGO</td>
                                <td>{{ (session.game_mode==0) ? 'Individual' : 'Multijugador' }}</td>
                            </tr>
                            <tr v-if="$store.state.session.game_mode == $GAME_MODES.MULTIPLAYER">
                                <td class="font-weight-bold">COMIENZO</td>
                                <td>{{ ( moment(session.startedAt).isValid()) ? moment(session.startedAt).format("DD/MM/YYYY  HH:mm:ss") : '-' }}</td>
                            </tr>
                            <tr v-if="$store.state.session.game_mode == $GAME_MODES.MULTIPLAYER">
                                <td class="font-weight-bold">FIN</td>
                                <td>{{ ( moment(session.finishAt).isValid()) ? moment(session.finishAt).format("DD/MM/YYYY  HH:mm:ss") : '-' }}</td>
                            </tr>
                            <tr v-if="$store.state.session.game_mode == $GAME_MODES.MULTIPLAYER">
                                <td class="font-weight-bold">ESTADO</td>
                                <td>{{ session.status }}</td>
                            </tr>
                            <!--tr>
                                <td class="font-weight-bold">FECHA DE FINALIZACIÓN</td>
                                <td>{{ (session.finishAt) ? moment(session.finishAt).format("DD/MM/YYYY  HH:mm:ss") : '-' }}</td>
                            </tr-->
                            <tr>
                                <td class="font-weight-bold">FECHA DE CREACIÓN</td>
                                <td>{{ moment(session.createdAt).format("DD/MM/YYYY  HH:mm:ss") }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">FECHA DE ACTULALIZACIÓN</td>
                                <td>{{ moment(session.updatedAt).format("DD/MM/YYYY  HH:mm:ss") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RPC from '../../../../shared/RPC'
    import utils from '../../utils'
    export default {
        name: 'Session',
        data(){
            return {
                session: null,
                playable: false,
                // Show/Hide warning modal.
                show_modal_warning: false,
                // Show/Hide share link modal.
                show_modal_share: false,
                // Show/hide new user modal.
                show_new_session_user: false,
                // New user data
                new_user: {
                    username: null,
                    session_id: null,
                    role: 0,
                    password: null,
                    repassword: null,
                },
                // Game time clock.
                counter: null,
                // Game time timer.
                interval: null,
            };
        },
        mounted: function(){
            vueapp.$on("hide-clean-user-session-btn", data => {
                var btn = null;
                btn = document.getElementById('user_'+data.user_id+'_session_id_'+data.session_id);
                if(btn != null)
                    btn.parentElement.removeChild(btn);
            })
            
            ws.send(JSON.stringify({action: RPC.GET_SESSION, params: this.$route.params.id}));
        },
        created : function(){
            vueapp.$on('emptyUpdateUserSession', (user_id) => {
                console.log($('#clean_user_session_'+user_id));
            })
        },
        destroy : function(){
            if(this.interval != null)
                clearInterval(this.interval);
        },
        methods: {
            view_activity : function(session_id){
                router.push({name: "panel-activity", params:{id: session_id}}).catch((e)=>{console.log(e)});
            },
            clean_user_session : function(user_id, session_id){
                ws.send(JSON.stringify({action: RPC.CLEAN_USER_SESSION, params:{user_id: user_id, session_id: session_id}}));
                if(vueapp.$store.state.session.users.length>0){
                    for(var i = 0; i < vueapp.$store.state.session.users.length; i++){
                        if(vueapp.$store.state.session.users[i].id == user_id){
                            vueapp.$store.state.session.users.splice(i, 1);
                            vueapp.$toast.success("Se ha limpiado la sesión de juego del usuario!",{});
                        }
                    }
                }
            },
            register_user : function(){
                if(utils.isValidParameter(this.new_user.username) && utils.isValidParameter(this.new_user.password) && utils.isValidParameter(this.new_user.repassword)){
                    if(!utils.isUserNameValid(this.new_user.username)){
                        vueapp.$toast.error("¡Comprueba el formato del nombre de usuario!");
                        return;
                    }
                    if(this.findUserByUsername(this.new_user.username) == true){
                        vueapp.$toast.error("¡Ya existe un usuario con ese nombre de usuario!");
                        return;
                    }
                    if(this.new_user.password == this.new_user.repassword){
                        if(utils.isValidPasswordLength(this.new_user.password)){
                            // Hide modal
                            this.show_new_session_user = false;
                            // Send packet to the server for register new session
                            ws.send(JSON.stringify({action: RPC.REGISTER_USER, params:this.new_user}));
                            // Reset form values
                            this.new_user.username = null;
                            this.new_user.role = 0;
                            this.new_user.password = null;
                            this.new_user.repassword = null;
                            this.new_user.session_id = null;
                        }else{
                            vueapp.$toast.error("¡Comprueba el tamaño de las contraseñas!");
                        }
                    }else{
                        vueapp.$toast.error("¡Las contraseñas no coinciden!");
                    }
                }else{
                    vueapp.$toast.error("¡Comprueba si los campos nombre del usuario y contraseña estan completados!");
                }
            },
            findUserByUsername : function(username){
                var found = false;
                for(var i = 0; i < vueapp.$store.state.panel_users.length && found == false; i++){
                    if(vueapp.$store.state.panel_users[i].username == username){
                        found = true;
                        return true;
                    }
                }
                return false;
            },
            start_session:function(){
                ws.send(JSON.stringify({action: RPC.SESSION_UPDATE_STATUS, params:{id:this.session.id, status: vueapp.$STATUS.STARTING}}))
            },
            stop_session:function(){
                ws.send(JSON.stringify({action: RPC.SESSION_UPDATE_STATUS, params:{id:this.session.id, status: vueapp.$STATUS.STOPPED}}))
                this.show_modal_warning=false;
                if(this.interval != null){
                    clearInterval(this.interval);
                    this.counter = null;
                }
            },
            restart_session:function(){
                ws.send(JSON.stringify({action: RPC.SESSION_UPDATE_STATUS, params:{id:this.session.id, status: vueapp.$STATUS.IDLE}}))
                if(this.interval != null){
                    clearInterval(this.interval);
                    this.counter = null;
                }
            },
            share_session : function(){
                this.show_modal_share = true;
            },
            copy_session: function(){
                /* Get the text field */
                var copyText = $("#inputSessionLink")[0];

                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices */

                /* Copy the text inside the text field */
                document.execCommand("copy");
                vueapp.$toast.success("Enlace copiado correctamente!");
            },
            view_ranking : function(session_id){
                router.push({name:'panel-ranking-id', params:{id: session_id}}).catch((e)=>{console.log(e)});
            },
            update_session_timer : function(){
                if(this.session.game_mode == window.GAME_MODES.MULTIPLAYER){
                    if(this.session.status == window.STATUS.STARTED){
                        if(this.interval != null)
                            clearInterval(this.interval);

                        this.interval = setInterval(function(){
                            var finish = vueapp.moment(this.session.finishAt);
                            var start = vueapp.moment();
                            var asseconds = vueapp.moment.duration(finish.diff(start)).asSeconds();
                            var minutes = Math.floor(asseconds / 60);
                            var seconds = Math.round(asseconds - minutes * 60);
                  
                            if(finish>start)
                                this.counter = ' '+minutes +'m '+ seconds+'s';
                            else{
                                this.counter = ' 0m 0s';
                                //this.session.status = 4;
                            }
                        }.bind(this), 1000);
                    }
                }
            }
        },
        watch:{
            '$store.state.game_state' : function(){
                console.log(vueapp.$store.state.session)
                this.update_session_timer();
            },
            '$store.state.session' : function(){
                /*if(vueapp.$store.state.panel_sessions.length>0){
                    var found = false;
                    for(var i = 0; i < vueapp.$store.state.panel_sessions.length && found == false; i++){
                        if(vueapp.$store.state.panel_sessions[i].id == this.$route.params.id){
                            found = true;
                            this.session = vueapp.$store.state.panel_sessions[i];
                        }
                    }
                }*/
                this.session = vueapp.$store.state.session;

                

                this.update_session_timer();
            }
        }
    }
</script>

<style scoped>
    
</style>
