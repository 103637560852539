var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Radio',{attrs:{"act_id":"demoscene4activity5","scene_id":"demoscene4","title":"Seguimiento posterior en AP","intro_text":"El médico de AP será el encargado de realizar el seguimiento posterior del paciente con IC","question":"En cuál de estas situaciones no se recomienda el estudio de los parámetros del metabolismo férrico de nuevo según consenso SEC-SEMI?","options":[
        {
            id: 0,
            name: 'respuesta0',
            text: 'Siempre que estudiemos a un paciente con sospecha de IC.',
            value: 0,
        },
        {
            id: 1,
            name: 'respuesta1',
            text: 'Ante progresión clínica de la IC (empeoramiento de los síntomas, reingresos).',
            value: 1,
        },
        {
            id: 2,
            name: 'respuesta2',
            text: 'De forma sistemática cada 6 meses.',
            value: 2,
        },
        {
            id: 3,
            name: 'respuesta3',
            text: 'Cuando existan datos de progresión de la enfermedad, aunque no haya empeoramiento clínico (disminución de la FEVI o aumento de los péptidos natriuréticos) ',
            value: 3,
        },
        {
            id: 4,
            name: 'respuesta4',
            text: 'Si se detecta la presencia de anemia.',
            value: 4,
        }
    ],"correct_answer":2,"incorrect_msg":"La respuesta es incorrecta","clue_text":"Sólo una es errónea","feedback":_vm.feedback_content,"scenario_finisher":true,"next_scenario":"demoscene5"}})}
var staticRenderFns = []

export { render, staticRenderFns }