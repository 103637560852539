<template>
    <div class="row h-100 w-100 m-0">
        
        <register-session-modal ref="register_session_modal"></register-session-modal>

        <!-- Remove session modal -->
        <b-modal v-model="show_delete_session" id="modal-warning-link" centered title="¡Cuidado!" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputSessionName">¡Esta a punto de eliminar la sesión de juego: <b v-if="current_delete_session">{{ current_delete_session.name }}</b>!</label> 
                <p>Si continua con la operación, los datos de la sesión se perderán.</p>
                <p class="font-weight-bold">¿Desea continuar con la operación?</p>
            </div>            
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="nav-blue"
                        class="float-right"
                        @click="remove_session(current_delete_session.id);show_delete_session = false;"
                    >
                        Continuar
                    </b-button>
                    <b-button
                        variant="secondary"
                        class="float-right mr-2"
                        @click="show_delete_session=false"
                    >
                        Cancelar
                    </b-button>
                </div>
            </template>
        </b-modal>

        <!-- Share link modal -->
        <b-modal v-model="show_modal_share" v-if="share_session_obj" id="modal-share-link" centered title="Comparte la sesión de juego" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputSessionName">Nombre de la sesión:</label>
                <input type="text" v-model="share_session_obj.name" class="form-control" id="inputSessionName" name="name" readonly>
            </div>
            
            <div class="form-group mb-2">
                <label for="inputSessionLink">Enlace:</label>
                <div class="d-flex">
                    <input type="text" v-model="share_session_obj.link" class="form-control" id="inputSessionLink" name="link" readonly>
                    <button class="btn btn-warning ml-3" @click="copy_session">Copiar</button>
                </div>
            </div>
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="nav-blue"
                        class="float-right"
                        @click="show_modal_share=false"
                    >
                        Listo
                    </b-button>
                </div>
            </template>
        </b-modal>

        <div class="col-12">
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel'})">Panel</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Sesiones</li>
                </ol>
                <div class="d-none">        
                    <font-awesome-icon id="trash-icon" :icon="['fa', 'trash']" />
                    <font-awesome-icon id="link-icon" :icon="['fa', 'link']" />
                    <font-awesome-icon id="broom-icon" :icon="['fa', 'broom']" />
                </div>
            </nav>
            <div class="col-12 p-0">
                <div class="row bg-dark text-white pt-3 pb-3 rounded m-0">
                    <div class="col d-flex justify-content-start align-items-center">
                        <h5 class="mb-0"><b>Sesiones</b></h5>
                    </div>
                    <div class="col">
                        <b-button @click="register_session_modal" variant="nav-blue" class="btn btn-sm float-right">Crear sesión</b-button>
                    </div>
                </div>
                <hr class="mt-2 mb-2">
                <table id="table_sessions" class="table table-hover table-striped border-bottom mb-3" style="width:100%">
                    <thead class="bg-dark text-white">
                        <tr>
                            <td>id</td>
                            <td>Nombre</td>
                            <td>Caso</td>
                            <!--td>Fecha de finalización</td-->
                            <td>Modo de acceso</td>
                            <td>Modo de juego</td>
                            <td>Fecha de creación</td>
                            <!--td>Fecha de actualización</td-->
                            <!--td>Visible</td>
                            <td>Estado</td-->
                            <td>Acciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        <!--tr v-for="session in $store.state.panel_sessions" :key="session.id" class="session_row" :data-id="session.id">
                            <td>{{ session.id }}</td>
                            <td><router-link @click.native="view_session(session.id)" v-bind:to="'/panel/session/'+session.id">{{ session.name }}</router-link></td>
                            <td>Caso clínico: {{ session.case_id }}</td>
                            <td>{{ (session.access_mode==0) ? 'Abierto': 'A través de login' }}</td>
                            <td>{{ (session.game_mode==0) ? 'Individual': 'Multijugador' }}</td>
                            <td>{{ (session.createdAt) ? moment(session.createdAt).format("DD/MM/YYYY  HH:mm:ss"): '-' }}</td>
                            
                            <td>
                                <div class="row">        
                                    <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                        <button class="btn btn-sm btn-danger text-white delete-session" @click="remove_session(session.id)"><font-awesome-icon :icon="['fa', 'trash']" /></button>
                                    </div>
                                    <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                        <button class="btn btn-sm btn-primary text-white share-session" @click="share_session(session.id)"><font-awesome-icon :icon="['fa', 'link']" /></button>
                                    </div>
                                </div>
                            </td>
                        </tr-->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import Vue from 'vue';
    import Actions from './Actions';
    import RegisterSessionModal from '../../components/panel/RegisterSessionModal.vue';
    export default {
        name: 'Sessions',
        components: {
            Actions, 'register-session-modal': RegisterSessionModal
        },
        created (){
            vueapp.$on('emptySessionsTable', () => {
                console.log("table needs to be empty")
                this.datatable_sessions.clear().draw();
            })
        },
        data : function(){
            return {
                show: false,
                // Show/Hide delete session modal.
                show_delete_session: false,
                current_delete_session: {
                    name: "",
                    id: null
                },
                // Show/Hide share link modal
                show_modal_share: false,
                current_pagination: null,
                actions_content: "",
                new_session: {
                    name: null,
                    case_id: 1,
                    visible: false,
                    access_mode: 0, // 0: opened, 1: login
                    game_mode: 0, // 0: singleplayer, 1: multiplayer
                },
                share_session_obj: null,
                datatable_sessions: null,
                html_trash: '',
                html_link:'',
                html_broom:''
            };
        },
        mounted: function() {
            ws.send(JSON.stringify({action: RPC.GET_SESSIONS, params: null}));
            this.html_trash = document.getElementById("trash-icon");
            this.html_link = document.getElementById("link-icon");
            this.html_broom = document.getElementById("broom-icon");

            // Create a dummy DOM node.
            const div = document.createElement('div');
            // Create a new Vue instance and use the dummy div as a mount point
            const componentInstance = new Vue(Object.assign({}, Actions)).$mount(div); 
            // Get HTML as a string from the newly created Vue instance
            const html = componentInstance.$el.outerHTML;
            this.actions_content = html;

            // Create the datatable instance with the configuration for panel sessions.
            this.datatable_sessions = $('#table_sessions').DataTable({
                responsive: true,
                "dom": '<"toolbar">frtip',
                "iDisplayLength": 20,
                "bLengthChange" : false,
                "bInfo" : false,
                "oLanguage": {
                    "sSearch": "Buscar:"
                },
                "language": {
                    "paginate": {
                        "previous": "Anterior",
                        "next": "Siguiente"
                    }
                }
            });
            // Save in vuex the datatable instance for panel sessions
            vueapp.$store.commit("setPanelSessionsDataTable", this.datatable_sessions);

            window['remove_session'] = this.remove_session;
            window['share_session'] = this.share_session;
            window['view_session'] = this.view_session;
            window['clean_session'] = this.clean_session;
            window['show_delete_session_modal'] = this.show_delete_session_modal;
        },
        watch: {
            '$store.state.panel_sessions': function() { 
                // Clear table content
                this.datatable_sessions.clear();
                // Fill table content
                for(var i = 0; i < vueapp.$store.state.panel_sessions.length; i++){
                    var id = vueapp.$store.state.panel_sessions[i].id;
                    var name = "<a href='#' onclick='view_session("+id+")'>"+vueapp.$store.state.panel_sessions[i].name+"</a>";
                    var case_id = "Caso clínico: "+vueapp.$store.state.panel_sessions[i].case_id;
                    var access_mode = null;
                    switch(parseInt(vueapp.$store.state.panel_sessions[i].access_mode)){
                        case window.ACCESS_MODE.OPENED: access_mode = "Abierto";
                            break;
                        case window.ACCESS_MODE.LOGIN: access_mode = "A través de login";
                            break;
                        case window.ACCESS_MODE.DEMO: access_mode = "Demo";
                            break;
                        default: access_mode = "-"; break;
                    }
                    
                    //(vueapp.$store.state.panel_sessions[i].access_mode==0) ? 'Abierto': '';
                    var game_mode = (vueapp.$store.state.panel_sessions[i].game_mode==0) ? 'Individual': 'Multijugador';
                    var createdAt = (vueapp.$store.state.panel_sessions[i].createdAt) ? moment(vueapp.$store.state.panel_sessions[i].createdAt).format("DD/MM/YYYY  HH:mm:ss"): '-';
                    
                    if(vueapp.$store.state.user.role == window.USERS.SUPER_ADMIN){
                        var actions = ` 
                            <div class="row">        
                                <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                    <button class="btn btn-sm btn-danger text-white delete-session" onclick="show_delete_session_modal(`+id+`)">`+this.html_trash.outerHTML+`</button>
                                </div>
                                <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                    <button class="btn btn-sm btn-warning text-white share-session" onclick="clean_session(`+id+`)">`+this.html_broom.outerHTML+`</button>
                                </div>
                                <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                    <button class="btn btn-sm btn-primary text-white share-session" onclick="share_session(`+id+`)">`+this.html_link.outerHTML+`</button>
                                </div>
                            </div>
                        `;
                    }else{
                            
                        var actions = ` 
                            <div class="row">        
                                <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                    <button class="btn btn-sm btn-danger text-white delete-session" onclick="show_delete_session_modal(`+id+`)">`+this.html_trash.outerHTML+`</button>
                                </div>
                                <div class="col-md-4 p-0 d-flex justify-content-center align-items-center">
                                    <button class="btn btn-sm btn-primary text-white share-session" onclick="share_session(`+id+`)">`+this.html_link.outerHTML+`</button>
                                </div>
                            </div>
                        `;
                    }

                    // Add new row
                    this.datatable_sessions.row.add([id, name, case_id, access_mode, game_mode, createdAt, actions]).draw();
                }
            }
        },
        methods: {
            register_session_modal : function(){
                this.$refs.register_session_modal.show_modal();
            },
            show_delete_session_modal : function(session_id){
                if(vueapp.$store.state.panel_sessions.length>0){
                    var found = false;
                    for(var i = 0; i < vueapp.$store.state.panel_sessions.length && found == false; i++){
                        if(vueapp.$store.state.panel_sessions[i].id == session_id){
                            found = true;
                            this.current_delete_session = vueapp.$store.state.panel_sessions[i];
                        }
                    }
                }
                this.show_delete_session = true;
            },
            share_session : function(session_id){
                if(vueapp.$store.state.panel_sessions.length>0){
                    var found = false;
                    for(var i = 0; i < vueapp.$store.state.panel_sessions.length && found == false; i++){
                        if(vueapp.$store.state.panel_sessions[i].id == session_id){
                            found = true;
                            this.share_session_obj = vueapp.$store.state.panel_sessions[i];
                            this.share_session_obj.link = window.location.origin+'/login/'+vueapp.$store.state.panel_sessions[i].id;
                        }
                    }
                }
                this.show_modal_share = true;
            },
            copy_session: function(){
                /* Get the text field */
                var copyText = $("#inputSessionLink")[0];

                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices */

                /* Copy the text inside the text field */
                document.execCommand("copy");
                vueapp.$toast.success("Enlace copiado correctamente!");
            },
            remove_session : function(session_id){
                ws.send(JSON.stringify({action:RPC.REMOVE_SESSION, params: session_id}));
            },
            view_session : function(session_id){
                router.push({name: 'panel-session-id', params: {id: session_id}}).catch((e)=>{console.log(e)});
            },
            show_modal : function(){
                this.show = true;
            },
            clean_session : function(session_id){
                ws.send(JSON.stringify({action:RPC.CLEAN_SESSION, params: session_id}));
            }
        }
    }
</script>

<style scoped>
    #table_sessions_filter{
        width: 50%;
    }
</style>
