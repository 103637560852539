<template>
<div class="w-100 h-100 demoscene4activity2">
    <Order 
        act_id="demoscene4activity2"
        scene_id="demoscene4"
        title="Diferencias entre las presentaciones de hierro para uso intravenoso"
        intro_text="En la actualidad se dispone de diferentes presentaciones para uso intravenoso con diferentes características físicas y bioquímicas."
        question="Asocia la columna de la derecha con una de las presentaciones de hierro intravenoso de la izquierda. Cada principio activo tiene 4 características."
        :options="[
            /*{
                id: 0,
                name: 'Hidróxido de hierro + carbohidrato',
                cssclass: 'opcion0',
                val: 0,
            },
            {
                id: 1,
                name: 'Complejo férrico + polímeros de hidratos de  carbono',
                cssclass: 'opcion1',
                val: 1,
            },
            {
                id: 2,
                name: 'No se elimina por vía renal ni diálisis',
                cssclass: 'opcion2',
                val: 2,
            },
            {
                id: 3,
                name: 'Se distribuye rápidamente unido a apotransferrina y ferritina',
                cssclass: 'opcion3',
                val: 3,
            },
            {
                id: 4,
                name: 'Se deposita en hígado y bazo',
                cssclass: 'opcion4',
                val: 4,
            },
            {
                id: 5,
                name: 'Vida media 7-12h con eliminación renal mínima',
                cssclass: 'opcion5',
                val: 5,
            },
            {
                id: 6,
                name: 'Se han reportado reacciones anafilactoides o pseudoalérgicas por administración de dosis superiores a las recomendadas o infusión rápida.',
                cssclass: 'opcion6',
                val: 6,
            },
            {
                id: 7,
                name: 'Mejor perfil de seguridad, permite administrar dosis mayores de hierro en menor tiempo de infusión.',
                cssclass: 'opcion7',
                val: 7,
            }*/

            {
                id: 0,
                name: 'Dosis máxima semanal: 600 mg',
                cssclass: 'opcion0 bg-bluelight',
                val: 0,
            },
            {
                id: 1,
                name: 'Sólo se puede administrar en adultos',
                cssclass: 'opcion1 bg-bluelight',
                val: 1,
            },
            {
                id: 6,
                name: 'Eliminación renal insignificante y mejor perfil de seguridad',
                cssclass: 'opcion6',
                val: 6,
            },
            {
                id: 7,
                name: 'Único hierro iv recomendado por las Guías Europeas de Cardiología',
                cssclass: 'opcion7',
                val: 7,
            },
            //-----------------------------------------------------------
            {
                id: 4,
                name: 'Dosis máxima semanal: 1000 mg',
                cssclass: 'opcion4',
                val: 4,
            },
            {
                id: 3,
                name: 'Se han reportado reacciones anafilactoides o pseudoalérgicas por administración de dosis superiores a las recomendadas o infusión rápida.',
                cssclass: 'opcion3 bg-bluelight',
                val: 3,
            },
            {
                id: 5,
                name: 'Se puede administrar en ≥ 14 años',
                cssclass: 'opcion5',
                val: 5,
            },
            {
                id: 2,
                name: 'Eliminación renal de un 75% de la dosis administrada',
                cssclass: 'opcion2 bg-bluelight',
                val: 2,
            },
        ]" 
        :correct_order="[0,1,2,3,4,5,6,7]"
        left_column="col-6 m-0 p-0"
        right_column="col-6 m-0 p-0"
        left_size="col-4"
        right_size="col-8"
        :table_body="[
            {
                rowspan: 4,
                class: 'p-0 m-0 bg-bluelight',
                style: 'height:50%;min-height: 50%;',
                tds:[
                    {
                        style: 'width: 25%; border-bottom: 10px solid #31718a;text-align: center;vertical-align: middle;',
                        content: '<span>Hierro sacarosa (HS)</span>'
                    },
                    {
                        draggable: true,
                        class: 'w-100 h-100 p-0 m-0 right-col-transparent',
                        rowspan: 6,
                    }
                ]
            },
            {
                rowspan: 4,
                class: 'p-0 m-0 bg-bluedark',
                style: 'height:50%;min-height: 50%;',
                tds:[
                    {
                        style: 'width: 25%;text-align: center;vertical-align: middle;',
                        content: '<span>Hierro carboximaltosa (HCM)</span>'
                    },
                    {
                        content: null
                    }
                ]
            },            
        ]"
        incorrect_msg="La respuesta es incorrecta"
        clue_text="Cada uno tiene 4 items"
        row_style="height: 70px;background-color: rgba(0,0,0,0);"
        :feedback="feedback_content"
        :check_activity_children="check_activity_children">
    </Order></div>
</template>

<script>
//:correct_order="[0,2,3,6,1,4,5,7]"
import Vue from 'vue';
import Order from '../../../../components/activitytype/Order';
import FeedbackActivity2 from '../feedbacks/FeedbackActivity2';

export default {
    data: function(){
        return {
            feedback_content: "",
        };
    },
    components: {
        Order
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene4");
        vueapp.$store.commit("setLastURL", "demoscene4activity2");
        setActivityVisited('demoscene4', 'demoscene4activity2');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity2)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;

        updateUserSession();
    },
    methods: {
        check_activity_children : function(context){
            //console.log("check_activity_children",context)
            console.log(context.dragmodel)
            var current_dragmodel = [];
            context.dragmodel.forEach((item)=>{
                current_dragmodel.push(item.val);
            })
            var fisrt_section = [current_dragmodel[0],current_dragmodel[1],current_dragmodel[2],current_dragmodel[3]];
            fisrt_section.sort();
            var second_section = [current_dragmodel[4],current_dragmodel[5],current_dragmodel[6],current_dragmodel[7]];
            second_section.sort();
            var final_dragmodel = fisrt_section.concat(second_section);
            if(compareArrays(final_dragmodel,context.correct_order)){
                context.activity_response = true;
                context.is_correct = true;
                setActivityStatus(context.scene_id, context.act_id, context.$data);
                var act = getActivityStatus(context.scene_id, context.act_id);
                if(act.completed == false)
                    vueapp.$store.commit("setScore", vueapp.$store.state.demo.score + 1);
                setActivityCompleted(context.scene_id, context.act_id, true);
                if(context.scenario_finisher == true){
                    setSceneLocked(context.next_scenario, false);
                    setSceneCompleted(context.next_scenario, true);
                }
                if(context.$store.state.correct != null)
                    context.$store.state.correct.play();
            }else{
                context.activity_response = false;
                context.is_correct = false;
                setTimeout(function(){
                    context.activity_response = null;
                    context.is_correct = null;
                }.bind(this), 3000);
                if(context.$store.state.error != null)
                    context.$store.state.error.play();
            }
        }
    }
}
</script>