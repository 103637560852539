var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Radio',{attrs:{"act_id":"demoscene3activity2","scene_id":"demoscene3","title":"Interpretación de analítica completa en paciente con IC","intro_text":"La analítica completa en el contexto de una descompensación de IC aporta mucha información al médico de AP","question":"¿Ante los resultados de la analítica cuál sería la sospecha diagnóstica?","options":[
        {
            id: 0,
            name: 'respuesta0',
            text: 'Anemia ferropénica',
            value: 0,
        },
        {
            id: 1,
            name: 'respuesta1',
            text: 'Descompensación aguda de IC',
            value: 1,
        },
        {
            id: 2,
            name: 'respuesta2',
            text: 'Déficit de hierro sin anemia',
            value: 2,
        },
        {
            id: 3,
            name: 'respuesta3',
            text: 'Insuficiencia respiratoria aguda secundaria a infección respiratoria',
            value: 3,
        },
        {
            id: 4,
            name: 'respuesta4',
            text: 'Insuficiencia renal crónica agudizada',
            value: 4,
        },
        {
            id: 5,
            name: 'respuesta5',
            text: 'Hipopotasemia',
            value: 5,
        },
        {
            id: 6,
            name: 'respuesta6',
            text: 'Hipertiroidismo',
            value: 6,
        },
        {
            id: 7,
            name: 'respuesta7',
            text: 'Síndrome ansioso-depresivo',
            value: 7,
        }
    ],"correct_answer":2,"incorrect_msg":"La respuesta es incorrecta","clue_text":"Piensa un poco, no es complicado...","feedback":_vm.feedback_content}})}
var staticRenderFns = []

export { render, staticRenderFns }