<template>
    <div class="activity4checks w-100 h-100">
        <Checks 
            act_id="demoscene2activity4"
            scene_id="demoscene2"
            title="Factores desencadenantes de descompensación de ICC"
            intro_text="En los pacientes con ICC, la descompensación aguda de su enfermedad puede ocurrir sin factores desencadenantes conocidos, pero en la mayoría de las ocasiones están presentes varios factores. Alguno de estos factores requieren detección y tratamiento urgentes." 
            question="¿Cuál de los siguientes factores desencadenantes de descompensación de ICC requieren tratamiento urgente?" 
            :options="[
                {
                    text: 'Síndrome coronario agudo (SCA)',
                    value: 0,
                },
                {
                    text: 'Alteración del ritmo grave (taquiarritmia/bradiarritmia)',
                    value: 1,
                },
                {
                    text: 'Emergencia hipertensiva',
                    value: 2,
                },
                {
                    text: 'Infecciones (neumonía, endocarditis infecciosa, sepsis)',
                    value: 3,
                },
                {
                    text: 'Falta de adherencia al tratamiento farmacológico o no farmacológico',
                    value: 4,
                },
                {
                    text: 'Patología mecánica aguda',
                    value: 5,
                },
                {
                    text: 'Consumo de fármacos (AINE, corticoides) o tóxicos (alcohol, drogas)',
                    value: 6,
                },
                {
                    text: 'Tromboembolismo pulmonar',
                    value: 7,
                },
                {
                    text: 'Exacerbación de EPOC',
                    value: 8,
                },
                {
                    text: 'Alteraciones hormonales o metabólicas (disfunción tiroidea, cetoacidosis diabética, embarazo)',
                    value: 9,
                },
                {
                    text: 'Cirugía, situaciones de estrés',
                    value: 10,
                }
            ]" 
            :correct_answer="[
                0, 1, 2, 3, 5, 7
            ]"
            clue_text="Según la Guía ESC 2021 sobre el diagnóstico y tratamiento de la IC son 7, que quedan recogidas bajo el acrónimo CHAMPIT."
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            ></Checks>
        </div>
</template>

<script>
//clue_text="Según la Guía ESC 2016 sobre el diagnóstico y tratamiento de la insuficiencia cardiaca aguda y crónica son 5."
//:correct_answer="[
//    0, 1, 2, 5, 7
//]"
import Vue from 'vue';
import Checks from '../../../../components/activitytype/Checks';
import FeedbackActivity4 from '../feedbacks/FeedbackActivity4';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Checks
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene2");
        vueapp.$store.commit("setLastURL", "demoscene2activity4");
        setActivityVisited('demoscene2', 'demoscene2activity4');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity4)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
        
        updateUserSession();
    }
}
</script>
