<template>
    <div id="start-background" class="h-100 w-100">
        <div class="row h-100 w-100 m-0 p-0">
            <div class="col-sm-8 col-md-6 col-lg-5 col-xl-3 d-flex justify-content-center align-items-start flex-column bg-white">
                <h2 id="intro-case" class="mb-3 ml-3">CASO CLÍNICO</h2>
                <div class="d-flex justify-content-center align-items-center flex-column">
                    <p id="intro-text" class="p-3">
                        Juana, es una mujer de 74 años, pluripatológica y polimedicada. Vive sola, es independiente para las actividades de la vida diaria.
                    </p>
                    <button type="button" id="intro-button" class="btn btn-light text-dark font-weight-bold p-3 pr-5 pl-5 mt-4" @click="Start()">COMENZAR</button>
                </div>
            </div>
            <div id="start" class="col-sm-4 col-md-6 col-lg-7 col-xl-9 p-0 m-0" :style="cssProps"></div>
            <img id="personaje_1" src="@/assets/personaje_1.png" alt="personaje_1">
        </div>
    </div>
</template>

<script>
    import RPC from '../../../../shared/RPC'
    export default {
        name: 'Start',
        data(){
            return {
                cssProps: {
                    backgroundImage: `url(${require('@/assets/imagen_fondo.png')})`
                }
            };
        },
        methods: {
            Start: function(){
                vueapp.$store.state.session.status = window.STATUS.STARTING;
                vueapp.$store.state.game_state = window.STATUS.STARTING;
                // Move the user to the countdown.
                router.push({name:"democountdown"}).catch((e)=>{console.log(e)});
            }
        }
    }
</script>

<style scoped>

    #start{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    #personaje_1{
        position: absolute;
        right: 30%;
        bottom: -60px;
        width: 30%;
    }
    
</style>
