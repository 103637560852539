<template>
    <div class="activity2checks w-100 h-100">
        <Checks 
            act_id="demoscene2activity2"
            scene_id="demoscene2"
            title="Pruebas complementarias para el diagnóstico de ICA o descompensación de ICC."
            intro_text="En muchas ocasiones los síntomas y signos de descompensación de ICC son poco específicos y se precisa de pruebas adicionales para confirmar el diagnóstico." 
            question="¿Cuál de las siguientes pruebas diagnósticas solicitaría en esta primera visita?" 
            :options="[
                {
                    text: 'PCR para descartar infección por Sars-CoV2',
                    value: 0,
                },
                {
                    text: 'ECG',
                    value: 1,
                },
                {
                    text: 'Radiografía de tórax',
                    value: 2,
                },
                {
                    text: 'Espirometría forzada',
                    value: 3,
                },
                {
                    text: 'Analítica completa (con péptidos natriuréticos si se dispone de ellos)',
                    value: 4,
                },
                {
                    text: 'Ecocardiograma',
                    value: 5,
                },
                {
                    text: ' Ecodoppler de extremidades inferiores',
                    value: 6,
                },
                {
                    text: 'Prueba de esfuerzo cardíaco',
                    value: 7,
                },
                {
                    text: 'Test de la marcha de 6 minutos',
                    value: 8,
                }
            ]" 
            :correct_answer="[
                1, 2, 4
            ]"
            clue_text="Son 3 las correctas"
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            ></Checks>
        </div>
</template>

<script>
import Vue from 'vue';
import Checks from '../../../../components/activitytype/Checks';
import FeedbackActivity2 from '../feedbacks/FeedbackActivity2';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Checks
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene2");
        vueapp.$store.commit("setLastURL", "demoscene2activity2");
        setActivityVisited('demoscene2', 'demoscene2activity2');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity2)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
        
        updateUserSession();
    }
}
</script>
