var dispatcher = {
    listeners: [],

    fireSignal: function(signal, param) {
        if (this.listeners[signal])
            this.listeners[signal](param);
    },

    setSignalHandler: function(signal, handler) {
        this.listeners[signal] = handler;
    }
};

module.exports = dispatcher;