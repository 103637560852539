<template>
    <div id="navigationbar" :class="['row p-2', ((blue_rules.indexOf($route.name) > -1) ? 'escaperoom-main-bg':'escaperoom-white-bg')]">
        <register-user-modal ref="register_user_modal"></register-user-modal>
        <register-session-modal ref="register_session_modal"></register-session-modal>
        <b-modal id="modal-center" centered hide-footer title-html="<span class='text-white text-rockwell'>¡Cuidado!</span>">
            <p class="my-4 text-oswald">¿Esta seguro que desea salir de la demostración?</p>
            <hr>
            <div class="col p-0">
                <b-button class="float-right btn escaperoom-main-bg ml-2" @click="resetDemo('modal-center')">Continuar</b-button>
                <b-button class="float-right btn btn-escaperoom" @click="hideModal('modal-center')">Cancelar</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" id="modal-help" ref="modal-help" centered hide-footer title-html="<span class='text-white text-rockwell'>Ayuda</span>">
            <help></help>
        </b-modal>

        <div class="user-info col-5 h-100">
            <div class="h-100 d-flex justify-content-start align-items-center w-100">
                <!-- Logo white -->
                <img v-if="(blue_rules.indexOf($route.name) > -1)" id="logo-white-color" src="@/assets/logo_SEMG.png">
                <!-- Logo white end -->

                <!-- Logo blue -->
                <img v-if="(white_rules.indexOf($route.name) > -1)" id="logo-blue-color" src="@/assets/logo-azul.png">
                <!-- Logo blue end -->

                <!-- Quit demo -->
                <!--button v-if="$store.state.demo.demo" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" v-b-modal.modal-center>Salir de la demo</button-->
                <!-- Quit demo end -->
            </div>            
        </div>
        <div v-if="$store.state.user" :class="[($store.state.user.role == $USERS.PLAYER)?'col-2':'d-none', ' clock h-100 d-flex justify-content-center align-items-center']">
            <div v-if="$store.state.user" class="row no-gutters clock-container">
                <!--timer class="col-md-3 timer"/-->
                <!--div v-if="typeof ($store.state.demo.timer) !=='undefined' && $store.state.demo.timer && ($store.state.demo.timer > new Date().getTime())" class="align-middle time-text" >
                    <FlipDown
                        :type="2"
                        :endDate="$store.state.demo.timer"
                        @timeUp="onFinished"
                    />
                </div-->
                <div v-if="typeof ($store.state.timer) !=='undefined' && $store.state.timer && ($store.state.timer > new Date().getTime()) && $route.name !='end' && $route.name !='ranking-id'" class="align-middle time-text" >
                    <FlipDown
                        :type="2"
                        :endDate="$store.state.timer"
                        @timeUp="onFinished"
                    />
                </div>
                <div v-if="finished == true">
                    <small class="text-danger font-weight-bold bg-light p-2 time-text rounded">00:00</small>
                </div>
            </div>
        </div>
        <div v-if="$store.state.user" :class="[($store.state.user.role == $USERS.PLAYER)?'col-5':'col-7', 'h-100 d-flex justify-content-end align-items-center']">
            <div v-if="['panel', 'panel-sessions', 'panel-session-id', 'panel-users', 'panel-logs', 'panel-user-id', 'panel-ranking-id', 'panel-activity'].indexOf($route.name) > -1">
                <button @click="panic" class="btn btn-nav-white text-oswald" v-if="$store.state.user.role == $USERS.SUPER_ADMIN">Reiniciar servidor</button>
                <button @click="logs" class="btn btn-nav-white text-oswald ml-3" v-if="$store.state.user.role == $USERS.SUPER_ADMIN">Logs</button>
                <b-dropdown right :variant="(blue_rules.indexOf($route.name) > -1) ? 'nav-white':'nav-blue'" id="dropdown-1"  text="Sesiones" toggle-class="text-decoration-none" class="btn rounded text-oswald text-white pr-0">
                    <b-dropdown-item @click="$router.push({name:'panel-sessions'})">Ver listado de sesiones</b-dropdown-item>
                    <b-dropdown-item @click="register_session_modal">Añadir nueva sesión</b-dropdown-item>
                </b-dropdown>  
                <b-dropdown right :variant="(blue_rules.indexOf($route.name) > -1) ? 'nav-white':'nav-blue'" id="dropdown-2"  text="Usuarios" toggle-class="text-decoration-none" class="btn rounded text-oswald text-white pr-0">
                    <b-dropdown-item @click="$router.push({name:'panel-users'})">Ver listado de usuarios</b-dropdown-item>
                    <b-dropdown-item @click="register_user_modal">Añadir nuevo usuario</b-dropdown-item>
                </b-dropdown>  
                <!--button class="btn rounded mb-0 ml-3 text-oswald btn-nav-white" href="" @click="$router.push({name:'panel-sessions'})">Sesiones</button>
                <button class="btn rounded mb-0 ml-3 text-oswald btn-nav-white" href="" @click="$router.push({name:'panel-users'})">Usuarios</button-->
            </div>

            <!-- Ranking button -->
            <button v-if="$route.name == 'end'" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="ranking">Ranking</button>
            <!-- Ranking button end-->

            <!-- Demo volver mapa -->
            <button v-if="$store.state.back_url == 'demomap' && $route.name != 'end'" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="$router.push({name:'demomap'})">
                <font-awesome-icon v-if="$store.state.demo.fsbtn_mini == true" :icon="['fas', 'arrow-left']" />
                <span v-else>Mapa</span>
            </button>
            <button v-if="demo_scenes.indexOf($store.state.back_url) > -1 && $route.name != 'end' && $route.name != 'ranking-id'" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="$router.push({name:$store.state.back_url})">
                <font-awesome-icon v-if="$store.state.demo.fsbtn_mini == true" :icon="['fas', 'arrow-left']" />
                <span v-else>Volver</span>
            </button>
            <!-- Demo volver mapa end -->
            <!-- Full screen button -->
            <button type="button" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" :tooltip="[($store.state.demo.demo || panel_routes.indexOf($route.name) > -1 || $store.state.fs_message == false || $store.state.demo.fs_message == false)?'':'¡Para una mejor experiencia, utilice la pantalla completa!']" :flow="['left']" id="fsbtn" ref="fsbtn" @click="toggleFullScreen"><font-awesome-icon v-if="is_fullscreen == false" :icon="['fas', 'expand']" /> <font-awesome-icon v-if="is_fullscreen == true" :icon="['fas', 'compress']" /> {{ ($store.state.demo.fsbtn_mini==false) ? text_btn_fs:'' }}</button>
            <!-- Full screen button end -->
            <!-- Volver button -->
            <button v-if="$store.state.demo.demo == true && demo_activities.indexOf($route.name) > -1" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="goBack">Volver</button>
            <!-- Volver button end -->

            <b-dropdown right :variant="(blue_rules.indexOf($route.name) > -1) ? 'nav-white':'nav-blue'" id="dropdown-1"  :text="$store.state.user.username" toggle-class="text-decoration-none" class="btn rounded mb-0 text-oswald text-white">
                <b-dropdown-item @click="logout">Cerrar sesión</b-dropdown-item>
            </b-dropdown>    

            <!-- Help button -->
            <button :class="['btn text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue'), 'btn-nav-generic']" @click="showHelpModal">
                <font-awesome-icon :icon="['fas', 'question']" />
            </button>
            <!-- Help button end -->
        </div>
        <div v-else class="col-7 h-100 d-flex justify-content-end align-items-center">
            <!-- Full screen button -->
            <button type="button" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" :tooltip="[($store.state.demo.demo)?'':'¡Para una mejor experiencia, utilice la pantalla completa!']" :flow="['left']" id="fsbtn" ref="fsbtn" @click="toggleFullScreen"><font-awesome-icon v-if="is_fullscreen == false" :icon="['fas', 'expand']" /> <font-awesome-icon v-if="is_fullscreen == true" :icon="['fas', 'compress']" /> {{ ($store.state.demo.fsbtn_mini==false) ? text_btn_fs:'' }}</button>
            <!-- Full screen button end -->
            
            <!-- Demo button -->
            <!--button type="button" v-if="$store.state.demo.demo == false" :class="['btn rounded mb-0 ml-3 text-oswald btn-nav-white', ((demo_rules.indexOf($route.name) > -1) ? 'd-none' : '')]" @click="start">Probar demo</button-->
            <!-- Demo button end -->

            <!-- Volver mapa button -->
            <!-- Demo volver mapa -->
            <button v-if="$store.state.back_url == 'demomap' && (demo_scenes.indexOf($route.name) > -1)" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="$router.push({name:'demomap'})">
                <font-awesome-icon v-if="$store.state.demo.fsbtn_mini == true" :icon="['fas', 'arrow-left']" />
                <span v-else>Mapa</span>
            </button>
            <!-- Normal volver mapa -->
            <!--button v-if="$store.state.demo.demo == false && (normal_scenes.indexOf($route.name) > -1)" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="$router.push({name:'map'})">
                <font-awesome-icon v-if="$store.state.demo.fsbtn_mini == true" :icon="['fas', 'arrow-left']" />
                <span v-else>Mapa</span>
            </button-->
            <!-- Volver mapa button end -->

            <!-- Volver button -->
            <button v-if="$store.state.demo.demo == true && demo_activities.indexOf($route.name) > -1" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="goBack">Volver</button>
            <button v-if="$store.state.demo.demo == false && normal_activities.indexOf($route.name) > -1" :class="['btn rounded mb-0 ml-3 text-oswald', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue')]" @click="goBack">Volver</button>
            <!-- Volver button end-->

            <!-- Help button -->
            <button :class="['btn text-oswald ml-3', ((blue_rules.indexOf($route.name) > -1) ? 'btn-nav-white':'btn-nav-blue'), 'btn-nav-generic']" @click="showHelpModal">
                <font-awesome-icon :icon="['fas', 'question']" />
            </button>
            <!-- Help button end -->
        </div>
    </div>
</template>

<script>
    import timer from '@/assets/timer.svg';
    import FlipDown from 'vue-flip-down';
    import utils from '../utils';
    import Help from '../views/Help';
    import dotenv from 'dotenv';
    import RegisterUserModal from '../components/panel/RegisterUserModal.vue';
    import RegisterSessionModal from '../components/panel/RegisterSessionModal.vue';
    dotenv.config()
    //popper
    import { createPopper } from '@popperjs/core';
    export default {
        name: 'NavBar',
        components: {
            timer, FlipDown, Help, 'register-user-modal': RegisterUserModal, 'register-session-modal': RegisterSessionModal
        },
        props:{
            leftTime: null
        },
        data: function(){
            return {
                image: '@/assets/logo.png',
                username: "",
                showuserdata: false,
                showmenu: false,
                //Timer (countdown game)
                time: '',
                user_avatar_name: '',
                newAvatar: '',
                is_fullscreen: true,
                finished: false,
                text_btn_fs: "Pantalla completa",
                cc1_rules: [
                    'scene1cc1',
                    'scene1cc2',
                    'scene1cc3',
                    'scene1cc4',
                    'scene1cc5',
                    'scene1activity1cc1',
                    'scene1activity2cc1',
                    'scene1activity3cc1',
                    'scene1activity4cc1',
                    'scene1activity5cc1',
                    'scene1activity6cc1',
                ],
                blue_rules: [
                    'home',
                    'lobby',
                    'end',
                    'countdown',
                    'map',
                    'loading',
                    'session',
                    'login',
                    'demostart',
                    'demoend',
                    'democountdown',
                    'demolocked',
                    'demoscene1',
                    'demoscene2',
                    'demoscene3',
                    'demoscene4',
                    'demoscene5',
                    'demoscene1activity1',
                    'demoscene1activity3',
                    'demoscene1activity4',
                    'demoscene1activity6',
                    'demoscene2activity1',
                    'demoscene2activity2',
                    'demoscene2activity4',
                    'demoscene2activity5',
                    'demoscene2activity6',
                    'demoscene2activity7',
                    'demoscene3activity2',
                    'demoscene3activity3',
                    'demoscene3activity4',
                    'demoscene4activity1',
                    'demoscene4activity2',
                    'demoscene4activity3',
                    'demoscene4activity5',
                    'demoscene5activity1',
                    'demoscene5activity3',
                    'demoscene5activity4',
                    // cc1 (from demo example)
                    'scene1cc1start',
                ],
                white_rules: [
                    'demomap',
                    'map1',
                    'map2',
                    'demoscene1activity2',
                    'demoscene1activity5',
                    'demoscene2activity3',
                    'demoscene3activity1',
                    'demoscene4activity4',
                    'demoscene5activity2',
                    'ranking-id'
                ],
                demo_rules: [
                    'demomap',
                    'map1',
                    'map2',
                    'demoscene1activity2',
                    'demoscene1activity5',
                    'demoscene2activity3',
                    'demoscene3activity1',
                    'demoscene4activity4',
                    'demoscene5activity2'
                ],
                demo_activities: [
                    'demolocked',
                    'demoscene1activity1',
                    'demoscene1activity2',
                    'demoscene1activity3',
                    'demoscene1activity4',
                    'demoscene1activity5',
                    'demoscene1activity6',
                    'demoscene2activity1',
                    'demoscene2activity2',
                    'demoscene2activity3',
                    'demoscene2activity4',
                    'demoscene2activity5',
                    'demoscene2activity6',
                    'demoscene2activity7',
                    'demoscene3activity1',
                    'demoscene3activity2',
                    'demoscene3activity3',
                    'demoscene3activity4',
                    'demoscene4activity1',
                    'demoscene4activity2',
                    'demoscene4activity3',
                    'demoscene4activity4',
                    'demoscene4activity5',
                    'demoscene5activity1',
                    'demoscene5activity2',
                    'demoscene5activity3',
                    'demoscene5activity4'
                ],
                normal_activities: [],
                panel_routes:[
                    'panel',
                    'panel-sessions',
                    'panel-users',
                    'panel-session-id',
                    'panel-user-id',
                    'panel-ranking-id',
                    'panel-activity',
                    'panel-logs'
                ],
                demo_scenes: ['demoscene1', 'demoscene2', 'demoscene3', 'demoscene4', 'demoscene5', 'demoscene6'],
                normal_scenes: []
            };
        },
        created(){
            this.blue_rules = this.blue_rules.concat(this.cc1_rules);
            this.blue_rules = this.blue_rules.concat(this.panel_routes);

            this.$root.$on('gameFinished', () => {
                this.finished = true;
            })
        },
        mounted(){
            $(document).on('fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange', function() {
                if(utils.IsFullScreenCurrently()) { 
                    this.is_fullscreen = false;
                }
                else {
                    this.is_fullscreen = true;
                }
            });

            /*if(vueapp.$store.state.timer != null && vueapp.$store.state.session != null){
                if(vueapp.moment(vueapp.$store.state.timer).valueOf() < new Date().getTime() && vueapp.$store.state.session.game_mode == window.GAME_MODES.SINGLEPLAYER){
                    this.finished = true;
                }
            }*/
        },
        watch: {
            '$store.state.fs_message': function() {  
                this.updateFSTooltip(vueapp.$store.state.fs_message);
            },
            '$store.state.demo.fs_message': function() {  
                this.updateFSTooltip(vueapp.$store.state.demo.fs_message);
            }
        },
        methods: {
            panic : function(){
                ws.send(JSON.stringify({action: RPC.PANIC, params:null}));
            },
            logs : function(){
                router.push({name:"panel-logs"});
            },
            register_user_modal : function(){
                this.$refs.register_user_modal.show_modal();
            },
            register_session_modal : function(){
                this.$refs.register_session_modal.show_modal();
            },
            ranking(){
                router.push({name:"ranking-id", params: {id: vueapp.$store.state.session.id}}).catch((e)=>{console.log(e)});
            },
            logout(){
                ws.send(JSON.stringify({action: RPC.CLIENT_LOGOUT, params: null}))
            },
            start(){
                router.push({name:"demostart"}).catch((e)=>{console.log(e)});
            },
            showHelpModal(){
                this.$refs['modal-help'].show();
            },
            hideHelpModal(){
                this.$refs['modal-help'].hide();
            },
            onFinished: function(){
                console.log("finished")
                if(vueapp.$store.state.session.game_mode == window.GAME_MODES.MULTIPLAYER){
                    vueapp.$store.commit('setGameState', "finished")
                    router.push({name:((vueapp.$store.state.demo.demo === true) ? 'demoend':'end')})
                }else{
                    this.finished = true;
                    //alert("Juego terminado");
                    //vueapp.$store.commit("setTimer", null);
                }
            },
            process: function(){
                console.log("process");
            },
            resetDemo: function(id){
                //this.hideModal(id)
                vueapp.$store.commit("setDemo", false);
                clearInterval(vueapp.$store.state.demo.countdown_interval);
                vueapp.$store.commit("setCountDownInterval", null);
                clearInterval(vueapp.$store.state.demo.timer_interval);
                vueapp.$store.commit("setTimerInterval", null);
                
                console.log("antes-"+vueapp.$store.state.demo.game_state)
                vueapp.$store.commit('resetGlobalState');
                console.log("despues-"+vueapp.$store.state.demo.game_state)
                window.location.href = "/";
            },
            hideModal (id) {
                this.$root.$emit('bv::hide::modal',id)
            },
            goBack(){
                if(typeof vueapp.$store.state.demo.back_url === "string")
                    router.push({name: vueapp.$store.state.demo.back_url}).catch((e)=>{console.log(e)});
                else
                    router.push({name:"map"}).catch((e)=>{console.log(e)});
            },
            toggleFullScreen(){
                var status = utils.toggleFullScreen();
                if(status)
                    this.text_btn_fs = "Salir de pantalla completa"; 
                else       
                    this.text_btn_fs = "Pantalla completa";     

                $('#fsbtn').addClass('no-after');
                vueapp.$store.commit("setFullScreenMessage", false);
            },
            updateFSTooltip(status){
                if(status)
                    $('#fsbtn').removeClass('no-after');
                else
                    $('#fsbtn').addClass('no-after');
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

	/*SVG LOGO STYLES*/
	.colorst0{fill-rule:evenodd;clip-rule:evenodd;fill:#1D1D1C;}
	.colorst1{fill:none;stroke:#DB0032;stroke-miterlimit:10;}
	.colorst2{fill:none;stroke:#DB0032;stroke-miterlimit:3.8637;}
	.colorst3{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#DB0032;stroke-miterlimit:3.8637;}
	.colorst4{fill:#DB0032;}
	.colorst5{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.colorst6{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.colorst7{fill:none;stroke:#FFFFFF;stroke-miterlimit:3.8637;}
	.colorst8{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#FFFFFF;stroke-miterlimit:3.8637;}
	.colorst9{fill:#FFFFFF;}

    .no-after:after{content:none !important;}
    .no-after:before{content:none !important;}

    /* START TOOLTIP STYLES */
    [tooltip] {
        position: relative; /* opinion 1 */
        
        pointer-events:auto;
    }

    /* Applies to all tooltips */
    [tooltip]::before,
    [tooltip]::after {
        text-transform: none; /* opinion 2 */
        font-size: .9em; /* opinion 3 */
        line-height: 1;
        user-select: none;
        position: absolute;
        display: none;
        opacity: 0;
        pointer-events:auto;
    }
    [tooltip]::before {
        content: '';
        border: 5px solid transparent; /* opinion 4 */
        z-index: 1001; /* absurdity 1 */
    }
    [tooltip]::after {
        content: attr(tooltip); /* magic! */
    
        /* most of the rest of this is opinion */
        font-family: Helvetica, sans-serif;
        text-align: center;
        
        /* 
            Let the content set the size of the tooltips 
            but this will also keep them from being obnoxious
            */
        min-width: 3em;
        white-space: nowrap;
        overflow: hidden;
        padding: 1ch 1.5ch;
        border-radius: .3ch;
        box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
        background: #333;
        color: #fff;
        z-index: 1000; /* absurdity 2 */
    }

/* Make the tooltips respond to hover */
[tooltip]::before,
[tooltip]::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after,
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]::before,
[tooltip][flow^="left"]::after,
[tooltip][flow^="right"]::before,
[tooltip][flow^="right"]::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

</style>
