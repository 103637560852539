<template>
    <div class="progress w-100 h-100 p-3">
        <div class="row w-100 h-100 d-flex justify-content-center align-items-center flex-column">
            <div class="lds-dual-ring"></div>
            <h3 class="mt-3">Cargando la información de la sesión de juego</h3>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        mounted: function(){
            console.log("info de la sesion:", (vueapp.$store.state.session!=null))
            console.log("info del usuario:", (vueapp.$store.state.user!=null))
        }
    }
</script>
