<template>
    <Order 
        act_id="demoscene4activity3"
        scene_id="demoscene4"
        title="Esquemas de tratamiento del déficit de hierro"
        intro_text="En este caso utilizaremos el esquema de tratamiento con hierro carboximaltosa intravenoso."
        question="Teniendo en cuenta que Juana pesa 71 kg y que su Hb es de 12,3 g/dL; escoge 3 ítems de la columna de la izquierda y ordénalos de forma adecuada en la columna de la derecha."
        :options="[
            {
                id: 0,
                name: '1000mg de HCM',
                cssclass: 'opcion0',
                val: 1,
            },
            {
                id: 1,
                name: '500mg de HCM',
                cssclass: 'opcion1',
                val: 2,
            },
            {
                id: 2,
                name: '1000mg de HCM',
                cssclass: 'opcion2',
                val: 1,
            },
            {
                id: 3,
                name: '500mg de HCM',
                cssclass: 'opcion3',
                val: 2,
            },
            {
                id: 4,
                name: '500mg de HCM',
                cssclass: 'opcion4',
                val: 2,
            },
            {
                id: 5,
                name: '1000mg de HCM',
                cssclass: 'opcion5',
                val: 1,
            },
        ]" 
        :correct_order="[1,2,2,null,null,null]"
        left_column="col-6 m-0 p-0"
        right_column="col-6 m-0 p-0"
        left_size="col-4"
        right_size="col-8 draglist"
        :table_body="[
            {
                class: 'p-0 m-0 bg-bluelight',
                tds:[
                    {
                        style: 'width: 30%;',
                        content: '<span>Dosis inicial</span>'
                    },
                    {
                        draggable: true,
                        class: 'w-100 h-100 p-0 m-0',
                        rowspan: 6,
                    }
                ]
            },
            {
                class: 'p-0 m-0 bg-bluedark',
                tds:[
                    {
                        style: 'width: 30%;',
                        content: '<span>Dosis a los 7 días</span>'
                    },
                    {
                        content: null
                    }
                ]
            },
            {
                class: 'p-0 m-0 bg-bluelight',
                tds:[
                    {
                        style: 'width: 30%;',
                        content: '<span>Dosis cada 3 meses</span>'
                    },
                    {
                        content: null
                    }
                ]
            },
            {
                class: 'p-0 m-0',
                tds:[
                    {
                        style: 'width: 30%;',
                        content: '<span class=\'invisible\'>a</span>'
                    },
                    {
                        content: null
                    }
                ]
            },
            {
                class: 'p-0 m-0',
                tds:[
                    {
                        style: 'width: 30%;',
                        content: '<span class=\'invisible\'>a</span>'
                    },
                    {
                        content: null
                    }
                ]
            },
            {
                class: 'p-0 m-0',
                tds:[
                    {
                        style: 'width: 30%;',
                        content: '<span class=\'invisible\'>a</span>'
                    },
                    {
                        content: null
                    }
                ]
            },
            
        ]"
        row_style="font-size:17px;"
        incorrect_msg="La respuesta es incorrecta"
        clue_text="La actividad informativa de este escenario te ayudará a ordenar los items."
        :feedback="feedback_content"
        :check_activity_children="check_activity_children">
    </Order>
</template>

<script>
import Vue from 'vue';
import Order from '../../../../components/activitytype/Order';
import FeedbackActivity3 from '../feedbacks/FeedbackActivity3';

export default {
    data: function(){
        return {
            feedback_content: "",
        };
    },
    components: {
        Order
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene4");
        vueapp.$store.commit("setLastURL", "demoscene4activity3");
        setActivityVisited('demoscene4', 'demoscene4activity3');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;

        updateUserSession();
    },
    methods: {
        check_activity_children : function(context){
            //console.log("check_activity_children",context)
            console.log(context.dragmodel)
            var current_dragmodel = [];
            context.dragmodel.forEach((item)=>{
                current_dragmodel.push(item.val);
            })
            if(current_dragmodel[0] == 1 && current_dragmodel[1] == 2 && current_dragmodel[2] == 2){
                context.activity_response = true;
                context.is_correct = true;
                setActivityStatus(context.scene_id, context.act_id, context.$data);
                var act = getActivityStatus(context.scene_id, context.act_id);
                if(act.completed == false)
                    vueapp.$store.commit("setScore", vueapp.$store.state.demo.score + 1);
                setActivityCompleted(context.scene_id, context.act_id, true);
                if(context.scenario_finisher == true){
                    setSceneLocked(context.next_scenario, false);
                    setSceneCompleted(context.next_scenario, true);
                }
                if(context.$store.state.correct != null)
                    context.$store.state.correct.play();
            }else{
                context.activity_response = false;
                context.is_correct = false;
                setTimeout(function(){
                    context.activity_response = null;
                    context.is_correct = null;
                }.bind(this), 3000);
                if(context.$store.state.error != null)
                    context.$store.state.error.play();
            }
        }
    }
}
</script>

<style scoped>
</style>