<template>
    <Radio 
        act_id="demoscene3activity3"
        scene_id="demoscene3"
        title="Criterios para el inicio de tratamiento"
        intro_text="Ya conoces el diagnóstico de la paciente, ahora debes de valorar si es necesario iniciar algún tipo de tratamiento."
        question="¿A partir de qué valores consideraría necesario tratar a la paciente?"
        :options="[
            {
                id: 0,
                name: 'respuesta0',
                text: 'No es necesario iniciar tratamiento porque no existe anemia',
                value: 0,
            },
            {
                id: 1,
                name: 'respuesta1',
                text: 'No es necesario iniciar tratamiento porque no hay descompensación de IC',
                value: 1,
            },
            {
                id: 2,
                name: 'respuesta2',
                text: 'Nivel de Hb <12 g/dl (♀) y Hb<13g/dl(♂)',
                value: 2,
            },
            {
                id: 3,
                name: 'respuesta3',
                text: 'Ferritina <30 ng/ml',
                value: 3,
            },
            {
                id: 4,
                name: 'respuesta4',
                text: 'Ferritina < 100ng/ml o ferritina 100-300 ng/ml + IST<20%',
                value: 4,
            },
            {
                id: 5,
                name: 'respuesta5',
                text: 'Ferritina < 100ng/ml o ferritina 100-300 ng/ml + IST<20% + Hb <12 g/dl',
                value: 5,
            }
        ]" 
        :correct_answer=4
        incorrect_msg="La respuesta es incorrecta"
        clue_text="Piensa en los criterios diagnósticos..."
        :feedback="feedback_content">
    </Radio>
</template>

<script>
import Vue from 'vue';
import Radio from '../../../../components/activitytype/Radio';
import FeedbackActivity3 from '../feedbacks/FeedbackActivity3';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Radio
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene3");
        vueapp.$store.commit("setLastURL", "demoscene3activity3");
        setActivityVisited('demoscene3', 'demoscene3activity3');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity3)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;

        updateUserSession();
    }
}
</script>