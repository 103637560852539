<template>
    <div class="w-100 h-100 animate__animated animate__fadeIn">
        <div class="row w-100 h-100 m-0" v-if="(activity_response==null || activity_response ==false)">
            <div :class="left_size">
                <div class="activity_title intro-title text-rockwell-bold">{{ title }}</div>
                <div class="intro_text intro_text_order text-oswald" v-html="intro_text"></div>
                <p class="intro_text_order order-warning text-center text-information-order mb-3 text-small-order"><em>Arrastra y suelta los elementos de la última columna para ordenar las filas.</em></p>
                <p class="intro_text_order order-warning text-center text-information-order text-small-order"><em>¡Ten cuidado! al arrastrar un elemento a una posición los demás se desplazan. Debes asignarlos en orden.</em></p>

                <div v-if="!clue && clue_text != null" class="d-flex justify-content-center align-items-center flex-column intro_text_order text-rockwell">
                    <button
                        :disabled="(current_date.diff(last_clue_date, 'seconds') < clue_time)" 
                        class="rounded pt-2 pb-2 pr-5 pl-5 clues clue-button" 
                        v-on:click="getClue">
                        ¡PISTA!
                    </button>
                </div>
                <div class="row text-rockwell" v-if="clue && clue_text != null">
                    <div class="rounded p-2 w-100 clues clue-show justify-content-center align-items-center ml-3 intro_text_order text-rockwell">
                        ¡PISTA!
                        <div class="text-oswald" v-html="clue_text"></div>
                    </div>
                </div>
                <p class="ml-5 intro_text_order order-warning text-center text-information-order clue-msg clue-msg-info"  v-if="!clue && (current_date.diff(last_clue_date, 'seconds') < clue_time) && clue_text != null">Hace muy poco que pediste una pista, inténtalo más tarde</p>
            </div>
            <div :class="right_size">
                <div class="row m-0">
                    <h3 class="text-rockwell-bold col-12 question-title mr-0 ml-0 mb-4">{{ question }}</h3>
                    <div class="col">
                        <table class='table'>
                            <thead class='thead-dark'>
                                <tr v-html="table_head"></tr>
                            </thead>
                            <tbody>
                                <tr v-for="tr in table_body" :class="tr.class" :style="tr.style" :rowspan="tr.rowspan" :colspan="tr.colspan">
                                    <td v-if="td.content != null || td.draggable == true" v-for="td in tr.tds" :class="td.class" :style="td.style"  :rowspan="td.rowspan" :colspan="td.colspan">
                                        <div v-if="td.content != null" v-html="td.content"></div>
                                        <div v-if="td.draggable == true" class="h-100">
                                            <draggable v-model="dragmodel" group="orderactivity" @start="drag=true" @end="onEnd" :style="container_style" class="list-group drag-item rounded-0" ghost-class="ghost" v-bind:options='options_bind'>
                                                <li class="list-group-item  w-100" :style="row_style" :class="element.cssclass" v-for="element in dragmodel" :key="element.id"><span class="order-text" v-html="element.name"></span></li>
                                            </draggable>
                                        </div>
                                    </td>
                                </tr>
                                <!--tr v-html="table_body"></tr>
                                <tr>
                                    <td class="table-item-order" v-for="col in table_first_row">
                                        <span v-html="col"></span>
                                    </td>
                                    <td :rowspan='table_rowspan' class="p-0">
                                        <draggable v-model="dragmodel" group="orderactivity" @start="drag=true" @end="onEnd" class="list-group drag-item rounded-0" ghost-class="ghost" v-bind:options='options_bind'>
                                            <li class="list-group-item  w-100" :style="row_style" :class="element.cssclass" v-for="element in dragmodel" :key="element.id"><span class="order-text" v-html="element.name"></span></li>
                                        </draggable>
                                    </td>
                                </tr>                                
                                <tr v-for="row in table_rows">
                                    <td class="table-item-order" :style="row_style" v-for="col in row">
                                        <span v-html="col"></span>
                                    </td>
                                </tr-->
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row m-0 d-flex justify-content-center align-items-center">
                    <button v-if="activity_response == null" class="btn btn-action mt-3 mb-3 rounded check-btn" v-on:click="check_activity">Comprobar</button>
                    <div v-if="activity_response==false" class="incorrectmsg p-2 mt-3 result text-center rounded">
                        {{ incorrect_msg }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activity_response==true" class="w-100 h-100 d-flex justify-content-center flex-column">
            <div class="w-100 h-100" v-html="feedback"></div>
            <div class="w-100 d-flex justify-content-center align-items-center scene-finisher-btn">
                <button v-if="scenario_finisher == true" @click="$router.push({name:'demomap'})" class="btn btn-action mt-3 mb-3 rounded check-btn">Mapa</button>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import ActivityType from './ActivityType'

export default {
    extends: ActivityType,
    props: {
        act_id: 0,
        scene_id: 0,
        options: {
            type: Array,
            default: []
        },
        correct_order: {
            type: Array,
            default: []
        },
        enunciados: {
            type: Array,
            default: null,
            required: false,
        },
        left_size: {
            type: String,
            default: 'col-6'
        },
        right_size: {
            type: String,
            default: 'col-6'
        },
        left_column: {
            type: String,
            default: 'col-6'
        },
        right_column: {
            type: String,
            default: 'col-6'
        },
        table_head:{
            type: String,
            required: false,
        },
        table_body:{
            type: Array,
            required: false,
        },
        table_first_row:{
            type: Array,
            required: false,
        },
        table_rowspan:{
            type: Number,
            required: false,
        },
        table_rows:{
            type: Array,
            required: false,
        },
        scenario_finisher: {
            type: Boolean,
            default: false,
        },
        next_scenario: {
            type: Number,
            default: 0,
        },
        row_style:{
            type: String,
            required: false,
            default: "60px",
        },
        final_activity: {
            type:Boolean,
            default: false,
            required: false,
        },
        check_activity_children: {
            type: Function,
            required: false,
        },
        container_style: {
            type: String,
            required: false,
            default: "",
        }
    },
    data: function() {
        return {
            isCorrect: null,
            dragmodel: [],
            result: '',
            answer: null,
            answer_render: null,
            activity_response: null,
        }
    },
    components: {
        draggable
    },
    mounted: function() {
        console.log(this.table_body)
        if (isEmptyArray(this.dragmodel))
            this.dragmodel = this.options;

        var act = null;
        act = getActivityStatus(this.scene_id, this.act_id);
        console.log(act)
        if(act != null){
            if(act.data != null){
                this.activity_response = act.data.activity_response;
                this.can_clue = act.data.can_clue;
                this.checked_options = act.data.checked_options;
                this.clue = act.data.clue;
                this.current_date = act.data.current_date;
                this.is_correct = act.data.is_correct;
                this.last_clue_date = act.data.last_clue_date;
                this.render = act.data.render;
                if (isEmptyArray(this.dragmodel))
                    this.dragmodel = act.data.options;
            }

            if(this.activity_response==false){
                this.activity_response = null;
                this.is_correct = null;
            }
        }
    },
    computed: {
        options_bind() {
            return {
                group: 'menu',
                animation: 200
            }
        }
    },
    methods: {
        onEnd: function(){
            setActivityStatus(this.scene_id,this.act_id, this.$data);
        },
        check_activity: function() {    
            if(typeof this.check_activity_children !== 'undefined'){    
                this.check_activity_children(this);
                        
                    updateUserSession();
            }else{
                if(compareOrderArrays(this.dragmodel,this.correct_order)){
                    this.activity_response = true;
                    this.is_correct = true;
                    setActivityStatus(this.scene_id, this.act_id, this.$data);
                    var act = getActivityStatus(this.scene_id, this.act_id);
                    if(act.completed == false)
                        vueapp.$store.commit("setScore", ((vueapp.$store.state.demo.demo) ? vueapp.$store.state.demo.score : vueapp.$store.state.score) + 1);
                    setActivityCompleted(this.scene_id, this.act_id, true);
                    if(this.scenario_finisher == true){
                        setSceneLocked(this.next_scenario, false);
                        setSceneCompleted(this.scene_id, true);
                    }
                    if(this.$store.state.correct != null)
                        this.$store.state.correct.play();
                        
                    updateUserSession();
                }else{
                    this.activity_response = false;
                    this.is_correct = false;
                    setTimeout(function(){
                        this.activity_response = null;
                        this.is_correct = null;
                    }.bind(this), 3000);
                    if(this.$store.state.error != null)
                        this.$store.state.error.play();
                }
            }
        }
    }
}
</script>

<style scoped>

.question-title{
    margin-left: 0px !important;
} 

@media (min-width: 768px) { 
    .list-group-item{
        font-size: 12px;
    }
}

@media (min-width: 1200px) { 
    .list-group-item{
        font-size: 14px;
    }
}
 
@media (min-width: 1024px) { 
    .list-group-item{
        font-size: 15px;
        
    }
}
</style>
