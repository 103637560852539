import Vue from 'vue'
import VueRouter from 'vue-router'

// Global views
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Panel from '../views/panel/Panel.vue'
import Sessions from '../views/panel/Sessions.vue'
import Session from '../views/panel/Session.vue'
import Activity from '../views/panel/Activity.vue'
import Users from '../views/panel/Users.vue'
import User from '../views/panel/User.vue'
import Logs from '../views/panel/Logs.vue'
import PanelLoading from '../views/panel/Loading.vue'
import Game from '../components/Game.vue'
import Loading from '../views/Loading.vue'
import Lobby from '../views/Lobby.vue'
import CountDown from '../views/CountDown.vue'
import GameMap from '../views/Map.vue'
import End from '../views/End.vue'
import Ranking from '../views/panel/Ranking.vue'
import RankingUser from '../views/Ranking.vue'

// Demo
import DemoStart from '../views/demo/Start.vue'
import DemoCountDown from '../views/demo/CountDown.vue'
import DemoMap from '../views/demo/Map.vue'
import DemoLocked from '../views/demo/Locked.vue'
import DemoEnd from '../views/demo/End.vue'

//Scenes for the demo
import DemoScene1 from '../views/demo/scene1/Scene1.vue'
import DemoScene2 from '../views/demo/scene2/Scene2.vue'
import DemoScene3 from '../views/demo/scene3/Scene3.vue'
import DemoScene4 from '../views/demo/scene4/Scene4.vue'
import DemoScene5 from '../views/demo/scene5/Scene5.vue'

// Activities for the demo
// Demo Scene 1
import DemoScene1Activity1 from '../views/demo/scene1/activities/Activity1.vue';
import DemoScene1Activity2 from '../views/demo/scene1/activities/Activity2.vue';
import DemoScene1Activity3Checks from '../views/demo/scene1/activities/Activity3Checks.vue';
import DemoScene1Activity4Checks from '../views/demo/scene1/activities/Activity4Checks.vue';
import DemoScene1Activity5 from '../views/demo/scene1/activities/Activity5.vue';
import DemoScene1Activity6Radio from '../views/demo/scene1/activities/Activity6Radio.vue';
// Demo Scene 2
import DemoScene2Activity1 from '../views/demo/scene2/activities/Activity1.vue';
import DemoScene2Activity2Checks from '../views/demo/scene2/activities/Activity2Checks.vue';
import DemoScene2Activity3 from '../views/demo/scene2/activities/Activity3.vue';
import DemoScene2Activity4Checks from '../views/demo/scene2/activities/Activity4Checks.vue';
import DemoScene2Activity5 from '../views/demo/scene2/activities/Activity5.vue';
//import DemoScene2Activity6Radio from '../views/demo/scene2/activities/Activity6Radio.vue';
import DemoScene2Activity6Checks from '../views/demo/scene2/activities/Activity6Checks.vue';
import DemoScene2Activity7SingleText from '../views/demo/scene2/activities/Activity7SingleText.vue';
// Demo Scene 3
import DemoScene3Activity1 from '../views/demo/scene3/activities/Activity1.vue';
import DemoScene3Activity2Radio from '../views/demo/scene3/activities/Activity2Radio.vue';
import DemoScene3Activity3Radio from '../views/demo/scene3/activities/Activity3Radio.vue';
import DemoScene3Activity4Radio from '../views/demo/scene3/activities/Activity4Radio.vue';
//import Activity3_4Checks from '../views/demo/scene3/activities/Activity3_4Checks.vue';
// Demo Scene 4
import DemoScene4Activity1Checks from '../views/demo/scene4/activities/Activity1Checks.vue';
import DemoScene4Activity2Order from '../views/demo/scene4/activities/Activity2Order.vue';
import DemoScene4Activity3Order from '../views/demo/scene4/activities/Activity3Order.vue';
import DemoScene4Activity4 from '../views/demo/scene4/activities/Activity4.vue';
import DemoScene4Activity5Radio from '../views/demo/scene4/activities/Activity5Radio.vue';
// Demo Scene 5
import DemoScene5Activity1QA from '../views/demo/scene5/activities/Activity1QA.vue';
import DemoScene5Activity2 from '../views/demo/scene5/activities/Activity2.vue';
import DemoScene5Activity3CrossWord from '../views/demo/scene5/activities/Activity3CrossWord.vue';
import DemoScene5Activity4Text from '../views/demo/scene5/activities/Activity4Text.vue';

// Clinic Case 1
//import Map1 from '../views/caso1/Map.vue'
import CC1Scene1 from '../views/caso1/scene1/Scene1.vue'
import CC1Scene2 from '../views/caso1/scene2/Scene2.vue'
import CC1Scene3 from '../views/caso1/scene3/Scene3.vue'
import CC1Scene4 from '../views/caso1/scene4/Scene4.vue'
import CC1Scene5 from '../views/caso1/scene5/Scene5.vue'

import CC1Scene1Act1 from '../views/caso1/scene1/activities/Activity1.vue';
import CC1Scene1Act2 from '../views/caso1/scene1/activities/Activity2.vue';
import CC1Scene1Act3Checks from '../views/caso1/scene1/activities/Activity3Checks.vue';
import CC1Scene1Act4Checks from '../views/caso1/scene1/activities/Activity4Checks.vue';
import CC1Scene1Act5 from '../views/caso1/scene1/activities/Activity5.vue';
import CC1Scene1Act6Radio from '../views/caso1/scene1/activities/Activity6Radio.vue';
//import CC1Map from '../views/caso1/Map.vue'
//import CC1CountDown from '../views/caso1/CountDown.vue'

// Clinic Case 2
//import Map2 from '../views/caso2/Map.vue'
import CC2Scene1 from '../views/caso2/scene1/Scene1.vue'
import CC2Scene2 from '../views/caso2/scene2/Scene2.vue'
import CC2Scene3 from '../views/caso2/scene3/Scene3.vue'
import CC2Scene4 from '../views/caso2/scene4/Scene4.vue'
import CC2Scene5 from '../views/caso2/scene5/Scene5.vue'
//import CC2Scene1 from '../views/caso2/scene1/Scene1.vue'
// Activities testing
//import OrderActivityScene2 from '../components/scenes/activities/OrderActivityScene2.vue'
//import MinChecksActivity from '../components/scenes/activities/MinChecksActivity.vue'
//import TextEntryActivity from '../components/scenes/activities/TextEntryActivity.vue'
//import OrderActivity from '../components/scenes/activities/OrderActivity.vue'
//import ChecksActivity from '../components/scenes/activities/ChecksActivity.vue'
//import QandAActivity from '../components/scenes/activities/QandAActivity.vue'
//import RadioActivity from '../components/scenes/activities/RadioActivity.vue'

//Score Board
//import ScoreBoard from '../components/scoreboard/ScoreBoard.vue'

// Test
//import Test from '../views/Test.vue'

import dotenv from 'dotenv'
dotenv.config()

Vue.use(VueRouter)
const game_id = '/game_'+process.env.VUE_APP_GAME_ID;
const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            isPublic: true,
        }
    },
    {
        path: '/login/:session_id?',
        name: 'login',
        component: Login,
        meta: {
            isPublic: true,
        }
    },
    {
        path: '/ranking/:id',
        name: 'ranking-id',
        meta: {
            isPublic: true,
        },
        component: RankingUser
    },
    {
        path: '/loading',
        name: 'loading',
        component: Loading,
        meta: {
            isPublic: true,
        }
    },
    {
        path: '/lobby',
        name: 'lobby',
        component: Lobby,
        meta: {
            isPublic: true,
        },
    },
    {
        path: '/end',
        name: 'end',
        component: End,
        meta: {
            isPublic: true,
        },
    },
    {
        path: '/countdown',
        name: 'countdown',
        component: CountDown,
        meta: {
            isPublic: true,
        },
    },
    {
        path: '/map',
        name: 'map',
        component: GameMap,
        meta: {
            isPublic: true,
        },
        children:[
            /*{
                path: '1',
                name: 'map1',
                meta: {
                    isPublic: true,
                },
                component: Map1
            },
            {
                path: '2',
                name: 'map2',
                meta: {
                    isPublic: true,
                },
                component: Map2
            }*/
        ]
    },
    {
        path: '/panel',
        name: 'panel',
        component: Panel,        
        children: [
            {
                path: 'panel-loading',
                name: 'panel-loading',
                meta: {
                    isPublic: true,
                },
                component: PanelLoading
            },
            {
                path: '/panel/sessions',
                name: 'panel-sessions',
                meta: {
                    isPublic: true,
                },
                component: Sessions
            },
            {
                path: '/panel/activity/:id',
                name: 'panel-activity',
                meta: {
                    isPublic: true,
                },
                component: Activity
            },
            {
                path: '/panel/session/:id',
                name: 'panel-session-id',
                meta: {
                    isPublic: true,
                },
                component: Session
            },
            {
                path: '/ranking/:id',
                name: 'panel-ranking-id',
                meta: {
                    isPublic: true,
                },
                component: Ranking
            },
            {
                path: '/panel/users',
                name: 'panel-users',
                meta: {
                    isPublic: true,
                },
                component: Users
            },
            {
                path: '/panel/user/:id',
                name: 'panel-user-id',
                meta: {
                    isPublic: true,
                },
                component: User
            },
            {
                path: '/panel/logs',
                name: 'panel-logs',
                meta: {
                    isPublic: true,
                },
                component: Logs
            }
        ],
        meta: {
            isPublic: true,
        }
    },
    // Demo routes
    {
        path: '/demo',
        name: 'demo',
        component: Game,
        meta: {
            isPublic: true,
        },
        children: [
            {
                path: 'start',
                name: 'demostart',
                meta: {
                    isPublic: true,
                },
                component: DemoStart
            },
            {
                path: 'countdown',
                name: 'democountdown',
                meta: {
                    isPublic: true,
                },
                component: DemoCountDown
            },
            {
                path: 'map',
                name: 'demomap',
                component: DemoMap,
                meta: {
                    isPublic: true,
                }
            },
            {
                path: 'locked',
                name: 'demolocked',
                component: DemoLocked,
                meta: {
                    isPublic: true,
                },
                props: {
                    default: true,
                }
            },
            {
                path: 'end',
                name: 'demoend',
                component: DemoEnd
            },
            
            //Scenes
            {
                path: 'scene1',
                name: 'demoscene1',
                component: DemoScene1
            },
            {
                path: 'scene2',
                name: 'demoscene2',
                component: DemoScene2
            },
            {
                path: 'scene3',
                name: 'demoscene3',
                component: DemoScene3
            },
            {
                path: 'scene4',
                name: 'demoscene4',
                component: DemoScene4
            },
            {
                path: 'scene5',
                name: 'demoscene5',
                component: DemoScene5
            },
            // Scene1
            {
                path: 'scene1/act_01',
                name: 'demoscene1activity1',
                props: true,
                component: DemoScene1Activity1,
            },
            {
                path: 'scene1/act_02',
                name: 'demoscene1activity2',
                props: true,
                component: DemoScene1Activity2
            },
            {
                path: 'scene1/act_03',
                name: 'demoscene1activity3',
                props: true,
                component: DemoScene1Activity3Checks,
            },
            {
                path: 'scene1/act_04',
                name: 'demoscene1activity4',
                props: true,
                component: DemoScene1Activity4Checks,
            },
            {
                path: 'scene1/act_05',
                name: 'demoscene1activity5',
                props: true,
                component: DemoScene1Activity5,
            },
            {
                path: 'scene1/act_06',
                name: 'demoscene1activity6',
                props: true,
                component: DemoScene1Activity6Radio,
            },
            // Scene2
            {
                path: 'scene2/act_07',
                name: 'demoscene2activity1',
                props: true,
                component: DemoScene2Activity1,
            },
            {
                path: 'scene2/act_08',
                name: 'demoscene2activity2',
                props: true,
                component: DemoScene2Activity2Checks,
            },
            {
                path: 'scene2/act_09',
                name: 'demoscene2activity3',
                props: true,
                component: DemoScene2Activity3,
            },
            {
                path: 'scene2/act_10',
                name: 'demoscene2activity4',
                props: true,
                component: DemoScene2Activity4Checks,
            },
            {
                path: 'scene2/act_11',
                name: 'demoscene2activity5',
                props: true,
                component: DemoScene2Activity5,
            },
            /*{
                path: 'scene2/act_12',
                name: 'demoscene2activity6',
                props: true,
                component: DemoScene2Activity6Radio,
            },*/
            {
                path: 'scene2/act_12',
                name: 'demoscene2activity6',
                props: true,
                component: DemoScene2Activity6Checks
            },
            {
                path: 'scene2/act_13',
                name: 'demoscene2activity7',
                props: true,
                component: DemoScene2Activity7SingleText,
            },
            //Scene3
            {
                path: 'scene3/act_14',
                name: 'demoscene3activity1',
                props: true,
                component: DemoScene3Activity1,
            },
            {
                path: 'scene3/act_15',
                name: 'demoscene3activity2',
                props: true,
                component: DemoScene3Activity2Radio,
            },
            {
                path: 'scene3/act_16',
                name: 'demoscene3activity3',
                props: true,
                component: DemoScene3Activity3Radio,
            },
            {
                path: 'scene3/act_17',
                name: 'demoscene3activity4',
                props: true,
                component: DemoScene3Activity4Radio,
            },
            // Scene 4
            {
                path: 'scene4/act_18',
                name: 'demoscene4activity1',
                props: true,
                component: DemoScene4Activity1Checks
            },
            {
                path: 'scene4/act_19',
                name: 'demoscene4activity2',
                props: true,
                component: DemoScene4Activity2Order
            },
            {
                path: 'scene4/act_20',
                name: 'demoscene4activity3',
                props: true,
                component: DemoScene4Activity3Order
            },
            {
                path: 'scene4/act_21',
                name: 'demoscene4activity4',
                props: true,
                component: DemoScene4Activity4
            },
            {
                path: 'scene4/act_22',
                name: 'demoscene4activity5',
                props: true,
                component: DemoScene4Activity5Radio
            },
            //Scene 5
            {
                path: 'scene5/act_23',
                name: 'demoscene5activity1',
                props: true,
                component: DemoScene5Activity1QA
            },
            {
                path: 'scene5/act_24',
                name: 'demoscene5activity2',
                props: true,
                component: DemoScene5Activity2
            },
            {
                path: 'scene5/act_25',
                name: 'demoscene5activity3',
                props: true,
                component: DemoScene5Activity3CrossWord
            },
            {
                path: 'scene5/act_26',
                name: 'demoscene5activity4',
                props: true,
                component: DemoScene5Activity4Text
            }
        ]
    },
    // Clinical case 1
    {
        path: '/cc1',
        name: 'cc1',
        component: Game,
        meta: {
            isPublic: true,
        },
        children: [
            //Scenes
            {
                path: 'start',
                name: 'scene1cc1start',
                component: DemoStart
            },
            {
                path: 'scene1',
                name: 'scene1cc1',
                component: CC1Scene1
            },
            {
                path: 'scene2',
                name: 'scene2cc1',
                component: CC1Scene2
            },
            {
                path: 'scene3',
                name: 'scene3cc1',
                component: CC1Scene3
            },
            {
                path: 'scene4',
                name: 'scene4cc1',
                component: CC1Scene4
            },
            {
                path: 'scene5',
                name: 'scene5cc1',
                component: CC1Scene5
            },
            // Activities
            {
                path: 'act1',
                name: 'scene1activity1cc1',
                component: CC1Scene1Act1
            },
            {
                path: 'act2',
                name: 'scene1activity2cc1',
                component: CC1Scene1Act2
            },
            {
                path: 'act3',
                name: 'scene1activity3cc1',
                component: CC1Scene1Act3Checks
            },
            {
                path: 'act4',
                name: 'scene1activity4cc1',
                component: CC1Scene1Act4Checks
            },
            {
                path: 'act5',
                name: 'scene1activity5cc1',
                component: CC1Scene1Act5
            },
            {
                path: 'act6',
                name: 'scene1activity6cc1',
                component: CC1Scene1Act6Radio
            },
        ]
    },
    // Clinical case 2
    {
        path: '/cc2',
        name: 'cc2',
        component: Game,
        meta: {
            isPublic: true,
        },
        children: [
            //Scenes
            {
                path: 'scene1',
                name: 'scene1cc2',
                component: CC2Scene1
            },
            {
                path: 'scene2',
                name: 'scene2cc2',
                component: CC2Scene2
            },
            {
                path: 'scene3',
                name: 'scene3cc2',
                component: CC2Scene3
            },
            {
                path: 'scene4',
                name: 'scene4cc2',
                component: CC2Scene4
            },
            {
                path: 'scene5',
                name: 'scene5cc2',
                component: CC2Scene5
            },
        ]
    },
]

window['routes'] = routes;

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    next();
    console.log(to)
    if(typeof vueapp === 'undefined')
        return;
    if(vueapp.$store.state.user != null && typeof vueapp.$store.state.user !== 'undefined'){
        if(to.name != "login")
            vueapp.$store.state.last_url = to.name;
        if(to.params.id != null && typeof to.params.id !== 'undefined')
            vueapp.$store.state.last_url = to.name+':'+to.params.id;
            
        console.log("Updating last_url to:"+vueapp.$store.state.last_url)

        updateUserSession();
    }
})

export default router