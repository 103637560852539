module.exports = {
    CLIENT_JOIN : 0,
    CLIENT_LOGIN : 1,
    CLIENT_REGISTER : 2,
    CLIENT_SESSION_CHECK : 3,
    CLIENT_PING : 4,
    CLIENT_TOAST : 5,
    CLIENT_SESSION_EXPIRE : 6,
    GET_SESSIONS : 7,
    GET_USERS: 8,
    GET_SESSION : 9,
    GET_USER: 10,
    REGISTER_SESSION: 11,
    CLIENT_LOGOUT: 12,
    REMOVE_SESSION: 13,
    REGISTER_USER: 14,
    SET_USER_STATE: 15,
    CLIENT_CONNECT: 16,
    CLIENT_DISCONNECT: 17,
    SESSION_UPDATE_STATUS: 18,
    UPDATE_USER_SESSION: 19,
    GET_USER_SESSION: 20,
    GET_RANKING: 21,
    UPDATE_RANKING: 22,
    REMOVE_USER: 23,
    REMOVE_USER_SESSION: 24,
    UPDATE_USER: 25,
    GET_SESSION_USERS: 26,
    CLEAN_SESSION: 27,
    CLEAN_USER_SESSION: 28,
    GET_USERS_SESSIONS: 29,
    PANIC: 30,
    LOG: 31,
    LOG_APACHE: 32
};