<template>
    <div id="map" class="row h-100 w-100 m-0 p-3">

        <b-modal size="xl" id="modal-help" ref="modal-help" centered hide-footer title-html="<span class='text-white text-rockwell'>Ayuda</span>">
            <help></help>
        </b-modal>
        <div class="h-100 w-100 d-flex justify-content-center align-items-center" id="map-container" ref="map-container">
            <mapasvg id="mapsvg" ref="mapsvg"/>
        </div>
    </div>
</template>

<script>
    import mapasvg from '@/assets/demo/mapa.svg'
    import Help from '../Help'
    export default {
        name: 'Map',
        components: {
            mapasvg, Help
        },
        mounted(){
            vueapp.$store.state.session.status = window.STATUS.STARTED;
            vueapp.$store.state.game_state = window.STATUS.STARTED;
            if(vueapp.$store.state.game_state !== window.STATUS.STARTED && vueapp.$store.state.game_state !== window.STATUS.STARTING){
                vueapp.$store.commit("setTimer", null);
                router.push({name:"lobby"}).catch((e)=>{console.log(e)});
            }
            // Update user's back url.
            vueapp.$store.state.back_url =  null;
            //Get the SVG scenes.
            var scenes = $('[data-class="grupo"]').toArray();

            if(vueapp.$store.state.scenes == null || typeof vueapp.$store.state.scenes === 'undefined' || Object.keys(vueapp.$store.state.scenes).length == 0){
                var game_scenes = {};
                //Lock each scene.
                for(var i = 0; i < scenes.length; i++){
                    //Configure each scene and push scene to the array of scenes.
                    game_scenes[scenes[i].id] = {
                        scene: scenes[i],
                        activities: {},
                        locked: (i == 0) ? false : true,
                        completed: false,
                    };                  
                }
                //Save the scenes information into vuex (localStorage).
                vueapp.$store.commit("setScenes", game_scenes);
            }else{
                var i = 0;
                for (var prop in vueapp.$store.state.scenes) {
                    vueapp.$store.state.scenes[prop].scene = scenes[i];
                    i++;
                }
            }
            

            if(Object.keys(vueapp.$store.state.scenes).length > 0){
                //Loop each scene.
                var i = 0;
                for (var prop in vueapp.$store.state.scenes) {
                    if(vueapp.$store.state.scenes[prop].locked == false){
                        //if is not locked then we allow the click event and hide the scene background and the locker.
                        $(vueapp.$store.state.scenes[prop].scene).click(function(){
                            router.push({name:this.id});
                        }).attr('fill', 'rgba(0,0,0,0)');
                        $("#locker"+(i+1)).click(function(scene){
                            router.push({name:scene.id});
                        }.bind(this, vueapp.$store.state.scenes[prop].scene)).attr('fill', 'rgba(0,0,0,0)');
                    }
                    if(vueapp.$store.state.scenes[prop].completed == true){
                        $("#tick"+prop).click(function(scene){
                            router.push({name:scene.id});
                        }.bind(this, vueapp.$store.state.scenes[prop].scene)).removeClass('invisible');                        
                    }
                    i++;
                }
            }

            if($('.helpbtn')){
                $('.helpbtn').click(function(){
                    this.showHelpModal()
                }.bind(this));
            }

            updateUserSession();
        },
        methods: {
            showHelpModal(){
                this.$refs['modal-help'].show();
            },
            hideHelpModal(){
                this.$refs['modal-help'].hide();
            }
        },
    }
</script>

<style scoped>
    #map{
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
        animation: fadein 2s;
    }
    #map-container{
        position: relative;
    }
    #mapsvg{
        position: absolute;
        max-width: 100%;
        max-height: 100%;
    }
    
</style>
