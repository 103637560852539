<template>
    <div class="row h-100 w-100 m-0">
        <div class="col-12" v-if="$store.state.users_sessions != null">
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel'})">Panel</a></li>
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel-sessions'})">Sesiones</a></li>
                    <li class="breadcrumb-item" v-if="session != null"><a href="#" @click="$router.push({name:'panel-session-id', params: $route.params.id})" >{{ session.name }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Actividad</li>
                </ol>
            </nav>
            <div class="row mt-3">
                <div class="col-lg-12">
                    <h5 class="p-2 bg-dark text-white w-100 d-flex justify-content-between align-items-center font-weight-bold">
                        Actividad de los usuarios
                        <!--button class="btn btn-sm btn-nav-blue" @click="show_new_session_user=true;"><font-awesome-icon :icon="['fas', 'plus']" /></button-->
                        <button class="btn btn-sm btn-nav-blue"><font-awesome-icon :icon="['fa', 'users']" /></button>
                    </h5>
                    <table class="table table-striped">
                        <thead class="escaperoom-main-bg text-white">
                            <tr>
                                <td class="font-weight-bold">USUARIO</td>
                                <td class="font-weight-bold">FECHA DE INICIO</td>
                                <td class="font-weight-bold">FECHA DE FIN</td>
                                <td class="font-weight-bold">ACTIVIDAD</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user_activity in $store.state.users_sessions.users" :key="user_activity.id">
                                <td>{{ user_activity.username }} </td>
                                <td v-if="user_activity.users_sessions.data != null">{{ ( moment(user_activity.users_sessions.data.startedAt).isValid()) ? moment(user_activity.users_sessions.data.startedAt).format("DD/MM/YYYY  HH:mm:ss") : '-' }} </td>
                                <td v-if="user_activity.users_sessions.data != null">{{ ( moment(user_activity.users_sessions.data.finishedAt).isValid()) ? moment(user_activity.users_sessions.data.finishedAt).format("DD/MM/YYYY  HH:mm:ss") : '-' }} </td>
                                <td v-if="user_activity.users_sessions.data != null">
                                    <!--{{ user_activity.users_sessions.data }}-->                                                                        
                                    <button class="btn btn-primary" @click="show_collapse(user_activity.id)" :id="'btn_'+ user_activity.id" type="button" data-toggle="collapse" :data-target="'#collapseExample'+ user_activity.id" aria-expanded="false" :aria-controls="'collapseExample'+ user_activity.id">
                                        Ver actividad
                                    </button>                                    
                                    <div class="collapse" :id="'collapseExample'+ user_activity.id">
                                        <ul  v-for="(scene_data, scene_name) in user_activity.users_sessions.data.scenes">
                                            <li>Escenario: <b>{{ scene_name }}</b> <!--{{ scene_data }}-->
                                                <ul  v-for="(activity_data, activity_name) in scene_data.activities">
                                                    <li><b>{{ activity_name }}</b> <!--{{ activity_data }}-->
                                                        <ul>
                                                            <li>Visitada:
                                                                <font-awesome-icon v-if="activity_data.visited == false" class="text-danger" :icon="['fa', 'times']" />
                                                                <font-awesome-icon v-if="activity_data.visited == true" class="text-success" :icon="['fa', 'check']" />
                                                            </li>
                                                            <li>Completada:
                                                                <font-awesome-icon v-if="activity_data.completed == false" class="text-danger" :icon="['fa', 'times']" />
                                                                <font-awesome-icon v-if="activity_data.completed == true" class="text-success" :icon="['fa', 'check']" />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td v-if="user_activity.users_sessions.data == null" colspan="3" class="text-center">Sin datos sobre la sesión de juego.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RPC from '../../../../shared/RPC'
    export default {
        name: 'Activity',
        data : function(){
            return {
                session: null,
            };
        },
        mounted: function(){
            ws.send(JSON.stringify({action: RPC.GET_USERS_SESSIONS, params: this.$route.params.id}));

            var collapses = $('.collapse').toArray();
            console.log(collapses)
        },
        watch : {
            '$store.state.users_sessions' : function(){
                console.log(vueapp.$store.state.users_sessions)
                this.session = vueapp.$store.state.users_sessions;
            }
        },
        methods:{
            show_collapse : function(id){
                $('#collapseExample'+id).show();
                $('#btn_'+id).hide();
            }
        }
    }
</script>

<style scoped>
    
</style>
