var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity6checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"demoscene2activity6","scene_id":"demoscene2","title":"La importancia del déficit de hierro como comorbilidad en la IC","intro_text":"El déficit de hierro y la anemia son frecuentes en la IC. El déficit de hierro en el paciente con IC se asocia a una menor calidad de vida y un peor pronóstico de la enfermedad con mayor riesgo de hospitalizaciones, reingreso y morbimortalidad.","question":"¿Qué parámetros considera necesario solicitar en la analítica para comprobar si existe déficit de hierro?","options":[
            {
                text: 'Hemograma',
                value: 0,
            },
            {
                text: 'Ferritina',
                value: 1,
            },
            {
                text: 'Sideremia',
                value: 2,
            },
            {
                text: 'Transferrina',
                value: 3,
            },
            {
                text: 'Índice de Saturación de Transferrina (IST)',
                value: 4,
            }
        ],"correct_answer":[
            1, 4
        ],"clue_text":"Se pregunta por diagnóstico de déficit de hierro, no anemia","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }