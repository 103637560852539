<template>
    <div class="activity1 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row w-100 h-100 m-0">
            <div class="col-xl-5 text-rockwell fb-color fb-text-size d-flex justify-content-center align-items-center">
                <p class="mt-5 ml-5">Para realizar un diagnóstico de descompensación de ICC o insuficiencia cardiaca aguda (ICA) es necesario realizar una historia clínica minuciosa con anamnesis y exploración física para evaluar los síntomas y signos de descompensación y buscar posibles desencadenantes de la misma. En la tabla se resumen los principales hallazgos en la anamnesis y exploración física de Juana:</p>
            </div>
            <div class="col-xl-7 d-flex justify-content-center align-items-center">
                <table class="activity-table m-5">
                    <thead>
                        <tr class="text-rockwell-bold">
                            <td>ANAMNESIS</td>
                            <td>EXPLORACIÓN FÍSICA</td>
                        </tr>
                    </thead>
                    <tbody class="text-rockwell-bold">
                        <tr>
                            <td>
                                <ul class="mt-3 mb-3 mr-3">
                                    <li>Desde hace 2 semanas presenta aumento de su disnea habitual hasta hacerse de mínimos esfuerzos (clase funcional III de la NYHA).</li>
                                    <li>No ortopnea ni disnea paroxística nocturna.</li>
                                    <li>Aumento de peso (le cuesta abrocharse el pantalón).</li>
                                    <li>Edemas en tobillos desde hace 2 semanas.</li>
                                    <li>No dolor torácico, no palpitaciones, no tos, no fiebre.</li>
                                    <li>Buena adherencia al tratamiento.</li>
                                </ul>
                            </td>
                            <td>
                                <ul class="mt-3 mb-3 mr-3">
                                    <li>Constantes: TA 135/78mmHg, FC 75lpm, SatO2 96%, Tª 36,2º, G 112</li>
                                    <li>Peso: 71 kg (previo 68kg)</li>
                                    <li>Palidez cutánea, normohidratada, eupneica en reposo.</li>
                                    <li>No ingurgitación yugular</li>
                                    <li>ACP: arrítmica, sin soplos. Murmullo vesicular conservado. Leves crepitantes en ambas bases pulmonares.</li>
                                    <li>Abdomen: blando, depresible, no masas ni megalias</li>
                                    <li>Extremidades inferiores: edema con fóvea hasta tobillos</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!--img id="esc_1_act_1" src="@/assets/demo/activities/esc2_act1.png" alt="esc_1_act_1" rel="preload"-->
            </div>
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn btn-action mt-3 mb-3 rounded check-btn mr-5" onclick="router.push({name:vueapp.$store.state.back_url});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_07",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene2");
        vueapp.$store.commit("setLastURL", "demoscene2activity1");
        setActivityVisited('demoscene2', 'demoscene2activity1');
        var act = getActivityStatus("demoscene2", 'demoscene2activity1');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('demoscene2', 'demoscene2activity1', true);

        updateUserSession();
    }
}
</script>

<style scoped>
    #esc_1_act_1{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .activity-table thead{
        font-size: 21px;
    }

    .activity-table tbody{        
        font-size: 18px;
    }

    @media (max-width: 1500px) {
        .activity-table thead{
            font-size: 21px;
        }

        .activity-table tbody{        
            font-size: 18px;
        }
    }

    @media (min-width: 1281px) {
        .activity-table thead{
            font-size: 19px;
        }

        .activity-table tbody{        
            font-size: 15px;
        }
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
        .activity-table thead{
            font-size: 21px;
        }

        .activity-table tbody{        
            font-size: 17px;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        .activity-table thead{
            font-size: 21px;
        }

        .activity-table tbody{        
            font-size: 17px;
        }
    }

    @media (min-width: 868px) and (max-width: 1024px) {
        .activity-table thead{
            font-size: 19px;
        }

        .activity-table tbody{        
            font-size: 15px;
        }
    }

    @media (min-width: 481px) and (max-width: 867px) {
        .activity-table thead{
            font-size: 19px;
        }

        .activity-table tbody{        
            font-size: 15px;
        }
    }
</style>