<template>
    <div class="row h-100 w-100 m-0 d-flex justify-content-center align-items-center position-relative">
        <img id="pantalla_inicio" class="animate__animated animate__zoomIn animate__faster" src="@/assets/pantalla_inicio.png" alt="pantalla_inicio">
        <div id="countdown" class="w-100 h-100 d-flex justify-content-center align-items-center">
            <span>{{ $store.state.countdown }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'CountDown',
        mounted : function(){
            if(vueapp.$store.state.beep){    
                if(typeof vueapp.$store.state.beep.pause !== 'undefined')     
                    vueapp.$store.state.beep.pause();   
                if(typeof vueapp.$store.state.beep.play !== 'undefined')  
                    vueapp.$store.state.beep.play(); 
            }
            startCountDown();
        }
    }
</script>

<style scoped>
    #pantalla_inicio{
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        margin: 30px;
        opacity: 0.2;
    }
    #countdown{
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 200px;
        margin-top: 40px;
        z-index: 9999;
    }
</style>
