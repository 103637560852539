<template>
    <div class="activity2 w-100 h-100 d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
        <div class="row activity-container w-100 h-100 d-flex justify-content-center align-items-center">
            <table class="activity-table m-5">
                <thead>
                    <tr class="text-rockwell-bold">
                        <td>EXPLORACIÓN FÍSICA</td>
                    </tr>
                </thead>
                <tbody class="text-rockwell-bold">
                    <tr>
                        <td>
                            <ul class="mt-3 mb-3 mr-3">
                                <li>Constantes: TA 130/74mmHg, FC 74lpm, SatO2 96%, Tª 36,3º, G 105.</li>
                                <li>Peso: 68,5 kg (antes del tratamiento con hierro iv 71 kg).</li>
                                <li>Normocoloreada, normohidratada, eupneica en reposo.</li>
                                <li>No ingurgitación yugular.</li>
                                <li>ACP: arrítmica, sin soplos. Murmullo vesicular conservado. Sin ruidos añadidos.</li>
                                <li>Abdomen: blando, depresible, no masas ni megalias.</li>
                                <li>Extremidades inferiores: leve edema en dorso de pies.</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="w-100 d-flex justify-content-end align-items-center feeback-back-btn mr-5">
                <button class="btn rounded mb-4 ml-3 text-oswald btn-nav-white mr-5" onclick="router.push({name:vueapp.$store.state.back_url});">Volver</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "act_07",
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene5");
        vueapp.$store.commit("setLastURL", "demoscene5activity2");
        setActivityVisited('demoscene5', 'demoscene5activity2');
        var act = getActivityStatus("demoscene5", 'demoscene5activity2');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('demoscene5', 'demoscene5activity2', true);

        updateUserSession();
    }
}
</script>

<style scoped>
    #esc_1_act_1{
        width: auto;
        height: auto;
    }
</style>