var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CrossWord',{attrs:{"act_id":"demoscene5activity3","scene_id":"demoscene5","title":"Las definiciones de cada una de las palabras del crucigrama ya las deberías de saber. La palabra que obtengas al resolver el crucigrama es una de las palabras del enunciado final.","intro_text":"","question":"","left_size":"col-md-5","right_size":"col-md-7","feedback":_vm.feedback_content,"cols":11,"rows":6,"words":[
        {
            number : 1, 
            direction : 'across',
            row: 1,
            column: 1+10,
            clue: '',
            answer : 'adherencia',
            class: ''
        },
        {
            number : 2, 
            direction : 'across',
            row: 2,
            column: 2+10,
            clue: '',
            answer : 'disnea',
            class: ''
        },
        {
            number : 3, 
            direction : 'across',
            row: 3,
            column: 3+10,
            clue: '',
            answer : 'ecg',
            class: ''
        },
        {
            number : 4, 
            direction : 'across',
            row: 4,
            column: 1+10,
            clue: '',
            answer : 'ferritina',
            class: ''
        },
        {
            number : 5, 
            direction : 'across',
            row: 5,
            column: 10,
            clue: '',
            answer : 'intravenoso',
            class: ''
        },
        {
            number : 6, 
            direction : 'across',
            row: 6,
            column: 2+10,
            clue: '',
            answer : 'hospital',
            class: ''
        },
        {
            number : 7, 
            direction : 'down',
            row: 1,
            column: 3+10,
            clue: '',
            answer : 'hierro',
            class: 'final'
        } ]}})}
var staticRenderFns = []

export { render, staticRenderFns }