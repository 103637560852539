<template>
    <div id="start-background" class="h-100 w-100">
        <div class="row h-100 w-100 m-0 p-0 d-flex justify-content-center align-items-start">
            <h5 style="z-index: 999;" class="position-absolute text-center w-100 bg-warning pt-2 pb-2">Para ingresar a una sesión de juego, por favor, acceda desde el enlace/url correspondiente.</h5>
            <div id="start" class="col p-0 m-0 position-relative w-100 h-100" :style="cssProps"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Start',
        data(){
            return {
                cssProps: {
                    backgroundImage: `url(${require('@/assets/imagen_fondo.png')})`
                }
            };
        },
    }
</script>

<style scoped>

    #start{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
</style>
