<template>
    <div class="activity6checks w-100 h-100">
        <Checks 
            act_id="demoscene2activity6"
            scene_id="demoscene2"
            title="La importancia del déficit de hierro como comorbilidad en la IC"
            intro_text="El déficit de hierro y la anemia son frecuentes en la IC. El déficit de hierro en el paciente con IC se asocia a una menor calidad de vida y un peor pronóstico de la enfermedad con mayor riesgo de hospitalizaciones, reingreso y morbimortalidad." 
            question="¿Qué parámetros considera necesario solicitar en la analítica para comprobar si existe déficit de hierro?" 
            :options="[
                {
                    text: 'Hemograma',
                    value: 0,
                },
                {
                    text: 'Ferritina',
                    value: 1,
                },
                {
                    text: 'Sideremia',
                    value: 2,
                },
                {
                    text: 'Transferrina',
                    value: 3,
                },
                {
                    text: 'Índice de Saturación de Transferrina (IST)',
                    value: 4,
                }
            ]" 
            :correct_answer="[
                1, 4
            ]"
            clue_text="Se pregunta por diagnóstico de déficit de hierro, no anemia"
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            ></Checks>
        </div>
</template>

<script>
import Vue from 'vue';
import Checks from '../../../../components/activitytype/Checks';
import FeedbackActivity6 from '../feedbacks/FeedbackActivity6';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Checks
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene2");
        vueapp.$store.commit("setLastURL", "demoscene2activity6");
        setActivityVisited('demoscene2', 'demoscene2activity6');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity6)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
        
        updateUserSession();
    }
}
</script>
