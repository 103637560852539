<template>
    <div class="w-100 h-100 animate__animated animate__fadeIn">
        <div class="row w-100 h-100 m-0">
            <div :class="left_size">
                <div class="activity_title  question-title text-rockwell-bold">{{ title }}</div>
                <div class="intro_text mt-5 mb-4" v-html="intro_text"></div>            
                <div class="w-100 botonespecial">
                    <button type="button" v-for="question in questions" class="btn btn-secondary w-100 mb-2" @click="displayAnswer(question.answer)">{{ question.question }}</button>
                </div>
            </div>
            <div id="right-size-order" :class="right_size">
                <div class="answer respuestaVisible mt-5">
                    <div class="respuestaOculta" v-if="current_answer != null">
                        {{ current_answer }}
                    </div>
                
                </div>
                <img id="qandaimage" v-if="imgsrc != ''" :src="imgsrc" />
                
                <div class="feeback-back-btn" id="back-btn-order">
                    <button class="btn btn-action mt-3 mb-3 rounded check-btn" @click="goBack">Volver</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActivityType from './ActivityType';
export default {
    extends: ActivityType,
    props: {
        act_id: 0,
        scene_id: 0,
        clue_text: {
            type: String,
            required: false
        },
        question: {
            type: String,
            required: false
        },
        feedback: {
            type: String,
            required: false
        },
        incorrect_msg: {
            type: String,
            required: false
        },
        left_size: {
            type: String,
            default: 'col-6'
        },
        right_size: {
            type: String,
            default: 'col-6'
        },
        questions: {
            type: Array,
            required: true,
            default: []
        },
        imgsrc: {
            type: String,
            required: false,
            default: ''
        },
        intro_text: {
            type: String,
            required: true,
            default: ''
        }
    },
    data: function() {
        return {
            current_answer: null,
            answer: -1,
        }
    },
    methods:{
        displayAnswer: function(selected_item){
            this.current_answer = selected_item;
        },
        goBack(){
            router.push({name: vueapp.$store.state.back_url}).catch((e)=>{console.log(e)});
        },
    }
}
</script>

<style>
    #qandaimage{
        position: absolute;
        right: 150px;
        bottom: 0px;
        width: 35%;
    }

    #right-size-order{
        min-height: 500px;
    }

    #back-btn-order{
        position: fixed !important;
        bottom: 30px;
        right: 100px;
        z-index: 9999 !important;
    }
</style>