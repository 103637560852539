<template>
    <b-modal v-model="show" id="modal-center-sessions" centered title="Registra nueva sesión de juego" header-text-variant="light">
        <div class="form-group mb-2">
            <label class="font-weight-bold" for="inputName">Nombre de la sesión:</label>
            <input type="text" v-model="new_session.name" class="form-control" id="inputName" name="name" placeholder="Introduzca el nombre de la sesión de juego..">
        </div>
        <div class="form-group mb-2">
            <label class="font-weight-bold" for="inputCaseId">Caso:</label>
            <select v-model="new_session.case_id" class="form-control" id="inputCaseId" name="case_id" :value="null">
                <option :value="null">Selecciona el caso clínico</option>
                <option value="1" selected>Caso clínico 1</option>
                <option value="2" disabled>Caso clínico 2</option>
                <option value="3" disabled>Caso clínico 3</option>
                <option value="4" disabled>Caso clínico 4</option>
                <option value="5" disabled>Caso clínico 5</option>
            </select>
        </div>
        <div class="form-group mb-2">
            <label class="font-weight-bold" for="inputAccess">Modo de acceso:</label>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefaultAccessMode" id="flexRadioDefaultAccessMode1" value="0" v-model="new_session.access_mode">
                <label class="form-check-label" for="flexRadioDefaultAccessMode1">
                    Abierto.
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefaultAccessMode" id="flexRadioDefaultAccessMode2" checked value="1" v-model="new_session.access_mode">
                <label class="form-check-label" for="flexRadioDefaultAccessMode2">
                    A través de login.
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefaultAccessMode" id="flexRadioDefaultAccessMode3" checked value="2" v-model="new_session.access_mode">
                <label class="form-check-label" for="flexRadioDefaultAccessMode3">
                    Demostración.
                </label>
            </div>
            
            <div v-if="new_session.access_mode == 0" class="alert alert-info mt-2" role="alert">
                <b>Abierto:</b> El jugador al entrar simplemente pone nombre y automaticamente se crea un usuario con su nombre, en esa única sesión de juego.
            </div>
            
            <div v-if="new_session.access_mode == 1" class="alert alert-info mt-2" role="alert">
                <b>Login:</b> Al entrar solamente se pide al jugador el usuario y contraseña. (Previamente esa cuenta ha de ser creada por un administrador).
            </div>
            
            <div v-if="new_session.access_mode == 2" class="alert alert-info mt-2" role="alert">
                <b>Demostración:</b> Al entrar solamente es darle al botón de iniciar demo, sin necesitad rellenar ningun formulario.
            </div>
        </div>
        <div class="form-group mb-2" id="game_mode_section">
            <label class="font-weight-bold" for="inputAccess">Modo de juego:</label>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefaultGameMode" id="flexRadioDefaultGameMode1" value="0" v-model="new_session.game_mode">
                <label class="form-check-label" for="flexRadioDefaultGameMode1">
                    Individual.
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefaultGameMode" id="flexRadioDefaultGameMode2" checked value="1" v-model="new_session.game_mode">
                <label class="form-check-label" for="flexRadioDefaultGameMode2">
                    Multijugador.
                </label>
            </div>
            
            <div v-if="new_session.game_mode == 0" class="alert alert-info mt-2" role="alert">
                <b>Individual:</b> El jugador entra y puede comenzar el juego.
            </div>
            
            <div v-if="new_session.game_mode == 1" class="alert alert-info mt-2" role="alert">
                <b>Multijugador:</b> El admin será el que inicie el juego, los jugadores estarán en la sala de espera hasta que comienze el juego.
            </div>
        </div>
        
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    class="float-right"
                    @click="register_session"
                >
                    Registrar sesión de juego
                </b-button>
                <b-button
                    variant="secondary"
                    class="float-right mr-2"
                    @click="show=false"
                >
                    Cancelar
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import utils from '../../utils'
    const ACCESS_MODE = {
        OPENED:0,
        LOGIN:1,
        DEMO:2,
    };
    const GAME_MODE = {
        SINGLEPLAYER:"0",
        MULTIPLAYER:"1"
    };
    export default {
        name: 'RegisterSessionModal',
        data(){
            return{
                // Show/hide modal.
                show: false,
                // Session data.
                new_session: {
                    name: "",
                    case_id: null,
                    access_mode: null,
                    game_mode: null,
                }
            };
        },
        watch : {
            'new_session.access_mode' : function(val){
                // If the value of access_mode is different than 2, we show the "game_mode_section"
                // Is the value is == 2 we just hide the "game_mode_section"
                // also we set the game_mode value to 0 wich equals to 
                if(val == ACCESS_MODE.DEMO){
                    $('#game_mode_section').hide();
                    //$('#game_mode_section').css('visibility', 'hidden');
                    this.new_session.game_mode = GAME_MODE.SINGLEPLAYER;
                    //this.new_session.name = "demo_"+utils.makeID(10);
                }else{
                    $('#game_mode_section').show();
                    //$('#game_mode_section').css('visibility', 'visible');
                    this.new_session.game_mode = null;
                    //this.new_session.name = "";
                }
            }
        },
        methods: {
            show_modal : function(){
                this.show = true;
            },
            register_session : function(){
                console.log(this.new_session)
                //if(utils.isValidParameter(this.new_session.name) && utils.isValidParameter(this.new_session.case_id) && utils.isValidParameter(this.new_session.access_mode) && utils.isValidParameter(this.new_session.game_mode)){
                    if(!utils.isValidParameter(this.new_session.name)){
                        vueapp.$toast.error("¡Comprueba el nombre de la sesión!");
                        return;
                    }
                    if(!utils.isValidParameter(this.new_session.case_id)){
                        vueapp.$toast.error("¡Comprueba el campo caso clínico!");
                        return;
                    }
                    if(!utils.isValidParameter(this.new_session.access_mode)){
                        vueapp.$toast.error("¡Comprueba el campo modo de acceso!");
                        return;
                    }
                    if(!utils.isValidParameter(this.new_session.game_mode)){
                        vueapp.$toast.error("¡Comprueba el campo modo de juego!");
                        return;
                    }
                    // Hide modal
                    this.show = false;
                    // Send packet to the server for register new session
                    ws.send(JSON.stringify({action: RPC.REGISTER_SESSION, params:this.new_session}));
                    // Reset form values
                    this.new_session.name = "";
                    this.new_session.case_id = null;
                    this.new_session.access_mode = null;
                    this.new_session.game_mode = null;
                //}else{
               //     vueapp.$toast.error("¡Comprueba si los campos nombre, caso, modo de acceso y modo de juego estan completados!");
                //}
            }
        }
    }
</script>

<style scoped>

</style>
