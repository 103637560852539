<template>
    <div class="progress w-100 h-100 p-3">
        <div class="row w-100 h-100 d-flex justify-content-center align-items-center flex-column">
            <div class="lds-dual-ring"></div>
            <h3 class="mt-3">Cargando la información del panel</h3>
        </div>
    </div>
</template>

<script>
    import RPC from '../../../../shared/RPC'
    export default {
        name: 'Loading',     
        data(){
            return {
                sessions_loaded: false,
                users_loaded: false,
            };
        },   
        mounted: function(){
            //ws.send(JSON.stringify({action: RPC.GET_SESSIONS, params: null}));
            //ws.send(JSON.stringify({action: RPC.GET_USERS, params: null}));
        },
        watch: {
            '$store.state.panel_sessions': function() {  
                this.sessions_loaded = true;     
                if(this.sessions_loaded && this.users_loaded){
                    router.push({name:"panel"}).catch((e)=>{console.log(e)});
                }
            },
            '$store.state.panel_users': function() {  
                this.users_loaded = true;       
                if(this.sessions_loaded && this.users_loaded){
                    router.push({name:"panel"}).catch((e)=>{console.log(e)});
                }   
            }
        },
    }
</script>
