import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto'

Vue.use(Vuex);

const getDefaultState = () => {
    return {
        demo: {
            // Demo vuex state
            startedAt: null,
            finishedAt: null,
            final_score: null,
            //Global timer
            timer: null,
            timer_interval: null,
            //CountDown timer
            countdown: 3,
            countdown_interval: null,
            //Game state
            game_state: null,
            /*     
                IDLE:       0,  - The game is waiting to be initialized.
                STARTING:   1,  - The game is on the initial countdown view
                STARTED:    2,  - The game is already initialized, the other countdown on the navbar already got fired.
                STOPPED:    3,  - The game is stoped.
                FINISHED:   4   - The game is finished.
            */
            //Last saved url
            last_url: null,
            //Scenes
            scenes: {
                demoscene1:{
                    activities: {},
                    locked: false,
                    completed: false,
                    scene: null
                },
                demoscene2:{
                    activities: {},
                    locked: true,
                    completed: false,
                    scene: null
                },
                demoscene3:{
                    activities: {},
                    locked: true,
                    completed: false,
                    scene: null
                },
                demoscene4:{
                    activities: {},
                    locked: true,
                    completed: false,
                    scene: null
                },
                demoscene5:{
                    activities: {},
                    locked: true,
                    completed: false,
                    scene: null
                },
            },
            //Back URL
            back_url: null,
            //Clue timestamp
            clue_timestamp: null,
            //The game is over
            game_over: null,
            score: 0,
            // The user is playing the demo
            demo: false,
            //fs button with no text
            fsbtn_mini: false,
            //Full screen message
            fs_message: true,
        },
        
        // Demo vuex state
        startedAt: null,
        finishedAt: null,
        final_score: null,
        //Back URL
        back_url: null,
        //Last saved url
        last_url: null,
        //Beep sound
        beep: null,
        end: null,
        error: null,
        correct: null,
        gameover: null,
        //Image assets
        images: null,
        //Loading overlay
        loading: true,
        // Game vuex dtate
        // User information
        user: null,
        // Game sessions
        panel_sessions:[],
        panel_sessions_datatable: null,
        // Game users
        panel_users:[],
        panel_users_datatable: null,
        panel_pagination: {
            page: null, 
            limit: null, 
            offset: null
        },
        // ranking
        ranking:[],
        // Current user session
        session: null,
        // Game Scenes
        scenes:{
            demoscene1:{
                activities: {},
                locked: false,
                completed: false,
                scene: null
            },
            demoscene2:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
            demoscene3:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
            demoscene4:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
            demoscene5:{
                activities: {},
                locked: true,
                completed: false,
                scene: null
            },
        },
        // Game Score
        score: null,
        // Game time
        timer: null,
        // CountDown value
        countdown: 3,
        // CountDown timer
        countdown_timer: null,
        // Game state
        game_state: null,
        //Full screen message
        fs_message: true,
        // Game is over
        game_over: null,
        // Back to map button
        backtomap: null,
        // Panel viewing session id
        panel_session_id: null,
        // Volatile information
        user_session: null,
        // List users sessions data from session id
        users_sessions: null,
        log: [],
        log_apache: []
    }
}


// initial state
const state = getDefaultState();

const store = new Vuex.Store({
    state,
    getters: {},
    mutations: {
		initialiseStore(state) {
			// Check if the ID exists
			if(localStorage.getItem('store'+window.game_id)) {
                this.commit("setDemoState", JSON.parse(localStorage.getItem('store'+window.game_id)));
				//this.replaceState(
				//	Object.assign(state.demo, JSON.parse(localStorage.getItem('store'+window.game_id)))
				//);
			}
		},
        resetGlobalState(state) {
            Object.assign(state, getDefaultState())
        },
        setCountDown(state, param) {
            state.countdown = param;
        },
        setStartedAt(state, param) {
            state.startedAt = param;
        },
        setFinishedAt(state, param) {
            state.finishedAt = param;
        },
        setFinalScore(state, param) {
            state.demo.final_score = param;
        },
        setRanking(state, param) {
            state.ranking = param;
        },
        setTimerInterval(state, param) {
            state.demo.timer_interval = param;
        },
        setCountDownInterval(state, param){
            state.demo.countdown_interval = param;
        },
        setTimer(state, param) {
            state.timer = param;
        },
        setBackToMap(state, param){
            state.backtomap = param;
        },
        setCountDownTimer(state, param) {
            state.countdown_timer = param;
        },
        setGameState(state, param) {
            state.game_state = param;
        },
        setLastURL(state, param) {
            state.last_url = param;
        },
        setScenes(state, param) {
            state.scenes = param;
        },
        setBackURL(state, param) {
            state.back_url = param;
        },
        setClueTimestamp(state, param) {
            state.demo.clue_timestamp = param;
        },
        setGameOver(state, param) {
            state.game_over = param;
        },
        setScore(state, param) {
            state.score = param;
        },
        setUsersSessions(state, param) {
            state.users_sessions = param;
        },
        setBeepSound(state, param) {
            state.beep = param;
        },
        setEndSound(state, param) {
            state.end = param;
        },
        setErrorSound(state, param) {
            state.error = param;
        },
        setCorrectSound(state, param) {
            state.correct = param;
        },
        setGameOverSound(state, param) {
            state.gameover = param;
        },
        setImageAssets(state, param) {
            state.images = param;
        },
        setFullScreenMessage(state, param) {
            state.fs_message = param;
        },
        setLoading(state, param){
            state.loading = param;
        },
        setUser(state, param){
            state.user = param;
        },
        setState(state, param){
            state = param;
        },
        setDemo(state, param){
            state.demo.demo = param;
        },
        setDemoState(state, param){
            state.demo = param;
        },
        setFSBtnMini(state, param){
            state.demo.fsbtn_mini = param;
        },
        setSession(state, param){
            state.session = param;
        },
        setPanelSessions(state, param){
            state.panel_sessions = param;
        },
        setPanelSessionID(state, param){
            state.panel_session_id = param;
        },
        setPanelSessionsDataTable(state, param){
            state.panel_sessions_datatable = param;
        },
        setPanelUsersDataTable(state, param){
            state.panel_users_datatable = param;
        },
        setPanelUsers(state, param){
            state.panel_users = param;
        },
        setPanelUsersPagination(state, param){
            state.panel_pagination = param;
        },
        setUserSession(state, param){
            state.user_session = param;
        },
        setLog(state, param){
            state.log = param;
        },
        setLogApache(state, param){
            state.log_apache = param;
        },
    },
    actions: {}
})

store.subscribe((mutation, state) => {
	localStorage.setItem('store'+window.game_id, JSON.stringify(state.demo));
});

export default store