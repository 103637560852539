<template>
    <div class="row h-100 w-100 m-0">
        <!-- Share link modal -->
        <b-modal v-if="session" v-model="show_modal_share" id="modal-share-link" centered title="Comparte la sesión de juego" header-text-variant="light">
            <div class="form-group mb-2">
                <label for="inputSessionName">Nombre de la sesión:</label>
                <input type="text" v-model="session.name" class="form-control" id="inputSessionName" name="name" readonly>
            </div>
            
            <div class="form-group mb-2">
                <label for="inputSessionLink">Enlace:</label>
                <div class="d-flex">
                    <input type="text" v-model="session.link" class="form-control" id="inputSessionLink" name="link" readonly>
                    <button class="btn btn-warning ml-3" @click="copy_session">Copiar</button>
                </div>
            </div>
            
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="nav-blue"
                        class="float-right"
                        @click="show_modal_share=false"
                    >
                        Listo
                    </b-button>
                </div>
            </template>
        </b-modal>

        <div class="col-12">
            <nav aria-label="breadcrumb" class="mt-3">
                <ol class="breadcrumb mb-2">
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel'})">Panel</a></li>
                    <li class="breadcrumb-item"><a href="#" @click="$router.push({name:'panel-sessions'})">Sesiones</a></li>
                    <li class="breadcrumb-item" v-if="session != null" ><a href="#" @click="$router.push({name:'panel-session-id', params:{id: $route.params.id}})">{{ session.name }}</a></li>
                    <li class="breadcrumb-item" aria-current="page">Ranking</li>
                </ol>
            </nav>
            <div class="row mt-3">
                <div class="col-lg-4 h-100">
                    <h5 class="p-2 bg-dark text-white w-100 d-flex justify-content-between align-items-center font-weight-bold">
                        Detalle de la sesión:
                        <button class="btn btn-sm btn-nav-blue"><font-awesome-icon :icon="['fa', 'info']" /></button>
                    </h5>
                    <table  v-if="session != null" class="table table-striped" style="width:100%">
                        <tbody>
                            <tr>
                                <td><b>NOMBRE</b></td>
                                <td>{{ session.name }}</td>
                            </tr>
                            <tr>
                                <td><b>MODO DE ACCESO</b></td>
                                <td>{{ (session.access_mode==0) ? 'Abierto' : 'A través de login' }}</td>
                            </tr>
                            <tr>
                                <td><b>MODO DE JUEGO</b></td>
                                <td>{{ (session.game_mode==0) ? 'Individual' : 'Multijugador' }}</td>
                            </tr>
                            <tr v-if="session.game_mode == 1">
                                <td><b>ESTADO</b></td>
                                <td>
                                    <div v-if="session.status === $STATUS.IDLE">
                                        <span class="badge badge-primary">En espera</span>
                                    </div>
                                    <div v-else-if="session.status === $STATUS.STARTING">
                                        <button class="badge badge-info">Empezando</button>
                                    </div>
                                    <div v-else-if="session.status === $STATUS.STARTED">
                                        <button class="badge badge-success">En juego</button>
                                    </div>
                                    <div v-else-if="session.status === $STATUS.STOPPED">
                                        <button class="badge badge-warning">Detenido</button>
                                    </div> 
                                    <div v-else-if="session.status === $STATUS.FINISHED">
                                        <button class="badge badge-danger">Finalizado</button>
                                    </div>
                                    <div v-else>
                                        Desconocido
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-8 h-100">
                    <h5 class="p-2 bg-dark text-white w-100 d-flex justify-content-between align-items-center font-weight-bold">
                        Ranking
                        <div class="d-flex align-items-center">
                            <small v-if="counter != null" class="mr-3">Tiempo restante:{{ counter }}</small>
                            <div v-if="session != null">
                                <button class="btn btn-sm btn-primary text-white share-session" @click="share_session(session.id)"><font-awesome-icon :icon="['fa', 'link']" /></button>
                            </div>
                       </div> 
                    </h5>

                    <table id="table_ranking" class="table table-striped" style="width:100%">
                        <thead class="escaperoom-main-bg text-white">
                            <tr>
                                <td style="width:5%;">#</td>
                                <td style="width:20%;">NOMBRE</td>
                                <td style="width:20%;">COMIENZO</td>
                                <td style="width:20%;">FIN</td>
                                <td style="width:5%;">TERMINADO</td>
                                <td style="width:10%;">TIEMPO</td>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RPC from '../../../../shared/RPC'
    
    import dotenv from 'dotenv'
    dotenv.config()
    import XLSX from "xlsx";
    export default {
        name: 'Ranking',
        data(){
            return {
                session: null,
                datatable_ranking: null,
                // Game time clock.
                counter: null,
                // Game time timer.
                interval: null,
                show_modal_share: false
            };
        },
        destroy: function(){
            if(this.interval != null){
                clearInterval(this.interval);
            }
        },
        mounted: function(){
            
            ws.send(JSON.stringify({action: RPC.GET_RANKING, params:this.$route.params.id}));
            this.datatable_ranking = $('#table_ranking').DataTable({
                responsive: true,
                "dom": '<"toolbar">frtip',
                "bInfo" : false,
                paging: false,
                "oLanguage": {
                    "sSearch": "Buscar:"
                },
                /*buttons: [
                    'excelHtml5',
                    'csvHtml5',
                ]*/
            });
            vueapp.$store.commit("setLastURL", "panel-ranking-id:"+this.$route.params.id);
            //console.log(this.datatable_ranking)
            updateUserSession();


            $('.dataTables_filter').prepend("<button id='export' class='mr-3'>Exportar a excel</button>");
            var exportbtn = document.getElementById("export");
            
            if(exportbtn != null)
            {
                exportbtn.addEventListener("click", function(){
                    this.ExportToExcel('xlsx');                    
                }.bind(this));
            }
        },
        methods: {
            ExportToExcel:function(type, fn, dl) {
                var elt = document.getElementById('table_ranking');
                var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
                return dl ?
                    XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
                    XLSX.writeFile(wb, fn || ('Ranking_session_'+this.session.id+'.' + (type || 'xlsx')));
            },
            share_session : function(){
                this.show_modal_share = true;
            },
            copy_session: function(){
                /* Get the text field */
                var copyText = $("#inputSessionLink")[0];

                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /* For mobile devices */

                /* Copy the text inside the text field */
                document.execCommand("copy");
                vueapp.$toast.success("Enlace copiado correctamente!");
            },
        },
        watch:{
            '$store.state.ranking' : function(){
                console.log("new changes in ranking data")
                
                this.session = vueapp.$store.state.ranking;

                // Clear table content
                this.datatable_ranking.clear();
                // Fill table with ranking contents
                for(var i = 0; i < vueapp.$store.state.ranking.users.length; i++){
                    var user = vueapp.$store.state.ranking.users[i];
                    if(user != null){
                        var username = user.username;
                        
                        var startedAt = null, finishedAt = null, startedAtString = '-', finishedAtString = '-', game_finished = false, spent_time = 99999;
                        if(typeof user.users_sessions.startedAt !== 'undefined' && user.users_sessions.startedAt != null){
                            if(vueapp.moment(user.users_sessions.startedAt).isValid()){
                                startedAt = vueapp.moment(user.users_sessions.startedAt);
                                startedAtString = startedAt.format("DD/MM/YYYY  HH:mm:ss");
                            }
                        }
                        if(typeof user.users_sessions.finishedAt !== 'undefined' && user.users_sessions.finishedAt != null){
                            if(vueapp.moment(user.users_sessions.finishedAt).isValid()){
                                finishedAt = vueapp.moment(user.users_sessions.finishedAt);
                                finishedAtString = finishedAt.format("DD/MM/YYYY  HH:mm:ss");
                            }
                        }
                        
                        if(startedAt != null && finishedAt != null){
                            spent_time = vueapp.moment.duration(finishedAt.diff(startedAt)).asSeconds();

                            if(user.users_sessions.data != null && typeof user.users_sessions.data !== 'undefined')
                                game_finished = (user.users_sessions.data.game_over != null && typeof user.users_sessions.data.game_over !== 'undefined') ? ((user.users_sessions.data.game_over == true) ? false : true) : false;
                        }else{
                            game_finished = false;
                        }
                        // Add new row
                        this.datatable_ranking.row.add([0, username, startedAtString, finishedAtString, game_finished, spent_time]);
                    }
                }
                // Order and draw the new content
                this.datatable_ranking
                    .order([ 5, 'asc' ])
                    .draw();
                
                // Update each row from column 0 (Player position)
                var position = 1;
                this.datatable_ranking
                    .rows()
                    .every( function ( rowIdx, tableLoop, rowLoop ) {
                        var data = this.data();

                        // Adjust played time for each row of the table.
                        var formated_time = null, minutes = null, seconds = null;
                        if(data[5] == 99999)
                            formated_time = "-"
                        else{
                            minutes = Math.floor(data[5] / 60);
                            seconds = Math.round(data[5] - minutes * 60);
                            formated_time = minutes + " m " + seconds+ " s";
                        }
                        // Adjust player position.
                        data[0] = (minutes>=process.env.VUE_APP_GAME_DURATION || minutes == null) ? '-' : position+'º'; //col #0 (Player position)

                        // Adjust game finished
                        data[4] = (data[4] == true) ? 'Sí' : 'No'; //col #4 (Game finished)

                        // Adjust time spent in the game.
                        data[5] = formated_time; //col #5 (Time played)
                        this.data(data)
                        position++;
                    })
                
                if(this.session.game_mode == window.GAME_MODES.MULTIPLAYER){
                    if(this.session.startedAt != null && this.session.finishAt != null){
                        if(this.interval != null)
                            clearInterval(this.interval);

                        this.interval = setInterval(function(){
                            var finish = vueapp.moment(this.session.finishAt);
                            var start = vueapp.moment();
                            var asseconds = vueapp.moment.duration(finish.diff(start)).asSeconds();
                            var minutes = Math.floor(asseconds / 60);
                            var seconds = Math.round(asseconds - minutes * 60);
                       
                            if(finish>start)
                                this.counter = ' '+minutes +'m '+ seconds+'s';
                            else{
                                this.counter = ' 0m 0s';
                                //this.session.status = 4;
                            }
                        }.bind(this), 1000);
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>
