var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity4checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"demoscene2activity4","scene_id":"demoscene2","title":"Factores desencadenantes de descompensación de ICC","intro_text":"En los pacientes con ICC, la descompensación aguda de su enfermedad puede ocurrir sin factores desencadenantes conocidos, pero en la mayoría de las ocasiones están presentes varios factores. Alguno de estos factores requieren detección y tratamiento urgentes.","question":"¿Cuál de los siguientes factores desencadenantes de descompensación de ICC requieren tratamiento urgente?","options":[
            {
                text: 'Síndrome coronario agudo (SCA)',
                value: 0,
            },
            {
                text: 'Alteración del ritmo grave (taquiarritmia/bradiarritmia)',
                value: 1,
            },
            {
                text: 'Emergencia hipertensiva',
                value: 2,
            },
            {
                text: 'Infecciones (neumonía, endocarditis infecciosa, sepsis)',
                value: 3,
            },
            {
                text: 'Falta de adherencia al tratamiento farmacológico o no farmacológico',
                value: 4,
            },
            {
                text: 'Patología mecánica aguda',
                value: 5,
            },
            {
                text: 'Consumo de fármacos (AINE, corticoides) o tóxicos (alcohol, drogas)',
                value: 6,
            },
            {
                text: 'Tromboembolismo pulmonar',
                value: 7,
            },
            {
                text: 'Exacerbación de EPOC',
                value: 8,
            },
            {
                text: 'Alteraciones hormonales o metabólicas (disfunción tiroidea, cetoacidosis diabética, embarazo)',
                value: 9,
            },
            {
                text: 'Cirugía, situaciones de estrés',
                value: 10,
            }
        ],"correct_answer":[
            0, 1, 2, 3, 5, 7
        ],"clue_text":"Según la Guía ESC 2021 sobre el diagnóstico y tratamiento de la IC son 7, que quedan recogidas bajo el acrónimo CHAMPIT.","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }