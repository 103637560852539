<template>
    <div class="activity1qa w-100 h-100">
        <QandA 
            act_id="demoscene5activity1"
            scene_id="demoscene5"
            title="Consulta de seguimiento tras tratamiento con hierro intravenoso"
            intro_text="En primer lugar se procede a la valoración clínica de la paciente con la anamnesis." 
            :imgsrc="imgsrc"
            left_size="col-md-5"
            right_size="col-md-7"
            :questions="[
                {
                    id: 0,
                    question: 'Buenos días Juana, ¿qué tal se encuentra?',
                    answer: 'Hola doctor, desde que estuve en el lugar donde se proporciona a la población una asistencia médico sanitaria completa, tanto curativa como preventiva (Fila 6), me encuentro muy bien, mucho más animada.'
                },
                {
                    id: 1,
                    question: '¿Se sigue fatigando cuando camina?',
                    answer: 'No, ya no me fatigo cuando me camino, sólo si voy muy rápido o subo cuestas.'
                },
                {
                    id: 2,
                    question: '¿Se le siguen inflamando los tobillos?',
                    answer: 'Tampoco, desde hace unos días ya no se me hinchan.'
                },
                {
                    id: 3,
                    question: '¿Está tomando toda su medicación?',
                    answer: 'Si doctor, utilizo el pastillero como usted me recomendó y no se me olvida nunca.'
                }
            ]"
            :feedback="feedback_content"
            ></QandA>
        </div>
</template>

<script>
import QandA from '../../../../components/activitytype/QandA';

export default {
    data: function() {
        return {
            feedback_content: "",
            imgsrc: require("@/assets/personaje_1.png")
        };
    },
    components: {
        QandA
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene5");
        vueapp.$store.commit("setLastURL", "demoscene5activity1");
        setActivityVisited('demoscene5', 'demoscene5activity1');
        var act = getActivityStatus("demoscene5", 'demoscene5activity1');
        if(act.completed == false)
            vueapp.$store.commit("setScore", vueapp.$store.state.score + 1);
        setActivityCompleted('demoscene5', 'demoscene5activity1', true);

        updateUserSession();
    }
}
</script>

<style>
    #qandaimage{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
</style>