<template>
    <div class="row h-100 w-100 m-0 d-flex justify-content-center align-items-center">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name : 'Map',
    }
</script>
