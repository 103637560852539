var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity2checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"demoscene2activity2","scene_id":"demoscene2","title":"Pruebas complementarias para el diagnóstico de ICA o descompensación de ICC.","intro_text":"En muchas ocasiones los síntomas y signos de descompensación de ICC son poco específicos y se precisa de pruebas adicionales para confirmar el diagnóstico.","question":"¿Cuál de las siguientes pruebas diagnósticas solicitaría en esta primera visita?","options":[
            {
                text: 'PCR para descartar infección por Sars-CoV2',
                value: 0,
            },
            {
                text: 'ECG',
                value: 1,
            },
            {
                text: 'Radiografía de tórax',
                value: 2,
            },
            {
                text: 'Espirometría forzada',
                value: 3,
            },
            {
                text: 'Analítica completa (con péptidos natriuréticos si se dispone de ellos)',
                value: 4,
            },
            {
                text: 'Ecocardiograma',
                value: 5,
            },
            {
                text: ' Ecodoppler de extremidades inferiores',
                value: 6,
            },
            {
                text: 'Prueba de esfuerzo cardíaco',
                value: 7,
            },
            {
                text: 'Test de la marcha de 6 minutos',
                value: 8,
            }
        ],"correct_answer":[
            1, 2, 4
        ],"clue_text":"Son 3 las correctas","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }