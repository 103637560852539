<template>
    <div class="activity5checks w-100 h-100">
        <Checks 
            act_id="scene1activity4cc1"
            scene_id="scene1cc1"
            title="Exploración física y complementaria en la consulta de seguimiento de enfermería de AP del paciente con ICC"
            intro_text="Tras una breve entrevista, se procede a la exploración física." 
            question="¿Qué tres acciones priorizaría la enfermera en esta consulta de seguimiento y tras la anamnesis realizada?" 
            :options="[
                {
                    text: 'Realización de ECG',
                    value: 0,
                },
                {
                    text: 'Realización de test rápido de Antígenos para Sars-Cov2',
                    value: 1,
                },
                {
                    text: 'Control de peso',
                    value: 2,
                },
                {
                    text: 'Toma de temperatura',
                    value: 3,
                },
                {
                    text: 'Control de tensión arterial, frecuencia cardiaca y saturación de oxígeno',
                    value: 4,
                },
                {
                    text: 'Exploración de los pies',
                    value: 5,
                },
                {
                    text: 'Test de adherencia al tratamiento',
                    value: 6,
                },
                {
                    text: 'Vacunación de la gripe',
                    value: 7,
                }
            ]" 
            :correct_answer="[
                2, 4, 6
            ]"
            clue_text="Se trata de una visita de seguimiento de una paciente pluripatológica y polimedicada, con HTA, ICC. No refiere síntomas respiratorios salvo aumento de la disnea."
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            ></Checks>
        </div>
</template>

<script>
import Vue from 'vue';
import Checks from '../../../../components/activitytype/Checks';
import FeedbackActivity4 from '../feedbacks/FeedbackActivity4';

export default {
    data: function(){
        return {
            feedback_content: ""
        };
    },
    components: {
        Checks
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "scene1cc1");
        vueapp.$store.commit("setLastURL", "scene1activity4cc1");
        setActivityVisited('scene1cc1', 'scene1activity4cc1');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity4)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;
    }
}
</script>
