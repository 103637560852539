var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"activity1checks w-100 h-100"},[_c('Checks',{attrs:{"act_id":"demoscene4activity1","scene_id":"demoscene4","title":"Beneficios del tratamiento del déficit de hierro en la IC","intro_text":"El tratamiento del déficit de hierro en la IC, incluso sin la existencia de anemia, presenta múltiples beneficios.","question":"¿Cuál de las siguientes afirmaciones es falsa en relación al tratamiento del déficit de hierro en la IC?","options":[
            {
                text: 'Efecto neutro sobre la FEVI',
                value: 0,
            },
            {
                text: 'Mejoría en la calidad de vida',
                value: 1,
            },
            {
                text: 'Mejora de la clase funcional',
                value: 2,
            },
            {
                text: 'La capacidad de ejercicio no se modifica',
                value: 3,
            },
            {
                text: 'Disminución de reingresos hospitalarios',
                value: 4,
            },
            {
                text: 'Sin efecto en el consumo de oxígeno máximo',
                value: 5,
            },
            {
                text: 'Efecto positivo en la concentración de creatinina',
                value: 6,
            }
        ],"correct_answer":[
            0, 3, 5 ],"clue_text":"3 de estas afirmaciones son falsas ","feedback":_vm.feedback_content,"incorrect_msg":"La respuesta es incorrecta"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }