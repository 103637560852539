<template>
    <div class="activity1checks w-100 h-100">
        <Checks 
            act_id="demoscene4activity1"
            scene_id="demoscene4"
            title="Beneficios del tratamiento del déficit de hierro en la IC"
            intro_text="El tratamiento del déficit de hierro en la IC, incluso sin la existencia de anemia, presenta múltiples beneficios." 
            question="¿Cuál de las siguientes afirmaciones es falsa en relación al tratamiento del déficit de hierro en la IC?" 
            :options="[
                {
                    text: 'Efecto neutro sobre la FEVI',
                    value: 0,
                },
                {
                    text: 'Mejoría en la calidad de vida',
                    value: 1,
                },
                {
                    text: 'Mejora de la clase funcional',
                    value: 2,
                },
                {
                    text: 'La capacidad de ejercicio no se modifica',
                    value: 3,
                },
                {
                    text: 'Disminución de reingresos hospitalarios',
                    value: 4,
                },
                {
                    text: 'Sin efecto en el consumo de oxígeno máximo',
                    value: 5,
                },
                {
                    text: 'Efecto positivo en la concentración de creatinina',
                    value: 6,
                }
            ]" 
            :correct_answer="[
                0, 3, 5,
            ]"
            clue_text="3 de estas afirmaciones son falsas "
            :feedback="feedback_content"
            incorrect_msg="La respuesta es incorrecta"
            ></Checks>
        </div>
</template>

<script>
import Vue from 'vue';
import Checks from '../../../../components/activitytype/Checks';
import FeedbackActivity1 from '../feedbacks/FeedbackActivity1';

export default {
    data: function() {
        return {
            feedback_content: ""
        };
    },
    components: {
        Checks
    },
    mounted: function() {
        vueapp.$store.commit("setBackURL", "demoscene4");
        vueapp.$store.commit("setLastURL", "demoscene4activity1");
        setActivityVisited('demoscene4', 'demoscene4activity1');

        // Create a dummy DOM node.
        const div = document.createElement('div');
        // Create a new Vue instance and use the dummy div as a mount point
        const componentInstance = new Vue(Object.assign({}, FeedbackActivity1)).$mount(div); 
        // Get HTML as a string from the newly created Vue instance
        const html = componentInstance.$el.outerHTML;
        this.feedback_content = html;

        updateUserSession();
    }
}
</script>
